import {PaddleDuty, TrackType} from 'components/sections';

import Layout from 'components/layouts';
import localData from 'locals/easydl/paddle';
import { useEffect } from 'preact/hooks';

export default function PaddleDutyPage() {
    const {config} = localData;

    useEffect(() => {
        const jumpToDownload = () => (location.href = `${process.env.PADDLE}#paddle-download`);
        const btn = document.querySelectorAll('.js-paddle');

        btn.forEach((item) => {
            item.addEventListener('click', jumpToDownload);
        });
        return () => {
            btn.forEach((item) => {
                item.removeEventListener('click', jumpToDownload);
            });
        };
    });

    return (
        <TrackType.Provider value={config}>
            <Layout>
                <PaddleDuty />
            </Layout>
        </TrackType.Provider>
    );
}
