
import Layout from 'components/layouts';
import localData from 'locals/bml/codelab';
import Banner from 'components/banner';
import {useEffect} from 'preact/hooks';
import forEach from 'lodash/forEach';
import {scrollToAim} from 'utils';
import Section, {
    WithComponent,
    TrackType,
    IconHoverCards,
    Download,
    CodeLabIntro
} from 'components/sections';

const BmlSection = WithComponent(Section)({
    sectionWidth: 'bml'
});

export default function CodeLab() {
    const {config, banner, productAdvantages, sence, partner} = localData;
    useEffect(() => {
        const gotoDownload = document.getElementsByClassName('codelab-download');
        if (gotoDownload.length) {
            forEach(gotoDownload, ele => {
                ele.addEventListener('click', () => scrollToAim('codelab-downloads'));
            });
        }
    }, []);
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />
                <BmlSection style={{paddingTop: '120px'}} {...productAdvantages}>
                    <IconHoverCards {...productAdvantages} />
                </BmlSection>
                <BmlSection {...sence} id={'codelab-downloads'} bg={'codelab'} >
                    <Download {...sence} />
                </BmlSection>
                <BmlSection title={partner.title}>
                    <CodeLabIntro {...partner} />
                </BmlSection>
            </Layout>
        </TrackType.Provider>
    );
}