import Layout from 'components/layouts';
import Banner from 'components/banner';

import localData from 'locals/easydl/ocr';
import Section, {
    TrackType,
    WithComponent,
    CardList,
    UseStep,
    HighLightNumber,
    TableCase,
    ProductForm
} from 'components/sections';
const SubSection = WithComponent(Section)({
    subSection: true
});

export default function OCR() {
    const {config, banner, feature, step, scene, recommend, productForm} = localData;
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />
                <SubSection title={feature.title}>
                    <HighLightNumber data={feature.data} />
                </SubSection>
                <SubSection title={step.title} desc={step.desc}>
                    <UseStep data={step.data} />
                </SubSection>
                <SubSection title={scene.title}>
                    <TableCase data={scene.data} />
                </SubSection>
                <Section title={productForm.title}>
                    <ProductForm data={productForm.data} />
                </Section>
                <SubSection title={recommend.title}>
                    <CardList {...recommend} />
                </SubSection>
            </Layout>
        </TrackType.Provider>
    );
}
