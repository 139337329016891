export const flowPathdata = {
    data: [
        {
            img: require('assets/giftBag/path-processing.svg').default,
            text: '数据处理'
        },
        {
            img: require('assets/giftBag/path-drill.svg').default,
            text: '模型训练'
        },
        {
            img: require('assets/giftBag/path-deploy.svg').default,
            text: '模型部署'
        },
        {
            img: require('assets/giftBag/path-call.svg').default,
            text: '模型调用'
        }
    ]
};

export const lookBack = {
    title: '往期实战营主题回顾',
    data: [
        {
            img: require('assets/giftBag/lookBack-a.png').default,
            text: '车辆智能分类'
        },
        {
            img: require('assets/giftBag/lookBack-b.png').default,
            text: 'CV 目标跟踪'
        },
        {
            img: require('assets/giftBag/lookBack-c.png').default,
            text: '工业场景物体数量识别'
        },
        {
            img: require('assets/giftBag/lookBack-d.png').default,
            text: '火锅串串签数量识别'
        },
        {
            img: require('assets/giftBag/lookBack-e.png').default,
            text: '小样本学习实训'
        },
        {
            img: require('assets/giftBag/lookBack-f.png').default,
            text: '商品识别'
        },
        {
            img: require('assets/giftBag/lookBack-g.png').default,
            text: '安全帽智能识别'
        },
        {
            text: '更多场景 每周推出'
        }
    ]
};

export const campsite = {
    bg: require('assets/giftBag/bg-module-a.svg').default,
    titleBg: require('assets/giftBag/bg-recommend-title.png').default,
    title: '实战营介绍',
    trumpet: require('assets/giftBag/recommend-trumpet-a.png').default
};

export const giftBagCon = {
    differentiate: 'differentiate',
    bg: require('assets/giftBag/bg-module-b.svg').default,
    titleBg: require('assets/giftBag/bg-recommend-title-b.png').default,
    title: '参与实战营，解锁以下<b>4</b>大礼包',
    trumpet: require('assets/giftBag/recommend-trumpet-b.png').default
};

export const giftBagOne = {
    text: 'AWARDS ONE',
    desc: '礼包一',
    title: '扫码立即报名实战营，即可解锁AI技能图谱一份',
    img: require('assets/giftBag/lookBack-one.png').default,
    gradientA: {backgroundImage: 'linear-gradient(90deg, #E763C3 0%, #7A39C6 100%)'},
    gradientB: {backgroundImage: 'linear-gradient(90deg, #E763C3 0%, #7A39C6 100%)'}
};

export const giftBagTwo = {
    direction: true,
    text: 'AWARDS TWO',
    desc: '礼包二',
    title: '完成数据创建、模型创建和模型训练，解锁3000元无门槛代金券',
    img: require('assets/giftBag/lookBack-two.png').default,
    gradientA: {backgroundImage: 'linear-gradient(270deg, #63DCE7 0%, #7E00EB 100%)'},
    gradientB: {
        backgroundImage: 'linear-gradient(270deg, #63DCE7 0%, #7E00EB 44%, rgba(126,0,235,0.00) 100%)'
    }
};

export const giftBagThree = {
    text: 'AWARDS THREE',
    desc: '礼包三',
    title: '完成模型部署和调用, 解锁6000元无门槛代金券、证书和礼品抽奖资格',
    img: require('assets/giftBag/lookBack-three.png').default,
    gradientA: {backgroundImage: 'linear-gradient(90deg, #E7B163 0%, #C639B2 100%)'},
    gradientB: {backgroundImage: 'linear-gradient(90deg, #E7B163 0%, #C639B2 39%, rgba(198,57,178,0.00) 100%)'}
};

export const giftBagFour = {
    direction: true,
    text: 'AWARDS FOUR',
    desc: '礼包四',
    title: '完成企业认证、解锁1000元无门槛代金劵和AI技术课程礼包',
    img: require('assets/giftBag/lookBack-four.png').default,
    gradientA: {backgroundImage: 'linear-gradient(270deg, #0052FF 0%, #B639D8 100%)'},
    gradientB: {backgroundImage: 'linear-gradient(270deg, #0052FF 0%, #B639D8 100%)'}
};

export const giftBagApply = [
    {
        img: require('assets/giftBag/lookBack-apply-one-a.png').default,
        desc: '扫码报名'
    },
    {
        img: require('assets/giftBag/lookBack-apply-one-b.png').default,
        desc: '进入实战营群'
    },
    {
        img: require('assets/giftBag/lookBack-apply-one-c.png').default,
        desc: '领取技能图谱一份'
    }
];

export const AwardsTwoData = {
    text: '群内完成任务后可领取',
    price: '3000',
    priceDesc: ['新用户专享代金券', '限EasyDL&BML 新用户'],
    desc: '3000元无门槛代金券'
};

export const AwardsThreeData = {
    text: '群内完成任务后可领取',
    price: '6000',
    priceDesc: ['AI达人代金券', '新老用户均可参加'],
    desc: '6000元无门槛代金券',
    imgs: [
        {
            img: require('assets/giftBag/lookBack-three-a.png').default,
            desc: '结营证书'
        },
        {
            img: require('assets/giftBag/lookBack-three-b.png').default,
            desc: '抽奖礼品'
        }
    ]
};
export const AwardsFourData = {
    text: 'AI技术课程包将在您认证完成后发送至您的邮箱',
    price: '1000',
    priceDesc: ['企业认证专享代金券', '限新认证企业用户'],
    desc: '1000元无门槛代金券',
    imgs: [
        {
            img: require('assets/giftBag/lookBack-four-a.png').default,
            desc: 'AI技术课程礼包'
        }
    ]
};
export const recommendMB = {
    title: '实战营介绍',
    flowPaths: [
        {
            img: require('assets/giftBag/mb-path-processing.svg').default,
            text: '数据处理'
        },
        {
            img: require('assets/giftBag/mb-path-drill.svg').default,
            text: '模型训练'
        },
        {
            img: require('assets/giftBag/mb-path-deploy.svg').default,
            text: '模型部署'
        },
        {
            img: require('assets/giftBag/mb-path-call.svg').default,
            text: '模型调用'
        }
    ],
    theme: '往期实战营主题回顾',
    themeData: [
        {
            img: require('assets/giftBag/mb-lookBack-a.png').default,
            text: '车辆智能分类'
        },
        {
            img: require('assets/giftBag/mb-lookBack-b.png').default,
            text: 'CV 目标跟踪'
        },
        {
            img: require('assets/giftBag/mb-lookBack-c.png').default,
            text: '工业场景物体数量识别'
        },
        {
            img: require('assets/giftBag/mb-lookBack-d.png').default,
            text: '火锅串串签数量识别'
        },
        {
            img: require('assets/giftBag/mb-lookBack-e.png').default,
            text: '小样本学习实训'
        },
        {
            img: require('assets/giftBag/mb-lookBack-f.png').default,
            text: '商品识别'
        },
        {
            img: require('assets/giftBag/mb-lookBack-g.png').default,
            text: '安全帽智能识别'
        },
        {
            text: '更多场景 每周推出'
        }
    ]
};
export const campsitemb = [
    {
        text: 'AWARDS ONE',
        desc: '礼包一',
        title: ['扫码立即报名实战营，即可解锁AI技能图谱一份'],
        img: require('assets/giftBag/mb-lookBack-one.png').default,
        gradientA: {backgroundImage: 'linear-gradient(90deg, #E763C3 0%, #7A39C6 100%)'},
        gradientB: {backgroundImage: 'linear-gradient(90deg, #E763C3 0%, #7A39C6 32%, rgba(122,57,198,0.00) 100%)'}
    },
    {
        direction: true,
        text: 'AWARDS TWO',
        desc: '礼包二',
        title: ['完成数据创建、模型创建和模型训练，', '解锁3000元无门槛代金券'],
        img: require('assets/giftBag/mb-lookBack-two.png').default,
        gradientA: {backgroundImage: 'linear-gradient(270deg, #63DCE7 0%, #7E00EB 100%)'},
        gradientB: {
            backgroundImage: 'linear-gradient(270deg, #63DCE7 0%, #7E00EB 44%, rgba(126,0,235,0.00) 100%)'
        }
    },
    {
        text: 'AWARDS THREE',
        desc: '礼包三',
        title: ['完成模型部署和调用, 解锁6000元无门槛代金券、', '证书和礼品抽奖资格'],
        img: require('assets/giftBag/mb-lookBack-three.png').default,
        gradientA: {backgroundImage: 'linear-gradient(90deg, #E7B163 0%, #C639B2 100%)'},
        gradientB: {
            backgroundImage: 'linear-gradient(90deg, #E7B163 0%, #C639B2 39%, rgba(198,57,178,0.00) 100%)'
        }
    },
    {
        direction: true,
        text: 'AWARDS FOUR',
        desc: '礼包四',
        title: ['完成企业认证、解锁1000元无门槛代金劵和AIAI技', '术课程礼包'],
        img: require('assets/giftBag/mb-lookBack-four.png').default,
        gradientA: {backgroundImage: 'linear-gradient(270deg, #63DCE7 0%, #7E00EB 100%)'},
        gradientB: {
            backgroundImage: 'linear-gradient(270deg, #63DCE7 0%, #7E00EB 44%, rgba(126,0,235,0.00) 100%)'
        }
    }
];
export const giftBagApplyMB = [
    {
        img: require('assets/giftBag/mb-lookBack-apply-one-a.png').default,
        desc: '扫码报名'
    },
    {
        img: require('assets/giftBag/mb-lookBack-apply-one-b.png').default,
        desc: '进入实战营群'
    },
    {
        img: require('assets/giftBag/mb-lookBack-apply-one-c.png').default,
        desc: '领取技能图谱一份'
    }
];
export const AwardsTwoDataMB = {
    text: '群内完成任务后可领取',
    price: '3000',
    priceDesc: ['新用户专享代金券', '限EasyDL&BML 新用户'],
    desc: '3000元无门槛代金券'
};
export const AwardsThreeDataMB = {
    text: '群内完成任务后可领取',
    price: '6000',
    priceDesc: ['AI达人代金券', '新老用户均可参加'],
    desc: '3000元无门槛代金券',
    imgs: [
        {
            img: require('assets/giftBag/lookBack-three-a.png').default,
            desc: '结营证书'
        },
        {
            img: require('assets/giftBag/lookBack-three-b.png').default,
            desc: '抽奖礼品'
        }
    ]
};
export const AwardsFourDataMB = {
    text: 'AI技术课程包将在您认证完成后发送至您的邮箱',
    price: '1000',
    priceDesc: ['企业认证专享代金券', '限新认证企业用户'],
    desc: '1000元无门槛代金券',
    imgs: [
        {
            img: require('assets/giftBag/mb-lookBack-four-a.png').default,
            desc: 'AI技术课程礼包'
        }
    ]
};

export const groundPage = [
    'ground-page-a',
    'ground-page-b',
    'ground-page-c',
    'ground-page-d',
    'ground-page-e',
    'ground-page-f',
    'ground-page-g',
    'ground-page-h'
];

export const groundPageMB = [
    'ground-page-a',
    'ground-page-b',
    'ground-page-c',
    'ground-page-d',
    'ground-page-e',
    'ground-page-f',
    'ground-page-g',
    'ground-page-h',
    'ground-page-i'
];