import css from './index.less';
import classNames from 'classnames';
import {useState} from 'preact/hooks';
import {jumpToURL, $useTrack} from 'utils';

export function PaddleParadigm({paradigm}) {
    const {track, ACTIONS} = $useTrack('BML官网主页飞桨入口');
    const {paradigmTitle, paradigmType, exploitTitle, img, external} = paradigm;
    const {vision, voice, science, bottom} = external;
    const [hoverIndex, setHoverIndex] = useState();
    return (
        <div className={css.paddle_paradigm}>
            <div className={css.paddle_paradigm_box}>
                <h3>{paradigmTitle}</h3>
                <div className={css.paddle_paradigm_box_item}>
                    {
                        paradigmType.map(({name, icon, iconHover, type}, index) => (
                            <div
                                className={
                                    classNames(hoverIndex === index ? css.paddle_paradigm_box_item_div : '')
                                }
                                key={name}
                                onMouseEnter={() => setHoverIndex(index)}
                                onMouseLeave={() => setHoverIndex(null)}
                                onClick={
                                    () => {
                                        if (type) {
                                            track(ACTIONS.CLICK, '跳转至落地页');
                                            jumpToURL(`/bml/paddlepaddle#${type}`);
                                        }
                                    }
                                }
                            >
                                <img src={(hoverIndex === index && type) ? iconHover : icon} alt={name} />
                                <span>{name}</span>
                                {
                                    hoverIndex === index && !type && (
                                        <p>
                                            暂未开放
                                        </p>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={css.paddle_paradigm_box}>
                <h3>{exploitTitle}</h3>
                <div
                    className={css.paddle_paradigm_box_external}
                    style={{backgroundImage: `url(${img})`}}
                >
                    <div className={css.paddle_paradigm_box_external_item}>
                        <div className={css.external_item_box}>
                            {
                                vision.map(({name, href}) => (
                                    <p
                                        onClick={() => {
                                            track(ACTIONS.CLICK, '跳转至落地页');
                                            jumpToURL(href);
                                        }}
                                        key={name}
                                    >
                                        {name}
                                    </p>
                                ))
                            }
                        </div>
                        <div className={css.external_item_box}>
                            {
                                voice.map(({name, href}) => (
                                    <p
                                        onClick={() => {
                                            track(ACTIONS.CLICK, '跳转至落地页');
                                            jumpToURL(href);
                                        }}
                                        key={name}
                                    >
                                        {name}
                                    </p>
                                ))
                            }
                        </div>
                        <div className={css.external_item_box}>
                            {
                                science.map(({name, href}) => (
                                    <p
                                        onClick={() => {
                                            track(ACTIONS.CLICK, '跳转至落地页');
                                            jumpToURL(href);
                                        }}
                                        key={name}
                                    >
                                        {name}
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                    <div className={css.paddle_paradigm_box_external_item_bottom}>
                        <p
                            onClick={() => {
                                    track(ACTIONS.CLICK, '跳转至落地页');
                                    jumpToURL(bottom.href);
                            }}
                        >
                            {
                                bottom.name
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}