import {useState, useEffect} from 'preact/hooks';

import Layout from 'components/layouts';
import Banner from 'components/banner';

// 页面数据
import localData from 'locals/easydl/case';
import {scrollToAim, getQueryParam} from 'utils';

import Section, {TrackType} from 'components/sections';

import 'style/easydl/case.less';

const imgItem = (img) => {
    if (img && Array.isArray(img) && img.length !== 0) {
        return img.map((it) => {
            if (it.link) {
                return (
                    <a href={it.link} class={`supplier-item ${it.name}`} target="_blank" rel="noopener noreferrer" />
                );
            }
            return <div class={`supplier-item ${it.name}`} />;
        });
    }
};

const moreItem = (data) =>
    data.map((it) => (
        <div class="scene-more-item">
            <div class="title">{it.name}</div>
            <div class="desc">{it.desc}</div>
        </div>
    ));

export default function Case() {
    const {config, banner, scene} = localData;
    const [curTab, setCurTab] = useState(0);
    useEffect(() => {
        const param = getQueryParam();
        if (param.target) {
            scrollToAim('application');
            setCurTab(+param.target);
        }
    }, []);
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />
                <div class="application-body">
                    <section id="application">
                        <div class="application-title">应用场景</div>
                        <div class="application-content">
                            <div class="scene-tab">
                                {scene.map((it, index) => (
                                    <div
                                        class={`scene-tab-item ${curTab === index ? 'active' : ''}`}
                                        onClick={() => setCurTab(index)}
                                    >
                                        <div class={`tzfont icon tzfont-${it.icon}`} />
                                        <div class="txt">{it.name}</div>
                                    </div>
                                ))}
                                <a
                                    href={`${process.env.CUSTOMER}?industry=0&technology=8&clickType=tech`}
                                    class="scene-tab-item"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div class={`tzfont icon tzfont-window`} />
                                    <div class="txt">更多案例</div>
                                </a>
                            </div>

                            <div class="scene-detail">
                                <div class="scene-content">
                                    {scene[curTab].content.map((it) => (
                                        <div class="scene-detail-item">
                                            <div class="scene-detail-item-left">
                                                <div class="title">{it.name}</div>
                                                <p class="desc">{it.desc}</p>
                                                <p class="example">{it.example}</p>
                                                <div class="img-wrapper">{imgItem(it.imgs)}</div>
                                            </div>
                                            <div class="scene-detail-item-right">
                                                <div class="detail-wrapper">
                                                    <img src={it.banner} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {scene[curTab].more && (
                                <Section title={'更多场景'}>
                                    <div class="section-content scene-more-content">{moreItem(scene[curTab].more)}</div>
                                </Section>
                            )}
                        </div>
                    </section>
                </div>
            </Layout>
        </TrackType.Provider>
    );
}
