import Layout from 'components/layouts';
import Banner from 'components/banner';
import ALink from 'components/base/aLink';

import Section, {
    TrackType,
    WithComponent,
    CardList,
    UseStep,
    HighLightNumber,
    TemplateCards,
    TableCase
} from 'components/sections';
const SubSection = WithComponent(Section)({
    subSection: true
});

import localData from 'locals/easydl/audio';

export default function Audio() {
    const {config, banner, modelTypes, feature, step, scene, recommend} = localData;
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />

                <SubSection title={modelTypes.title}>
                    <TemplateCards {...modelTypes} />
                </SubSection>

                <SubSection title={feature.title}>
                    <HighLightNumber {...feature} />
                </SubSection>
                <SubSection
                    title={step.title}
                    desc={step.desc}
                    descChildren={
                        <ALink className="js-consoleModalBtn" trackTag="在线使用_AI开发流程图上方">
                            在线使用
                        </ALink>
                    }
                >
                    <UseStep data={step.data} />
                </SubSection>
                <SubSection title={scene.title}>
                    <TableCase {...scene} />
                </SubSection>
                <SubSection title={recommend.title}>
                    <CardList {...recommend} />
                </SubSection>
            </Layout>
        </TrackType.Provider>
    );
}
