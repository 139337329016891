import {PreLink} from 'components/base/aLink';
import css from './index.less';
import {useEffect, useState} from 'preact/hooks';

const Link = PreLink({
    trackTag: 'Footer 链接'
});
function BaseLine(props) {
    const line = [
        {
            name: '百度智能云',
            href: 'https://cloud.baidu.com/?t=cp:online-media%7Cci:%7Ccn:ai'
        },
        {
            name: '百度开发者中心',
            href: 'https://developer.baidu.com'
        },
        {
            name: '百度手助平台',
            href: 'https://app.baidu.com'
        },
        {
            name: '百度技术学院',
            href: 'https://bit.baidu.com'
        },
        {
            name: '百度数据众包',
            href: 'http://zhongbao.baidu.com'
        }
    ];
    const {bgColor = 'default'} = props;
    return (
        <div className={css['ai-footer-line']}>
            <div className={css[bgColor]}>
                <div className={css['baseline-cover']}>
                    {line.map(({href, name}, index) => (
                        <>
                            {0 !== index && <div className={css.baseline_margin}>|</div>}
                            <Link href={href} className={css['baseline-item']}>
                                {name}
                            </Link>
                        </>
                    ))}
                    <Link href={'https://www.baidu.com/duty/'} className={css['baseline-item']}>
                        © {new Date().getFullYear()} Baidu 使用百度前必读
                    </Link>
                </div>
            </div>
        </div>
    );
}

export function BmlFooter(props) {
    const {lists} = props;

    return (
        <footer className={css['bml-footer']}>
            <div className={css['bml-footer-cover']}>
                <div className={css['bml-footer-content']}>
                    {lists.map(({name, items}) => (
                        <div className={css['bml-footer-column']} key={name}>
                            <div className={css['bml-footer-column_title']}>{name}</div>
                            {items.map(({name, href, QQ}) =>
                                QQ ? (
                                    <div className={css['bml-footer-column_qq']}>{name}</div>
                                ) : (
                                    <div>
                                        <Link href={href} className={css['bml-footer-column_link']}>
                                            {name}
                                        </Link>
                                    </div>
                                )
                            )}
                        </div>
                    ))}
                    <div className={css['ai-footer_qrcode']}>百度AI微信公众号</div>
                </div>
            </div>
            <BaseLine />
        </footer>
    );
}

export function EasyDLFooter(props) {
    const {desc, lists} = props;
    const [isScene, setIsScene] = useState(false);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const pathname = window.location.pathname;
            const re = new RegExp('scene');
            if (re.test(pathname)) {
                setIsScene(true);
            }
        }
    }, []);
    return (
        <footer className={css['easydl-footer']}>
            <div className={css['easydl-footer-cover']}>
                <div className={css['easydl-footer-content']}>
                    <div className={css['easydl-footer-about']}>
                        <p className={css['easydl-footer-about_title']}>{desc.title}</p>
                        {desc.intro.map((it) => (
                            <p className={css['easydl-footer-about_desc']} key={it}>{it}</p>
                        ))}
                        <Link href={'http://www.paddlepaddle.org.cn/'} className={css['learn-more']}>
                            <span className={css['easydl-footer-about_text']}>了解更多</span>
                            <span className={css['easydl-footer-about_pp']} />
                        </Link>
                    </div>
                    {lists.map(({name, items}) => (
                        <div className={css['easydl-footer-column']} key={name}>
                            <div className={css['easydl-footer-column_title']}>{name}</div>
                            {items.map(({name, href, QQ}) =>
                                QQ ? (
                                    <div className={css['easydl-footer-column_qq']}>{name}</div>
                                ) : (
                                    <div>
                                        <Link href={href} className={css['easydl-footer-column_link']}>
                                            {name}
                                        </Link>
                                    </div>
                                )
                            )}
                        </div>
                    ))}
                    {
                        isScene ? <div className={css['ai-footer_qrcode_scene']}>场景范例交流群</div> :
                        <div className={css['ai-footer_qrcode']}>飞桨企业俱乐部公众号</div>
                    }
                </div>
            </div>
            <BaseLine />
        </footer>
    );
}

export function GravityFooter(props) {
    const {desc, lists} = props;
    return (
        <footer className={css['gravity-footer']}>
            <div className={css['gravity-footer-cover']}>
                <div className={css['gravity-footer-content']}>
                    <div className={css['gravity-footer-about']}>
                        <p className={css['gravity-footer-about_title']}>{desc.title}</p>
                        {desc.intro.map((it) => (
                            <p className={css['gravity-footer-about_desc']} key={it}>{it}</p>
                        ))}
                        <Link href={'http://ai.baidu.com/easydl/'} className={css['gravity-footer-about_text']}>
                            EasyDL
                        </Link>
                        <Link href={'http://ai.baidu.com/bml/'} className={css['gravity-footer-about_text']}>
                            BML
                        </Link>
                        <Link href={'http://www.paddlepaddle.org.cn/'} className={css['gravity-footer-about_text']}>
                            了解更多
                        </Link>
                    </div>
                    {lists.map(({name, items}) => (
                        <div className={css['gravity-footer-column']} key={name}>
                            <div className={css['gravity-footer-column_title']}>{name}</div>
                            {items.map(({name, href, QQ}) => (
                                QQ ? (
                                    <div className={css['gravity-footer-column_qq']} key={name}>{name}</div>
                                ) : (
                                    <div key={name}>
                                        <Link href={href} className={css['gravity-footer-column_link']}>
                                            {name}
                                        </Link>
                                    </div>
                                )
                            ))}
                        </div>
                    ))}
                    <div className={css['gravity-footer_qrcode']}>
                        <p>
                            添加小助手微信获得更多支持
                        </p>
                    </div>
                </div>
            </div>
            <BaseLine bgColor={'gravity'} />
        </footer>
    );
}
