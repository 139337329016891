/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-25 11:28:34
 * @LastEditTime: 2021-06-08 15:45:28
 * @file: structdata.js
 */

export default {
    config: {
        trackType: 'EasyDL结构化数据官网',
        layout: 'easydl',
        page: 'structured_data'
    },
    banner: {
        name: 'structdata',
        title: 'EasyDL 结构化数据',
        subTitle: '一键式定制，自动进行数据处理并生成机器学习模型',
        bg: require('assets/banner/lite-structdata.jpg').default,
        buttonGroup: [
            {
                name: '在线使用',
                className: 'js-consoleModalBtn',
                size: 'small',
                type: 'primary',
                trackTag: '在线使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL在线使用',
                    'data-track-name': 'EasyDL-产品页面-在线使用',
                    'data-track-value': ''
                }
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/pkhypzxsv',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            },
            {
                name: '产品定价',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/nl2tvkksk',
                size: 'small',
                plain: true,
                trackTag: '产品定价_官网上方'
            }
        ]
    },
    modelTypes: {
        title: '源于实践的模型类型',
        data: [
            {
                title: '表格数据预测',
                desc: '定制预测一个表格中某列的类别或数值，适合通过多个已知列来预测未知列的场景',
                keywords: ['精准营销', '用户评分预测'],
                img: require('assets/subpage/structdata-table.jpg').default,
                link: {
                    name: '在线使用',
                    href: 'https://console.bce.baidu.com/easydl/201/20100/models'
                }
            },
            {
                title: '时序预测',
                desc: '时序预测是根据历史统计数据的时间序列，对未来的变化趋势进行预测分析',
                keywords: ['销量预测', '交通流量预测'],
                img: require('assets/subpage/structdata-sequential.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.TSF
                }
            }
        ]
    },

    feature: {
        title: '全面领先的特色功能效果',
        data: [
            {
                title: '零门槛操作',
                subtitle: '',
                href: '',
                desc: ['聚焦业务目标', '无需关注代码', '无需关注算法细节']
            },
            {
                title: '自动化流程',
                subtitle: '',
                href: '',
                desc: ['自动数据清洗', '自动特征工程', '自动算法选择与调优']
            },
            {
                title: '高质量模型',
                subtitle: '',
                href: '',
                desc: ['先进的超参优化技术', '模型融合获得更优效果', '详细的模型评估指标']
            },
            {
                title: '高性价比',
                subtitle: '',
                href: '',
                desc: ['免费训练', '仅对部署的模型付费', '按实际用量付费']
            }
        ]
    },

    step: {
        title: '简单四步体验全流程，轻松获得AI模型',
        desc:
            // eslint-disable-next-line
            '一站式模型定制，全流程可视化操作，最快15分钟获得高精度AI模型，',
        data: [
            {name: '创建模型', desc: '了解不同类型模型的功能，创建想要训练的模型'},
            {name: '准备数据', desc: '上传并标注需要模型识别的示例数据'},
            {name: '训练模型', desc: '了解不同算法的优势，一键启动模型训练'},
            {name: '上线模型', desc: '快速部署上线，即刻生成公有云 API 以供调用'}
        ]
    },

    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '精准营销',
                desc: '基于用户特征预测用户是否为潜在购买用户，进而决定是否向该用户推送广告或产品信息',
                banner: require('assets/subpage/structdata-marketing.png').default
            },
            {
                name: '用户评分预测',
                desc: '基于用户已有的打分行为，精准的预测这些用户对其它商品的打分',
                banner: require('assets/subpage/structdata-score.png').default
            }
        ]
    },

    recommend: {
        title: '相关推荐',
        data: [
            {
                name: 'EasyDL图像',
                href: 'https://ai.baidu.com/easydl/vision',
                desc: '零门槛定制图像分类、物体检测、图像分割AI模型，丰富的灵活部署方案，适用于各种行业场景'
            },
            {
                name: 'BML 全功能AI开发平台',
                href: 'https://cloud.baidu.com/product/bml.html',
                desc: '企业级AI开发平台，一站式完成模型构建、训练、部署的全部工作'
            },
            {
                name: 'EasyDL零售行业版',
                href: 'https://ai.baidu.com/easydl/retail',
                desc:
                    '专门用于定制货架合规性检查、自助结算、无人货柜等零售场景下识别商品的高精度AI模型，可以识别商品名称和在图中的位置'
            }
        ]
    }
};
