export default {
    config: {
        trackType: 'CodeLab官网',
        layout: 'bml',
        page: 'codelab'
    },
    banner: {
        name: 'codelab',
        title: 'BML CodeLab',
        subTitle:
            '面向机器学习的集成开发环境，提供强大的交互式编码体验、流畅的端云协同开发<br/>机制和丰富的AI开发插件，助力AI开发者和企业高效率构建AI应用。',
        bg: require('assets/bml/codelab/banner.png').default,
        buttonGroup: [
            {
                name: '立即下载',
                className: 'codelab-download',
                size: 'small',
                type: 'primary',
                target: '_self',
                trackTag: '立即下载_官网上方'
            },
            {
                name: '技术文档',
                href: 'https://cloud.baidu.com/doc/BML/s/skmipkby1',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            }
        ]
    },
    productAdvantages: {
        title: '产品优势',
        tag: '产品优势',
        data: [
            {
                // icon: 'stable',
                img: require('assets/bml/advantage-1.png').default,
                name: '交互式编码体验',
                desc: '全面升级到JupyterLab 3.0，支持双语言和可视化调试<br/>集成微软LSP协议，支持代码自动补全、变量重命名、实时提醒等',
                linkText: '了解详情',
                linkHref: 'https://cloud.baidu.com/doc/BML/s/gkmg74coi'
            },
            {
                // icon: 'app',
                img: require('assets/bml/advantage-3.png').default,
                name: '弹性资源扩展',
                desc:
                    '多端项目自动同步，持久化保存在云端存储中<br/>本地运行模式可随时扩展云端计算资源,端云协同开发调试<br/>按需付费，极大降低计算资源成本',
                linkText: '了解详情',
                linkHref: 'https://cloud.baidu.com/doc/BML/s/gkmg74coi'
            },
            {
                // icon: 'app',
                img: require('assets/bml/advantage-2.png').default,
                name: '多环境管理',
                desc: '具有项目管理能力，项目之间环境隔离，相互独立<br/>支持项目环境可视化管理，提供多版本套件、python包',
                linkText: '了解详情',
                linkHref: 'https://cloud.baidu.com/doc/BML/s/gkmg74coi'
            },
            {
                // icon: 'app',
                img: require('assets/bml/advantage-6.png').default,
                name: '丰富的AI插件',
                desc:
                    "提供变量监控和计算资源实时监控<br/>内置公共代码片段库，可管理自己代码片段<br/>内含百度自研数据计算引擎blackhole和语义理解技术文心（<a href='https://wenxin.baidu.com/'>ERNIE</a>）",
                linkText: '了解详情',
                linkHref: 'https://cloud.baidu.com/doc/BML/s/gkmg74coi'
            }
        ]
    },

    sence: {
        title: '下载CodeLab',
        bg: 'codelab',
        data: [
            {
               title: 'Docker镜像版',
               desc: [
                    {
                        support: true,
                        version: ['DEFAULT'],
                        info: '支持企业集成'
                    },
                    {
                        support: true,
                        version: ['DEFAULT'],
                        info: '支持计算资源弹性扩展到百度云'
                    }
                ],
                info: [
                    {
                        key: '版本',
                        value: '1.2.0'
                    },
                    {
                        key: '适应系统',
                        value: '全部系统'
                    },
                    {
                        key: 'Docker版本',
                        value: '19.03.0及以上'
                    },
                    {
                        key: '更新时间',
                        value: '2021-7-23'
                    }
                ],
                download: [
                    {
                        linkHref: 'https://public-codelab.cdn.bcebos.com/docker-images/codelab_cpu.1.2.0.tar.gz',
                        version: 'DEFAULT',
                    }
                ],
                moreVersionIntro: 'xxx'
            },
            {
                title: 'Windows客户端',
                subTitle: '（多种版本，请按需下载）',
                desc: [
                    {
                        support: true,
                        version: ['DEFAULT', 'GPU'],
                        info: '内置Linux子系统，提供原生Linux开发体验'
                    },
                    {
                        support: false,
                        version: ['NATIVE'],
                        info: '没有内置Linux子系统，提供原生Windows开发体验'
                    },
                    {
                        support: true,
                        version: ['NATIVE', 'GPU'],
                        info: "支持本地GPU卡调试（<a href='https://cloud.baidu.com/doc/BML/s/Gknr6zn28' target='_blank'>请先查看说明</a>）"
                    },
                    {
                        support: false,
                        version: ['DEFAULT'],
                        info: '不支持本地GPU卡调试'
                    },
                    {
                        support: true,
                        version: ['DEFAULT', 'GPU'],
                        info: '支持扩展云端计算资源'
                    },
                    {
                        support: false,
                        version: ['NATIVE'],
                        info: '不支持扩展云端计算资源'
                    }
                ],
                info: [
                    {
                        key: '版本',
                        value: '1.0.1'
                    },
                    {
                        key: '适应系统',
                        value: 'Windows 10 64位系统'
                    },
                    {
                        key: '更新时间',
                        value: '2021-5-14'
                    }
                ],
                download: [
                    {
                        version: 'DEFAULT',
                        desc: 'Windows wsl2版',
                        linkHref: 'https://public-codelab.cdn.bcebos.com/codelab-desktop/BML_CodeLab_1.0.1.exe'
                    },
                    {
                        version: 'GPU',
                        desc: 'Windows wsl2版（支持本地GPU卡调试）',
                        linkHref: 'https://public-codelab.cdn.bcebos.com/codelab-desktop/BML_CodeLab_1.0.1_GPU.exe'
                    },
                    // {
                    //     version: 'NATIVE',
                    //     desc: 'Windows 原生体验版',
                    //     linkHref: 'xxxx'
                    // }
                ],
                moreVersionIntro: '/bml/codelab/history'
            },
            {
                title: 'Mac客户端',
                desc: [
                    {
                        support: false,
                        version: ['DEFAULT'],
                        info: '不支持本地GPU卡调试'
                    },
                    {
                        support: true,
                        version: ['DEFAULT'],
                        info: '支持扩展云端计算资源'
                    }
                ],
                info: [
                    {
                        key: '版本',
                        value: '1.0.1'
                    },
                    {
                        key: '适应系统',
                        value: 'MAC OS 10.13及以上系统'
                    },
                    {
                        key: 'Docker版本',
                        value: '19.03.0及以上'
                    },
                    {
                        key: '更新时间',
                        value: '2021-5-14'
                    }
                ],
                download: [
                    {
                        linkHref: 'https://public-codelab.cdn.bcebos.com/codelab-desktop/BML_CodeLab_1.0.1.dmg',
                        version: 'DEFAULT',
                    }
                ],
                moreVersionIntro: '/bml/codelab/history'
            }
        ]
    },

    partner: {
        title: '企业合作伙伴',
        data: [
            {
                img: require('assets/bml/codelab/zxy.png').default,
                title: '智星云',
                intro: '智星云是一家专注于高性能计算加速的GPU云平台，赋能AI深度学习、HPC科学计算、3D渲染和航拍测绘等行业应用加速。'
            },
            {
                img: require('assets/bml/codelab/more.png').default,
                title: '即将发布更多合作伙伴',
                intro: 'BML CodeLab正在与数家企业伙伴建立合作关系，一起服务千万用户。'
            }
        ]
    }
};