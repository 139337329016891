export default {
    bml: {
        lists: [
            {
                name: '快速入口',
                items: [
                    {
                        name: '立即开始',
                        href: 'https://ai.baidu.com/bml/app/overview'
                    },
                    {
                        name: '私有化部署',
                        href: 'https://cloud.baidu.com/product/ai_bml.html'
                    }
                ]
            },
            {
                name: '开发资源',
                items: [
                    {
                        name: 'BML社区',
                        href: 'https://ai.baidu.com/forum/topic/list/208'
                    },
                    {
                        name: '文档中心',
                        href: 'https://ai.baidu.com/ai-doc/BML/Ekhvg0smz'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: 'AI加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: `${process.env.CLOUDCOOPERATION}bml`
                    },
                    {
                        name: '官方QQ群: 868826008',
                        QQ: true
                    }
                ]
            }
        ]
    },
    easydl: {
        desc: {
            title: '关于EasyDL',
            intro: [
                'EasyDL是基于百度自主研发的深度学习平台飞桨',
                '结合业界先进的工程服务技术打造的',
                '简单易用的模型训练和服务平台'
            ]
        },
        lists: [
            {
                name: '开发资源',
                items: [
                    {
                        name: 'EasyDL社区',
                        href: 'https://ai.baidu.com/forum/topic/list/190'
                    },
                    {
                        name: '文档中心',
                        href: 'https://ai.baidu.com/ai-doc/EASYDL/pk38n3odv'
                    },
                    {
                        name: '教学视频',
                        href: 'https://ai.baidu.com/support/video'
                    },
                    {
                        name: '免费资源兑换',
                        href: 'https://ai.baidu.com/easydl/app/exchange'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: '加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    },
                    {
                        name: 'BML平台',
                        href: 'https://ai.baidu.com/bml/'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: `${process.env.CLOUDCOOPERATION}easydl`
                    },
                    {
                        name: '提交工单',
                        href: 'https://ticket.bce.baidu.com/#/ticket/create~productId=171'
                    },
                    {
                        name: '意见反馈',
                        href: 'mailto:easydl@baidu.com'
                    },
                    {
                        name: '客服电话：400-920-8999',
                        QQ: true
                    },
                    {
                        name: '官方群：124601062',
                        QQ: true
                    },
                    {
                        name: '官方群（零售版）：1009661589',
                        QQ: true
                    }
                ]
            }
        ]
    },
    wenxin: {
        lists: [
            {
                name: '快速入口',
                items: [
                    {
                        name: '账户中心',
                        href: 'https://console.bce.baidu.com/iam/?fromai=1#/iam/baseinfo'
                    },
                    {
                        name: '管理控制台',
                        href: 'https://console.bce.baidu.com/?fromai=1#/aip/overview'
                    },
                    {
                        name: '财务中心',
                        href: 'https://console.bce.baidu.com/billing/?fromai=1#/account/index'
                    },
                    {
                        name: '价格计算器',
                        href: 'https://cloud.baidu.com/calculator.html#/ocr/price'
                    },
                    {
                        name: '服务协议',
                        href: 'https://ai.baidu.com/ai-doc/REFERENCE/kk3dwjg7d'
                    },
                    {
                        name: '申请合作',
                        href: 'https://ai.baidu.com/partner/apply'
                    }
                ]
            },
            {
                name: '开发资源',
                items: [
                    {
                        name: 'AI社区',
                        href: 'https://ai.baidu.com/forum'
                    },
                    {
                        name: '文档中心',
                        href: 'https://ai.baidu.com/ai-doc/REFERENCE/Ck3dwjgn3'
                    },
                    {
                        name: 'SDK下载',
                        href: 'https://ai.baidu.com/sdk'
                    },
                    {
                        name: '教学视频',
                        href: 'https://ai.baidu.com/support/video'
                    },
                    {
                        name: 'GitHub',
                        href: 'https://github.com/Baidu-AIP'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: 'AI加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    }
                ]
            },
            {
                name: '常见问题',
                items: [
                    {
                        name: '账号登录',
                        href: 'https://ai.baidu.com/ai-doc/REFERENCE/lk3dwjh1z'
                    },
                    {
                        name: '接口调用',
                        href: 'https://ai.baidu.com/ai-doc/REFERENCE/lk3dwjh1z#%E6%8E%A5%E5%8F%A3%E8%B0%83%E7%94%A8'
                    },
                    {
                        name: '商用咨询',
                        href: 'https://ai.baidu.com/ai-doc/REFERENCE/lk3dwjh1z#%E5%95%86%E7%94%A8%E5%92%A8%E8%AF%A2'
                    },
                    {
                        name: '技术问答',
                        href: 'https://ai.baidu.com/ai-doc/REFERENCE/lk3dwjh1z#%E4%BA%BA%E8%84%B8%E8%AF%86%E5%88%AB'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: 'https://ai.baidu.com/consultation/cooperation?referrerUrl=/easydl'
                    },
                    {
                        name: '提交工单',
                        href: 'https://ticket.bce.baidu.com/?_=1621420008562#/ticket/create~productId=171'
                    },
                    {
                        name: '意见反馈',
                        href: 'mailto:wenxin@baidu.com'
                    },
                    {
                        name: '客服电话：400-920-8999',
                        QQ: true
                    }
                ]
            }
        ]
    },
    edge: {
        desc: {
            title: '关于EasyEdge',
            intro: [
                'EasyEdge提供面向AI模型本地部署的一站式',
                '硬件适配、模型更新、设备管理能力，',
                '与EasyDL&BML平台无缝对接，',
                '帮助开发者更高效地在端与边缘部署应用AI模型'
            ]
        },
        lists: [
            {
                name: '开发资源',
                items: [
                    {
                        name: 'EasyEdge社区',
                        href: 'https://ai.baidu.com/forum/topic/list/199'
                    },
                    {
                        name: '文档中心',
                        href: 'https://ai.baidu.com/ai-doc/EASYEDGE/yk3fj850y'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: '加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: `${process.env.CLOUDCOOPERATION}easyedge/home`
                    }
                ]
            }
        ]
    },
    data: {
        desc: {
            title: '关于EasyData',
            intro: [
                'EasyData提供面向AI模型开发的一站式数据采',
                '集、清洗、标注和增强能力，与EasyDL平台无缝',
                '对接，帮助开发者更高效更低成本的解决AI模型',
                '开发及应用过程中遇到的数据问题'
            ]
        },
        lists: [
            {
                name: '开发资源',
                items: [
                    {
                        name: 'EasyData社区',
                        href: 'https://ai.baidu.com/forum/topic/list/205'
                    },
                    {
                        name: '文档中心',
                        href: 'https://ai.baidu.com/ai-doc/EasyData/ek9k9014h'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: '加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: `${process.env.CLOUDCOOPERATION}easydata`
                    }
                ]
            }
        ]
    },
    gravity: {
        desc: {
            title: '飞桨企业版',
            intro: [
                '飞桨企业版是基于百度自主研发的深度学习平台',
                '结合业界先进的工程服务技术打造的一站式模型',
                '训练和服务平台'
            ]
        },
        lists: [
            {
                name: '开发资源',
                items: [
                    {
                        name: 'EasyDL文档',
                        href: 'https://ai.baidu.com/ai-doc/EASYDL/pk38n3odv'
                    },
                    {
                        name: 'EasyDL社区',
                        href: 'https://ai.baidu.com/forum/topic/list/190'
                    },
                    {
                        name: 'BML文档',
                        href: 'https://ai.baidu.com/ai-doc/BML/Ekhvg0smz'
                    },
                    {
                        name: 'BML社区',
                        href: 'https://ai.baidu.com/forum/topic/list/208'
                    },
                    {
                        name: '教学视频',
                        href: 'https://ai.baidu.com/support/video'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: '技术合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: '加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: `${process.env.CLOUDCOOPERATION}easydl`
                    },
                    {
                        name: '客服电话：400-920-8999',
                        QQ: true
                    }
                ]
            }
        ]
    },
    enterprise: {
        desc: {
            title: '关于飞桨企业版',
            intro: [
                '飞桨企业版是基于百度自主研发的深度学习平台',
                '结合业界先进的工程服务技术打造的一站式模型训练和服务平台'
            ]
        },
        lists: [
            {
                name: '开发资源',
                items: [
                    {
                        name: 'EasyDL文档',
                        href: 'https://ai.baidu.com/ai-doc/EASYDL/pk38n3odv'
                    },
                    {
                        name: 'BML文档',
                        href: 'https://ai.baidu.com/ai-doc/BML/Ekhvg0smz'
                    },
                    {
                        name: '教学视频',
                        href: 'https://ai.baidu.com/support/video'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: '加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: `https://ai.baidu.com/consultation/cooperation?referrerUrl=/easydl`
                    },
                    {
                        name: '提交工单',
                        href: 'https://ticket.bce.baidu.com/?_=1621420008562#/ticket/create~productId=171'
                    },

                    {
                        name: '客服电话：400-920-8999',
                        QQ: true
                    }
                ]
            }
        ]
    },
    paddle: {
        desc: {
            title: '关于飞桨EasyDL',
            intro: [
                '飞桨EasyDL是基于百度自主研发的深度学习平',
                '台飞桨打造的客户端形态模型训练和服务软件',
                '为用户提供本地一站式模型训练与部署能力'
            ]
        },
        lists: [
            {
                name: '开发资源',
                items: [
                    {
                        name: 'EasyDL社区',
                        href: 'https://ai.baidu.com/forum/topic/list/190'
                    },
                    {
                        name: '文档中心',
                        href: 'https://ai.baidu.com/ai-doc/EASYDL/pk38n3odv'
                    },
                    {
                        name: '教学视频',
                        href: 'https://ai.baidu.com/support/video'
                    }
                ]
            },
            {
                name: '生态与市场',
                items: [
                    {
                        name: '生态合作伙伴',
                        href: 'https://ai.baidu.com/partner'
                    },
                    {
                        name: '加速器',
                        href: 'https://ai.baidu.com/accelerator'
                    },
                    {
                        name: 'AI市场',
                        href: 'https://ai.baidu.com/market/'
                    },
                    {
                        name: 'BML平台',
                        href: 'https://ai.baidu.com/bml/'
                    }
                ]
            },
            {
                name: '联系我们',
                items: [
                    {
                        name: '合作咨询',
                        href: `${process.env.CLOUDCOOPERATION}easydl`
                    },
                    {
                        name: '提交工单',
                        href: 'https://ticket.bce.baidu.com/#/ticket/create~productId=171'
                    },
                    {
                        name: '意见反馈',
                        href: 'mailto:easydl@baidu.com'
                    },
                    {
                        name: '客服电话：400-920-8999',
                        QQ: true
                    },
                    {
                        name: '官方群：868826008',
                        QQ: true
                    },
                    {
                        name: '官方群（零售版）：1009661589',
                        QQ: true
                    }
                ]
            }
        ]
    }
};
