import Axios from 'axios';
import fetchJsonp from 'fetch-jsonp';
import cookie from 'cookie';
const axiosMap = {};

const axiosInstance = (baseURL) => {
    if (Reflect.has(axiosMap, baseURL)) {
        return axiosMap[baseURL];
    }
    return (axiosMap[baseURL] = Axios.create({
        baseURL,
        timeout: 20_000,
        maxRedirects: 0,
        responseType: 'json',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }));
};

const bceJsonpHost = process.env.VUE_APP_BCE_JSONP_HOST || 'https://bce.baidu.com/api/account/v2/displayName';

const appendCookieFrom = (dict, key) => {
    if (dict[key]) {
        document.cookie = cookie.serialize(key, dict[key], {path: '/', domain: location.hostname});
    }
};

// 请求jsonp获取值写入cookie
export const fetchBceAuthInfo = async () => {
    const res = await fetchJsonp(bceJsonpHost);
    if (res.ok) {
        const response =
            await res.json();

        if (response.success && response.result.hasLogin) {
            const cookies = response.result.cookies;

            const cookiesFieldsToPatch = [
                'bce-sessionid',
                'bce-auth-type',
                'bce-ctl-client-cookies',
                ...(cookies['bce-ctl-client-cookies']
                    ?.replace(/^"|"$/g, '')
                    .split(',')
                    .filter(field => !['BDUSS', 'BAIDUID'].includes(field)) ?? [])
            ];

            for (const field of cookiesFieldsToPatch) {
                appendCookieFrom(cookies, field);
            }
        }
    }
};
export const send = ({
    method,
    headers = {},
    payload = {},
    baseURL = process.env.API_URL || '/easydl/api',
    options = {method: 'POST'}
}) => {
    const config = {
        // 请求 body
        data: {
            ...payload,
            method
        },
        headers: {
            ...headers
        },
        ...options
    };
    return axiosInstance(baseURL)
        .request(config)
        .then((response) => response.data);
};

export const getUrlParam = (name) => {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURI(r[2]);
    };
    return null;
};
