import css from './index.less';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import {marked} from 'marked';
import mds from 'locals/markdowns/bml/index';
import {useEffect, useRef, useState} from 'preact/hooks';

export function Frame(props) {
    const {dataVersions} = props;
    const [html, setHtml] = useState('');
    const [isShow, setIsShow] = useState(true);
    const {paddleFrame} = mds;
    const elHeight = useRef();
    useEffect(() => {
        setHtml(DOMPurify.sanitize(marked(paddleFrame)));
    }, []);
    return (
        <div className={css.frame}>
            <div className={css.frame_desc}>
                <div className={css.frame_desc_title}>
                    <h3>飞桨最新版本2.3.0介绍</h3>
                    {
                        <span
                            className={css.frame_desc_title_text}
                            style={{display: `${isShow ? 'none' : 'inline-block'}`}}
                        >
                            1. 更加丰富的API体系，支持各领域模型开发…
                        </span>
                    }
                    <span
                        className={
                            classNames(isShow ? css.frame_desc_on : css.frame_desc_off)
                        }
                        onClick={() => setIsShow(!isShow)}
                    >
                        {
                            isShow ? '收起' : '展开'
                        }
                    </span>
                </div>
                <div
                    className={css.frame_desc_content}
                    ref={elHeight}
                    style={{ maxHeight: `${isShow ? '3000px' : '0px'}` }}
                >
                    {
                        /* bca-disable */
                        <div
                            className={css.frame_md}
                            dangerouslySetInnerHTML={{ __html: html }}
                        >
                        </div>
                    }
                    <p>
                        <a
                            href="https://github.com/PaddlePaddle/Paddle/blob/develop/README_cn.md"
                            target='_blank'
                            rel="noreferrer"
                        >
                            了解更多
                        </a>
                    </p>
                </div>
            </div>
            <div className={css.frame_steps}>
                <p className={css.frame_steps_title}>
                    飞桨基础框架
                </p>
                <div className={css.frame_steps_box}>
                    {
                        dataVersions.map(({date, versions, dase}, index) => (
                            <div
                                className={classNames(
                                    css.frame_steps_content,
                                    index === 1 && css.frame_steps_content_action,
                                    !index && css.frame_steps_content_dim
                                )}
                                key={date}
                            >
                                <div className={css.frame_steps_content_left}>
                                    <span>{date}</span>
                                    <i></i>
                                </div>
                                <div className={css.frame_steps_content_right}>
                                    <div className={css.frame_steps_content_right_box}>
                                        <p className={css.steps_right_box_title}>
                                            {versions}{index === 1 && (<span>当前版本</span>)}
                                        </p>
                                        {
                                            dase && <p className={css.steps_right_box_desc}>{dase}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}