import hljs from 'highlight.js';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/github.css';
hljs.registerLanguage('json', json);

import Layout from 'components/layouts';
import Banner from 'components/banner';

import Section, {
    TrackType,
    WithComponent,
    CardList,
    UseStep,
    HighLightNumber,
    TemplateCards,
    TableCase,
    IconList,
    TabRetail
} from 'components/sections';
import localData from 'locals/easydl/retail';

const SubSection = WithComponent(Section)({
    subSection: true
});

const {config, banner, modelTypes, feature, step, scene, recommend, productAdvantages, functionDisplay} = localData;

export default function Retail() {
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />

                <SubSection title={modelTypes.title} style={{paddingTop: '120px'}}>
                    <TemplateCards {...modelTypes} />
                </SubSection>

                <SubSection title={feature.title}>
                    <HighLightNumber data={feature.data} />
                </SubSection>
                <SubSection title={productAdvantages.title}>
                    <IconList {...productAdvantages} />
                </SubSection>
                <SubSection title={functionDisplay.title}>
                    <TabRetail data={functionDisplay.data} />
                </SubSection>
                <SubSection title={step.title}>
                    <UseStep data={step.data} />
                </SubSection>
                <SubSection title={scene.title}>
                    <TableCase data={scene.data} />
                </SubSection>
                <SubSection title={recommend.title} style={{paddingBottom: '134px'}}>
                    <CardList {...recommend} />
                </SubSection>
            </Layout>
        </TrackType.Provider>
    );
}
