/**
 * @file: case
 * @Author: v_panfeng01@baidu.com
 * @Date: 2020-11-10 20:15:20
 * @Last Modified by: v_panfeng01
 * @Last Modified time: 2021-01-04 20:56:24
 */

export default {
    config: {
        trackType: 'EasyDL案例官网',
        layout: 'easydl',
        page: 'case'
    },
    banner: {
        name: 'case',
        title: 'EasyDL应用案例',
        subTitle: 'EasyDL广泛应用于互联网、零售、工业、医疗、安防监控、物流等行业场景中',
        bg: require('assets/banner/case.jpg').default,
        buttonGroup: [
            {
                name: '查看更多案例',
                href: `${process.env.CUSTOMER}?industry=0&technology=8&clickType=tech`,
                size: 'small',
                type: 'primary',
                trackTag: '查看更多案例_官网上方'
            },
            {
                name: '商务合作',
                href: `${process.env.CLOUDCOOPERATION}easydl`,
                size: 'small',
                plain: true,
                trackTag: '商务合作_官网上方'
            }
        ]
    },
    scene: [
        {
            name: '图像内容识别',
            icon: 'image',
            content: [
                {
                    name: '海量图片打分类标签',
                    desc:
                        '将图片进行定制分类标签并进行训练，实现对海量图片自动打标签，实现将图片更好的在前端面向C端用户展示，以及判断用户点击图片内容从而进行相关图片推荐等功能',
                    example: '示例：家装网站可以将图片分类为：卧室、餐厅、厨房等',
                    banner: require('assets/case/censor-a.jpg').default,
                    imgs: [
                        {
                            name: 'idcool',
                            link: `${process.env.CUSTOMER}/idcool`
                        },
                        {
                            name: 'shihuo',
                            link: `${process.env.CUSTOMER}/shihuo`
                        }
                    ]
                },
                {
                    name: '图像审核',
                    desc:
                        '根据需求，制定图片审核标准，用EasyDL判断是否合规。常用于视频、新闻等内容平台定制内容审核策略，过滤不良信息。或用于线上活动，判断C端用户提交图片的合规性',
                    examlie: '示例：房产网站审核用户提交信息是否为户型图、房源图、还是非房源图片',
                    banner: require('assets/case/censor-b.jpg').default,
                    imgs: [
                        {
                            name: 'leet',
                            link: `${process.env.CUSTOMER}/leet`
                        }
                    ]
                }
            ]
        },
        {
            name: '视频图像监控',
            icon: 'vision',
            content: [
                {
                    name: '生产环境安全监控',
                    desc:
                        '对生产环境现场做安全性监控，如是否出现挖掘机等危险物品、工人是否佩戴安全帽、是否穿工作服等进行检查，辅助人工判断安全隐患并及时预警，保证生产环境安全运行',
                    example: '示例：如输电线路附近的安全性检查，需要检测是否存在挖掘机、吊车等外部隐患物体',
                    banner: require('assets/case/sec-a.jpg').default,
                    imgs: [
                        {
                            name: 'southern',
                            link: `${process.env.CUSTOMER}/nfdw`
                        }
                    ]
                },
                {
                    name: '超市防损监控',
                    desc:
                        ' 对超市结算台下层安装的摄像头采集图片进行标注及训练，实现实时监测购物车下层图片中是否有未结算商品',
                    example: '安装摄像头拍摄超市购物车下层，抽帧后可判断有商品、无商品、无车、非购物车',
                    banner: require('assets/case/sec-b.jpg').default,
                    imgs: [
                        {
                            name: 'bob',
                            link: `${process.env.CUSTOMER}/checkpoint`
                        }
                    ]
                },
                {
                    name: '货物状态监控',
                    desc:
                        '根据实际业务场景安装摄像头，采用定时抓拍或视频抽帧的方式，自动判断货物状态，提升业务运营、货品管理效率',
                    example: '示例：对于货船调运公司，智能监控船只上货品状态为有货或无货',
                    banner: require('assets/case/sec-c.jpg').default,
                    imgs: [
                        {
                            name: 'yihang',
                            link: `${process.env.CUSTOMER}/yihangotms`
                        }
                    ]
                }
            ],
            more: [
                {
                    name: '路面质量检测',
                    desc: '对路面常见问题，例如坑槽等病害图片进行训练，辅助政府实时识别路面'
                },
                {
                    name: '江面安全检查',
                    desc: '对江面违法采砂船特征进行训练，辅助人工进行监测识别，提高工作人员处理效率'
                },
                {
                    name: '油井安全检查',
                    desc: '对油田井场摄像头拍摄的周围环境照片进行训练，实现模型可以检查图片中是否有人、是否有漏油'
                },
                {
                    name: '环境卫生检查',
                    desc: '对地面垃圾图片进行训练，通过摄像头定时拍照，实现检测地面图片中是否有垃圾问题并及时通知'
                },
                {
                    name: '城市管理检查',
                    desc: '对城市违法小吃车特征进行训练，辅助人工实时检查是否有乱停乱放现象'
                }
            ]
        },
        {
            name: '工业生产质检',
            icon: 'gear',
            content: [
                {
                    name: '产品组装合格性检查',
                    desc:
                        '在流水线作业中针对组合型产品可能存在的不合格情况进行列举，并投入示例图片进行训练，从而训练出自动判断合格或不合格的模型，辅助人工判断产品质量',
                    example: '示例：键盘生产可能存在错装、漏装、合格进行分类识别',
                    banner: require('assets/case/qa-a.jpg').default,
                    imgs: [
                        {name: 'origin', link: `${process.env.CUSTOMER}/liuzhouyuanchuang`},
                        {name: 'butterflyFish', link: `${process.env.CUSTOMER}/aibao`}
                    ]
                },
                {
                    name: '瑕疵检测',
                    desc:
                        '微小瑕疵检测，针对原始图片或基于光学成像图片进行瑕疵标注及训练，将模型集成在检测器或流水线中，辅助人工提升质检效率，降低成本',
                    example: '示例：针对地板质检的常见问题进行识别，例如虫眼、毛面、棘爪等',
                    banner: require('assets/case/qa-b.jpg').default,
                    imgs: []
                }
            ]
        },
        {
            name: '专业领域研究',
            icon: 'flask',
            content: [
                {
                    name: '医疗镜检识别',
                    desc:
                        '针对医疗检验场景中，可能存在的正常或异常的结果判断，基于图片关键特征进行标注进行物体检测/图像分类训练，协助医生高效完成结果判断',
                    example: '示例：针对寄生虫卵镜检图片，判断虫卵类型从而对症下药',
                    banner: require('assets/case/research-a.jpg').default,
                    imgs: []
                },
                {
                    name: '培训或科普',
                    desc:
                        '将专业图片识别进行标注及模型训练，配合详细的图片介绍信息，集成在公司内部使用的培训app中，方便新人通过拍照识图快速上手业务',
                    example: '示例：车辆零部件识别',
                    banner: require('assets/case/research-b.jpg').default,
                    imgs: [{name: 'wuhucj', link: `${process.env.CUSTOMER}/cjatech`}]
                }
            ]
        },
        {
            name: '零售商品识别',
            icon: 'bag',
            content: [
                {
                    name: '货架陈列合规性检查',
                    desc:
                        '将商品陈列图片进行标注及训练，集成在app中供巡货员或者店员拍照上传自动识别，通过系统自动检查完成合规性的准确检查',
                    example: '示例：在货架及货柜中检测出第一排商品的个数、位置',
                    banner: require('assets/case/sku-a.jpg').default,
                    imgs: [{name: 'edianjia', link: `${process.env.CUSTOMER}/hzhuihe`}]
                },
                {
                    name: '自助结算',
                    desc: '对业务场景售卖的商品进行图片标注及训练，与摄像头、支付系统结合，辅助实现自助结算的流程',
                    example: '示例：结合在结算台的商品图像，识别出具体商品',
                    banner: require('assets/case/sku-b.jpg').default,
                    imgs: [{name: 'focusbear'}]
                },
                {
                    name: '互动营销',
                    desc:
                        '训练定制的商品识别，实现对C端用户提交的商品图片进行识别，配合游戏规则完成闯关/抽奖式的互动营销',
                    example: '示例：对KFC食物进行检测后，为就餐顾客提供拍照闯关活动',
                    banner: require('assets/case/sku-c.jpg').default,
                    imgs: [{name: 'leapLearner'}]
                },
                {
                    name: '电商平台商品管理',
                    desc: '根据对商品细节进行标注和模型训练，实现商品图库的快速分类，减少人工标注的成本',
                    example: '示例：针对鞋类商品判断鞋底花纹图案',
                    banner: require('assets/case/sku-d.jpg').default,
                    imgs: [{name: 'shihuo', link: `${process.env.CUSTOMER}/shihuo`}]
                }
            ],
            more: [
                {
                    name: '服装识别',
                    desc: '训练线下服装实体店通过摄像头拍摄的商品图像，辅助消费者快速了解商品信息'
                },
                {
                    name: '翡翠识别',
                    desc: '训练部分翡翠图像，搭载小程序对用户上传的翡翠手镯照片进行真伪辨别'
                },
                {
                    name: '蔬菜识别',
                    desc: '训练菜市场蔬菜水果图片，结合智能秤，辅助自动结算'
                }
            ]
        }
    ]
};
