import {useState} from 'preact/hooks';

import Layout from 'components/layouts';
import Banner from 'components/banner';
import Tab from 'components/tab';
import Section, {TrackType, UseStep, StartUse} from 'components/sections';
import lcoalData from 'locals/easydl/solution';

import 'style/easydl/solution.less';
const {solution} = lcoalData;
export default function Solution() {
    const {config, banner, navData, step, compare, jetson, start} = lcoalData;
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />

                <Tab navData={navData} />
                <Section title={step.title} id={step.id}>
                    <UseStep id={step.id} data={step.data} title={step.title} />
                </Section>
                <Section title={compare.title} id={compare.id}>
                    <SolutionIntro data={compare.data} />
                </Section>
                <Section title={jetson.title} id={jetson.id}>
                    <Jetson data={jetson.data} jetsonDesc={jetson.jetsonDesc} jetsonLink={jetson.jetsonLink} />
                </Section>
                <StartUse {...start} />
            </Layout>
        </TrackType.Provider>
    );
}

const solutionSelect = solution.map((item, i) => {
    if (i === solution.length - 1 || item.isOriginal) {
        return item.title;
    }
    return `${item.title}软硬一体方案`;
});

const SolutionIntro = (props) => {
    const [selectActive, setSelectActive] = useState(false);
    const [activeSolution, setActiveSolution] = useState(3);
    const setActive = (index) => {
        setActiveSolution(index);
        selectActive && setSelectActive(false);
    };
    const onSelectClick = () => {
        setSelectActive(!selectActive);
    };
    const selected = solution[activeSolution];
    return (
        <div class="solution_intro">
            <div class="left">
                <div class="title">EasyDL软硬一体方案价格及性能分布图</div>
                <div class="desc">图中不同方案的位置仅用于展示相对关系，具体价格和性能请点击方案查看，并以实测为准</div>
                <div class="chart">
                    <svg width="536" height="556" version="1.2" xmlns="http://www.w3.org/2000/svg">
                        <g class="chart-axis">
                            <line class="chart-axis-x" x1="2" y1="530" x2="492" y2="530" />
                            <line class="chart-axis-y" x1="2" y1="530" y2="60" />
                        </g>
                        <g class="chart-axis-line-x">
                            <line class="chart-line-x" y1="60" x2="492" y2="60" />
                            <line class="chart-line-x" y1="127" x2="492" y2="127" />
                            <line class="chart-line-x" y1="194" x2="492" y2="194" />
                            <line class="chart-line-x" y1="261" x2="492" y2="261" />
                            <line class="chart-line-x" y1="328" x2="492" y2="328" />
                            <line class="chart-line-x" y1="395" x2="492" y2="395" />
                            <line class="chart-line-x" y1="462" x2="492" y2="462" />
                        </g>
                        <g class="text-wrapper">
                            <text y="546">0</text>
                            <text class="end" x="536" y="534">
                                6000
                            </text>
                            <text class="end" x="536" y="552">
                                价格(元/套)
                            </text>
                            <text y="48">150</text>
                            <text y="30">性能(FPS)</text>
                        </g>
                    </svg>
                    <div class="icon_wrapper">
                        {solution.map((item, i) => {
                            const isActive = i === activeSolution;
                            return (
                                <div
                                    key={i}
                                    class={`prod_item prod_item_${i} ${item.batch ? 'prod_batch_item' : ''} ${
                                        isActive ? 'active' : ''
                                    }`}
                                    onClick={() => setActive(i)}
                                >
                                    <i class={`tzfont tzfont-${item.icon} tzfont_${i}`} />
                                    <div class={`popover popover_${item.popover}`}>
                                        <span>{item.title}</span>
                                    </div>
                                    {item.light && <div class={`fast_light ${isActive ? ' active' : ''}`} />}
                                    {item.batch && (
                                        <div class="prod_batch">
                                            <div class="popover popover_left popover_ceil">
                                                <span>支持多batch预测</span>
                                            </div>
                                            <i class="tzfont tzfont-book" />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div class="right">
                <div class={`solution_intro_title ${selectActive ? 'active' : ''}`} onClick={onSelectClick}>
                    <span>
                        {selected.title}
                        {(activeSolution !== solution.length - 1 && !selected.isOriginal) && '软硬一体方案'}
                    </span>
                    <i class="tzfont tzfont-down-arrow" />
                </div>
                <ul class="solution_intro_select">
                    {solutionSelect.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => setActive(index)}
                            class={`solution_intro_select_item ${index === activeSolution ? 'active' : ''}`}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
                <div class="solution_attrs">
                    <div class="img_wrapper">
                        <img src={selected.image} alt="" />
                    </div>
                    <ul class="attr_wrapper">
                        {selected.attrs.map((item) => (
                            <li class="attr_item">
                                {item[0]}： {item[1].value}
                            </li>
                        ))}
                    </ul>
                </div>
                <div class="sub_title">产品特点</div>
                <ul class="solution_intro_attrs">
                    {selected.feature.map((item, i) => (
                        <li key={i} class="solution_intro_attrs_item">
                            {item}
                        </li>
                    ))}
                </ul>
                <div class="solution_intro_param">
                    <div class="sub_title">单图识别时间</div>
                    <div class="thead">
                        <div class="thead_wrapper">
                            <span>EasyDL</span>
                            <span>BML（原专业版）</span>
                        </div>
                    </div>
                    <table class="param_table">
                        <tbody>
                            <tr>
                                {selected.paramsTable.label.map((item, i) => (
                                    <td key={i}>{item}</td>
                                ))}
                            </tr>
                            <tr>
                                {selected.paramsTable.data[0].params.map((item, i) => (
                                    <td key={i}>{item}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ops">
                    {selected.links.map((link, i) => (
                        <a key={i} href={link.href} target="_blank" rel="noopener noreferrer" class="link">
                            {link.name}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Jetson = (props) => {
    const {data, jetsonDesc, jetsonLink} = props;
    return (
        <div class="jetson">
            <div class="jetson_intro">
                <div class="left">
                    <img class="img" src={require('assets/solution/limit-logo.jpg').default} alt="" />
                </div>
                <div class="right">
                    <p class="text_wrapper">{jetsonDesc}</p>
                    <div class="links">
                        {jetsonLink.map(({text, link}) => (
                            <a href={link} class="link" target="_blank" rel="noopener noreferrer">
                                {text}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            <div class="jetson_content">
                {data.map((item, i) => (
                    <a key={i} href={item.url} target="_blank" rel="noopener noreferrer" class="jetson_item">
                        <div class="left">
                            <div class="title">{item.name}</div>
                            <div class="desc">{item.desc}</div>
                            <div class="param">{item.param}</div>
                        </div>
                        <div class="right">
                            <img src={item.image} />
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};
