/**
 * BML个人中心改版
 */

import css from './index.less';
import classnames from 'classnames';
import {genClassName} from 'utils';

const cls = genClassName(css);

export const BMLActions = () => {
    return (
        <div className={classnames(css['nav-item'], css['nav-item--user-bml-wrapper'])}>
            <img style={{display: 'none'}} id="user-avatar" src="{{avatar}}" className={css['user-avatar']} />
            <a id="user-name" href="javascript:;" />
            <div className={classnames(css['sub-div'], css['sub-div_user_bml'])}>
                <header>
                    <div className={classnames(css['user-info'], css['flex-row'])}>
                        <img id="user-panel-avatar" className={classnames(css['user-panel-avatar'])} />
                        <span id="user-panel-name" className={classnames(css['user-panel-name'])} />
                    </div>
                </header>
                <a
                    // eslint-disable-next-line max-len
                    href="javascript: window.dispatchEvent(new CustomEvent('afe:notifyRootApp', {detail: {key: 'userModal'}}));"
                    className={classnames(css['main-item'], css['flex-row'])}
                >
                    <span className={classnames(css['user-panel-icon'])} role="user-icon" />
                    个人信息
                </a>
                <div className={classnames(css['main-item'], css['flex-row'])}>
                    <span className={classnames(css['user-panel-icon'])} role="finance-icon" />
                    财务中心
                </div>
                <div className={classnames(css['sub-items'])}>
                    <a href="//console.bce.baidu.com/billing/#/account/index" className={classnames(css['sub-item'])}>
                        查看余额
                    </a>
                    <a href="//console.bce.baidu.com/billing/#/order/list" className={classnames(css['sub-item'])}>
                        订单管理
                    </a>
                    <a href="//console.bce.baidu.com/billing/#/account/index" className={classnames(css['sub-item'])}>
                        可用券码
                    </a>
                    <a
                        href="//console.bce.baidu.com/finance/#/finance/account/dealrecord"
                        className={classnames(css['sub-item'])}
                    >
                        收支明细
                    </a>
                </div>
                <footer>
                    <a className={classnames(css['main-item'], css['flex-row'])} href="/easydl/logout">
                        <span className={classnames(css['user-panel-icon'])} role="quit-icon" />
                        退出登录
                    </a>
                </footer>
            </div>
        </div>
    );
};

export const EasyDLActions = () => {
    return (
        <div className={classnames(cls('nav-item'), cls('nav-item--user-wrapper'))}>
            <img style={{display: 'none'}} id="user-avatar" src="{{avatar}}" className={cls('user-avatar')} />
            <a id="user-name" href="javascript:;" />
            <div className={classnames(cls('sub-div'), cls('sub-div_user'))}>
                <div className={cls('sub-div-item_user')}>
                    <div className={cls('sub-div-item_link')}>
                        <a href="/easydl/logout">退出登录</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const GeelyActions = () => {
    return (
        <div className={classnames(cls('nav-item'), cls('nav-item--user-wrapper'))}>
            <img style={{display: 'none'}} id="user-avatar" src="{{avatar}}" className={cls('user-avatar')} />
            <a id="user-name" href="javascript:;" />
            <div className={classnames(cls('sub-div'), cls('sub-div_user'))}>
                <div className={cls('sub-div-item_user')}>
                    <div className={cls('sub-div-item_link')}>
                        <a href="/easydl/logout">退出登录</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SmartasrActions = () => {
    return (
        <div className={classnames(cls('nav-item'), cls('nav-item--user-wrapper'))}>
            <img style={{display: 'none'}} id="user-avatar" src="{{avatar}}" className={cls('user-avatar')} />
            <a id="user-name" href="javascript:;" />
            <div className={classnames(cls('sub-div'), cls('sub-div_user'))}>
                <div className={cls('sub-div-item_user')}>
                    <div className={cls('sub-div-item_link')}>
                        <a href="/easydl/logout">退出登录</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const WenxinActions = () => {
    return (
        <div className={classnames(cls('nav-item'), cls('nav-item--user-wenxin-wrapper'))}>
            <img style={{display: 'none'}} id="user-avatar" src="{{avatar}}" className={cls('user-avatar')} />
            <a id="user-name" href="javascript:;" />
            <div className={classnames(cls('sub-div'), cls('sub-div_user'))}>
                <div className={cls('sub-div-item_user')}>
                    <div className={cls('sub-div-item_link')}>
                        <a href="/wenxinworkshop/api/carrier/api/logout">退出登录</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
