import {useState, useEffect, useRef, useMemo} from 'preact/hooks';
import AsyncValidator from 'async-validator';
import Layout from 'components/layouts';

import {cooperationRules, send, scrollToTop, getQueryParam, redirectURL, jumpToURL, assetURL} from 'utils';
// 页面数据
import {techType, techService, purpose, classification, environment, errorMsgArr} from 'locals/wenxin/cooperation';
import {industry} from 'locals/wenxin/common';
import {TrackType} from 'components/sections';
import {Field, Input, Submit, Radio, Select, Checkbox, CheckboxGroup, CodeImg} from 'components/base/field';

import 'style/wenxin/cooperation.less';
import {flagCooperationRequest} from '../../utils/cooperationFlags';
const customerTypes = [
    {key: '1', title: '企业'},
    {key: '2', title: '个人'}
];

export default function Cooperation() {
    const [formData, setFormData] = useState({
        techType: '1',
        techService: '101',
        industry: '1',
        website: null,
        introduction: null,
        description: null,
        customerType: null,
        company: null,
        customerName: null,
        phone: null,
        email: null,
        verifyCode: null,
        scene: null,
        purpose: null,
        classification: null,
        environment: null,
        purposeOther: null,
        classificationOther: null,
        environmentOther: null
    });
    const [formTips, setFormTips] = useState({
        techType: null,
        techService: null,
        industry: null,
        website: null,
        introduction: null,
        description: null,
        customerType: null,
        company: null,
        customerName: null,
        phone: null,
        email: null,
        verifyCode: null,
        scene: null,
        purpose: null,
        classification: null,
        environment: null
    });
    const modelContentRef = useRef(null);
    const [modelData, SetModelData] = useState({});
    const [submitRes, setSubmitRes] = useState(true);
    const [showCompany, setShowCompany] = useState(true);
    const isPaddlepaddle = formData.techType === '4';
    const isPaddleflow = formData.techType === '5';
    const isvisual = formData.techType === '6';
    const isernie = formData.techType === '7';
    const isocr = formData.techType === '8';
    const rules = useMemo(() => {
        return cooperationRules(showCompany, isPaddlepaddle);
    }, [showCompany, isPaddlepaddle]);
    const handleError = (err) => {
        const errObj = err.reduce(
            (pre, {message, field}) => ({
                ...pre,
                [field]: message
            }),
            {}
        );
        setFormTips({...formTips, ...errObj});
    };
    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        // 处理客户类型
        if (field === 'customerType') {
            setShowCompany(value === '1');
        }
        if (field === 'techType') {
            setFormData({
                ...formData,
                [field]: value,
                techService: techService.filter((it) => it.key === parseInt(value, 10))[0]?.value
            });
        } else {
            setFormData({
                ...formData,
                [field]: value
            });
        }
    };

    const handleBlur = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        const validator = new AsyncValidator({
            [field]: rules[field]
        });
        setFormTips({
            ...formTips,
            [field]: null
        });
        validator.validate({[field]: value}).catch(({errors}) => {
            errors && handleError(errors);
        });
    };

    const formSubmit = () => {
        const method = 'record/consult/create';
        let isLogin = true;
        if (isernie) {
            setFormData((formData.techService = '701'));
            isLogin = window.isLogin;
        }
        const validator = new AsyncValidator(rules);
        if (isLogin) {
            validator
                .validate(formData)
                .then(() => {
                    send({
                        method,
                        payload: formData,
                        baseURL: '/wenxin/api'
                    })
                        .then((res) => successCb(res))
                        .catch((err) => errorCb(err));
                })
                .catch(({errors}) => handleError(errors));
        } else {
            let tourl = assetURL('https://ai.baidu.com/wenxin/cooperation', formData);
            jumpToURL(redirectURL(tourl));
        }
    };

    const successCb = (res) => {
        if (res.success) {
            setSubmitRes(false);
            scrollToTop();
            SetModelData({
                state: 'success',
                title: '提交成功',
                desc: '将会有工作人员和您联系, 沟通进一步事宜',
                btn: '3S 跳转至首页'
            });
            countDown();
            flagCooperationRequest(formData);
            return;
        }

        const errMsg = errorMsgArr.find((it) => it.field === Object.entries(res.message.field)[0]);
        if (errMsg) {
            handleError([errMsg]);
        } else {
            errorCb();
        }
    };

    const errorCb = () => {
        setSubmitRes(false);
        scrollToTop();
        SetModelData({
            state: 'fail',
            title: '提交失败，请检查网络连接',
            desc: '您录入的信息已自动保存，可返回页面重新提交',
            btn: '返回'
        });
    };

    function countDown(time = 3) {
        let timeout = setTimeout(() => {
            time--;
            modelContentRef.current.textContent = `${time > 0 ? time : 1}S 跳转至首页`;
            if (time > 0) {
                countDown(time);
            }
            if (time === 0) {
                window.location.href = '/wenxin';
            }
            clearTimeout(timeout);
        }, 1000);
    }

    useEffect(() => {
        send({method: 'common/userInfo'})
            .then((response) => {
                const login = response.result;
                if (login) {
                    window.isLogin = true;
                    return;
                }
                window.isLogin = false;
            })
            .catch((e) => console.error(e));
        const queryParam = getQueryParam();
        if (queryParam.techType && queryParam.techService) {
        }
        queryParam.verifyCode = null;
        setFormData({...formData, ...queryParam});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TrackType.Provider value={{trackType: 'wenxin官网', layout: 'wenxin', page: 'cooperation'}}>
            <Layout>
                {submitRes ? (
                    <div className="cooperation-content">
                        <div className="form-body">
                            <div className="form-title">填写信息，咨询与合作</div>
                            <div className="form-desc">业务与需求信息</div>
                            <div className="form-content">
                                <Field label="咨询的技术服务" tip={formTips.techType}>
                                    <Select
                                        name="techType"
                                        onChange={handleChange}
                                        value={formData.techType}
                                        selectData={techType}
                                    />
                                </Field>
                                {!isPaddlepaddle && !isPaddleflow && !isvisual && !isernie && !isocr && (
                                    <Field label="咨询的服务类型" tip={formTips.techService}>
                                        <Select
                                            name="techService"
                                            onChange={handleChange}
                                            value={formData.techService}
                                            selectData={techService}
                                        />
                                    </Field>
                                )}
                                <Field label="您的行业" tip={formTips.industry}>
                                    <Select
                                        name="industry"
                                        onChange={handleChange}
                                        value={formData.industry}
                                        selectData={industry}
                                    />
                                </Field>
                                <Field label="您公司的网址" required={false} tip={formTips.website}>
                                    <Input
                                        name="website"
                                        placeholder="选填，请输入公司网址"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.website}
                                    />
                                </Field>
                                <Field label="公司业务介绍" required={false} tip={formTips.introduction}>
                                    <Input
                                        name="introduction"
                                        placeholder="选填，请简要介绍您公司的业务"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.introduction}
                                    />
                                </Field>
                                <Field label="AI需求描述" tip={formTips.description}>
                                    <Input
                                        name="description"
                                        placeholder="请描述您对AI的具体需求"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.description}
                                        tag={false}
                                    />
                                </Field>
                                {isPaddlepaddle && (
                                    <>
                                        <Field
                                            label="应用场景"
                                            tip={formTips.scene}
                                            desc="例如：您是一家工厂管理者，由于工厂位置偏远，您需要在无网的环境下完成模型训练。"
                                        >
                                            <Input
                                                name="scene"
                                                placeholder="本地化训练的具体应用行业场景"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={formData.scene}
                                            />
                                        </Field>
                                        <Field label="主要用途（多选）" tip={formTips.purpose}>
                                            <CheckboxGroup
                                                name="purpose"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleBlur(e);
                                                }}
                                                value={formData.purpose}
                                            >
                                                {purpose.map((item) => (
                                                    <Checkbox key={item.value} value={item.value} label={item.label} />
                                                ))}
                                                <Checkbox
                                                    value="other"
                                                    label="其他"
                                                    right={
                                                        <Input
                                                            className="form-checkbox-input"
                                                            onChange={handleChange}
                                                            name="purposeOther"
                                                            disabled={!formData.purpose?.includes('other')}
                                                            value={formData.purposeOther}
                                                        />
                                                    }
                                                />
                                            </CheckboxGroup>
                                        </Field>

                                        <Field label="模型类型（多选）" tip={formTips.classification}>
                                            <CheckboxGroup
                                                name="classification"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleBlur(e);
                                                }}
                                                value={formData.classification}
                                            >
                                                {classification.map((item) => (
                                                    <Checkbox
                                                        key={item.value}
                                                        value={item.value}
                                                        label={item.label}
                                                        bottom={<p className="form-checkbox-desc">{item.desc}</p>}
                                                    />
                                                ))}
                                                <Checkbox
                                                    value="other"
                                                    label="其他"
                                                    right={
                                                        <Input
                                                            className="form-checkbox-input"
                                                            name="classificationOther"
                                                            onChange={handleChange}
                                                            disabled={!formData.classification?.includes('other')}
                                                            value={formData.classificationOther}
                                                        />
                                                    }
                                                />
                                            </CheckboxGroup>
                                        </Field>
                                        <Field label="部署环境（多选）" tip={formTips.environment}>
                                            <CheckboxGroup
                                                name="environment"
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    handleBlur(e);
                                                }}
                                                value={formData.environment}
                                            >
                                                {environment.map((item) => (
                                                    <Checkbox key={item.value} value={item.value} label={item.label} />
                                                ))}
                                                <Checkbox
                                                    value="other"
                                                    label="其他"
                                                    right={
                                                        <Input
                                                            disabled={!formData.environment?.includes('other')}
                                                            className="form-checkbox-input"
                                                            name="environmentOther"
                                                            onChange={handleChange}
                                                            value={formData.environmentOther}
                                                        />
                                                    }
                                                />
                                            </CheckboxGroup>
                                        </Field>
                                    </>
                                )}
                            </div>
                            <div className="form-desc">
                                客户基本信息
                                {isPaddlepaddle ? (
                                    <span className="tips">
                                        （填写真实有效的信息可以帮助我们尽快与您取得联系，更及时提供服务）
                                    </span>
                                ) : (
                                    <span className="tips">（注我们会尽快与您取得联系，为您提供及时服务）</span>
                                )}
                            </div>
                            <div className="form-content">
                                <Field label="客户类型" tip={formTips.customerType} radio>
                                    <Radio
                                        name="customerType"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.customerType}
                                        radios={customerTypes}
                                    />
                                </Field>

                                {showCompany && (
                                    <Field label="公司名称" tip={formTips.company}>
                                        <Input
                                            name="company"
                                            placeholder="请输入公司名称"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={formData.company}
                                        />
                                    </Field>
                                )}

                                <Field label="业务联系人" tip={formTips.customerName}>
                                    <Input
                                        name="customerName"
                                        placeholder="请输入联系人姓名"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.customerName}
                                    />
                                </Field>
                                <Field label="联系人手机号" tip={formTips.phone}>
                                    <Input
                                        name="phone"
                                        placeholder="请输入手机号"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.phone}
                                    />
                                </Field>
                                <Field label="联系人邮箱" required={false} tip={formTips.email}>
                                    <Input
                                        name="email"
                                        placeholder="选填，请输入您的邮箱"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.email}
                                    />
                                </Field>
                                <Field label="验证码" tip={formTips.verifyCode}>
                                    <Input
                                        name="verifyCode"
                                        placeholder="请输入右侧验证码"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.verifyCode}
                                    />
                                    <CodeImg />
                                </Field>
                                <Field required={false}>
                                    <Submit onClick={formSubmit} />
                                </Field>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="model-content">
                        <div className="model-consult">
                            <div className={`model-icon-${modelData.state}`} />
                            <div className="model-title">{modelData.title}</div>
                            <div className="model-desc">{modelData.desc}</div>
                            {modelData.state === 'success' ? (
                                <div className="model-back-success" ref={modelContentRef}>
                                    {modelData.btn}
                                </div>
                            ) : (
                                <div className="model-back-fail" onClick={() => setSubmitRes(true)}>
                                    {modelData.btn}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Layout>
        </TrackType.Provider>
    );
}
