export default {
    config: {
        trackType: 'EasyDL零售官网',
        layout: 'easydl',
        page: 'retail'
    },
    banner: {
        name: 'retail',
        title: 'EasyDL零售行业版',
        subTitle:
            '训练定制化商品检测和地堆检测模型，开放基于百度大规模零售数据的预训练模型、<br/>及数据增强合成技术，实现低成本获得高精度AI模型服务',
        bg: require('assets/banner/retail.jpg').default,
        buttonGroup: [
            {
                name: '开始训练',
                href: process.env.COMMODITYIDENTIFICATION,
                size: 'small',
                type: 'primary',
                trackTag: '立即使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL立即使用',
                    'data-track-name': 'EasyDL-产品页面-立即使用',
                    'data-track-value': ''
                }
            },
            {
                name: '技术文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Jk38n3gu5',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            },
            {
                name: '产品价格',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Fknh4zs1r',
                size: 'small',
                plain: true,
                trackTag: '产品价格_官网上方'
            }
        ],
        video: 'http://hbnm3eqf9mju26279qj.exp.bcevod.com/mda-jh4m627fahdu4wkn/mda-jh4m627fahdu4wkn.mp4'
    },
    modelTypes: {
        title: '源于行业场景的模型类型',
        data: [
            {
                title: '商品检测',
                desc: '适用于适用于货架、端架、挂架等场景的商品陈列规范核查，支持识别商品基本信息，陈列顺序、层数、场景，统计排面数量和占比',
                keywords: ['基本信息识别', '陈列场景识别', '陈列层数识别', '排面占比统计'],
                img: require('assets/subpage/retail-commodity-inspection.jpg').default,
                link: {
                    name: '立即使用',
                    href: process.env.COMMODITYIDENTIFICATION
                }
            },
            {
                title: '地堆检测',
                desc: '适用于堆箱、堆头、地龙等场景的商品陈列规范核查，支持识别商品基本信息，可视商品计数，纵深商品计数和占地面积',
                keywords: ['基本信息识别', '陈列场景识别', '空间逻辑计数', '占地面积估算'],
                img: require('assets/subpage/retail-tg-inspection.jpg').default,
                link: {
                    name: '立即使用',
                    href: process.env.COMMODITYIDENTIFICATION
                }
            }
        ]
    },
    feature: {
        title: '全面满足行业需求的服务功能',
        data: [
            {
                title: '基本信息识别',
                desc: ['商品的名称、品牌、规格、编号', '商品在图片中的坐标位置', '商品识别的置信度']
            },
            {
                title: '陈列层数识别',
                desc: ['商品陈列所在货架层数和货架总层数', '商品陈列顺序', '货架是否拍摄完整判断']
            },
            {
                title: '排面占比统计',
                desc: [
                    '商品的排面数及排面占比率',
                    '每层货架可识别商品数量及未知商品数量',
                    '货架的总空位数、每层货架空位数及货架利用率'
                ]
            },
            {
                title: '陈列场景识别',
                desc: ['货架场景：货架、端架、挂架、斜口篮', '冰柜场景：立式冰柜、卧式冰柜、冷风柜', '地堆场景：堆箱、割箱、地龙']
            }
        ]
    },
    productAdvantages: {
        title: '增值服务',
        data: [
            {
                img: require('assets/subpage/retail-shelf-splicing.svg').default,
                name: '货架拼接',
                desc: '货架拼接服务支持将多个货架局部图片或视频，拼接为完整货架图片。同时支持输出在完整货架图中的商品检测结果，包含SKU的名称、数量和占比，适用于需要在长货架进行商品检测的业务场景',
                links: [
                    {href: 'https://ai.baidu.com/easydl/app/1001/10010/vas/img-stitch/', name: '立即使用'},
                    {href: 'https://ai.baidu.com/ai-doc/EASYDL/tk38n3j9h', name: 'API文档'},
                    {href: 'https://ai.baidu.com/ai-doc/EASYDL/6k38n3j1a', name: 'SDK文档'}
                ]
            },
            {
                img: require('assets/subpage/retail-remake-recognition.svg').default,
                name: '翻拍识别',
                desc: '识别出通过手机对屏幕翻拍出的商品陈列照片，比如商品货架陈列图片和地堆商品陈列图片，可降低人工审核人力，高效审核零售业务中通过翻拍原有图片来造假的图片',
                links: [{href: 'https://ai.baidu.com/ai-doc/EASYDL/dk3tmho35', name: 'API文档'}]
            },
            {
                img: require('assets/subpage/retail-price-tag-identification.svg').default,
                name: '价签识别',
                desc: '识别货架和促销活动中的价签信息，可识别各个价签在图片中的像素位置，以及价签内商品名称和价格，可用于洞察商品在线下渠道分销的价格区间',
                links: [{href: 'https://ai.baidu.com/ai-doc/EASYDL/Okppfchc8', name: 'API文档'}]
            }
        ]
    },
    step: {
        title: '简单五步体验全流程，轻松获得AI模型',
        data: [
            {name: '创建模型', desc: '根据业务需求和陈列场景选择创建的模型类型'},
            {name: '创建SKU', desc: '自定义创建SKU标签，含名称、品牌和规格'},
            {name: '实景图上传/标注', desc: '创建实景图集，上传实际业务中的实景图并标注'},
            {name: '训练模型', desc: '选择SKU和实景图集训练模型'},
            {name: '发布模型调用接口', desc: '将模型部署在云端，发布为云服务API进行调用'}
        ]
    },
    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '货架合规性检查',
                desc: '精准识别出货架、冰柜和端架上陈列商品的数量和种类，为品牌商分析陈列排面占比，重点SKU分销率、缺货率、合格率提供数据支撑',
                banner: require('assets/subpage/retail-scene-goods-shelves.png').default,
                imgs: [
                    {
                        src: require('assets/easydl/logo/07.jpg').default,
                        href: `${process.env.CUSTOMER}/hzhuihe`
                    }
                ]
            },
            {
                name: '地堆合规性检查',
                desc: '适用于堆箱、堆头、地龙等场景的商品陈列规范核查，支持识别商品基本信息，可见商品计数，纵深商品计数和占地面积',
                banner: require('assets/subpage/retail-scene-tg.jpg').default
            }
        ]
    },
    recommend: {
        title: '相关推荐',
        data: [
            {
                name: 'EasyDL图像',
                href: process.env.IMAGE,
                desc: '识别图片中的门脸文字信息，自动过滤非门脸文字内容，接口返回门脸名称、描述文字和置信度，最多支持返回图片中10个门脸信息'
            },
            {
                name: '数字化访销解决方案',
                href: 'https://ai.baidu.com/solution/fmcg',
                desc: '围绕快速消费品企业在线下渠道中的销量逻辑，提供基于AI技术的数字化访销解决方案，实现对访销过程的精细化管理'
            },
            {
                name: '全功能AI开发平台BML',
                href: process.env.BML,
                desc: '企业级AI开发平台，一站式完成模型构建、训练、部署的全部工作'
            }
        ]
    },
    functionDisplay: {
        title: '功能展示',
        data: [
            {
                title: '商品检测',
                image: require('assets/subpage/retail-function-display-01.png').default,

                res: [
                    [['陈列场景', '普通货架']],
                    [['货架总层数', '2层']],
                    [['货架利用率', '95.8%']],
                    [['空位数', '1']],
                    [['商品总排面占比', '34.7%']],
                    [
                        ['商品标签1', '冰红茶_康师傅_500ml'],
                        ['商品排面数', '5 个'],
                        ['商品位置', '第1层，第5、6、7、8、9个']
                    ],
                    [
                        ['商品标签2', '绿茶_统一_500ml'],
                        ['商品排面数', '3 个'],
                        ['商品位置', '第2层 ，第3、4、5个']
                    ]
                ],
                doc: 'https://ai.baidu.com/ai-doc/EASYDL/Rk38n3n39'
            },
            {
                title: '地堆检测',
                image: require('assets/subpage/retail-function-display-02.png').default,

                res: [
                    [
                        ['商品标签1', '纯生_青岛啤酒_500ml*12'],
                        ['商品所在列', '第1列'],
                        ['可见数量', '4个'],
                        ['纵深数量', '6个']
                    ],
                    [
                        ['商品标签2', '经典10度_青岛啤酒_500ml*12'],
                        ['商品所在列', '第2、3列'],
                        ['可见数量', '8个'],
                        ['纵深数量', '13个']
                    ],
                    [['堆箱占地数量', '6个']]
                ],
                doc: 'https://ai.baidu.com/ai-doc/EASYDL/Kkppept12'
            },
            {
                title: '货架拼接',
                image: require('assets/subpage/retail-function-display-03.png').default,

                res: [
                    [
                        ['商品标签1', '特浓可可_阿华田_250ml'],
                        ['商品数量', '6个'],
                        ['商品占比', '16.7%']
                    ],
                    [
                        ['商品标签2', '浓香原味_元气森林_450ml'],
                        ['商品数量', '5个'],
                        ['商品占比', '13.9%']
                    ]
                ],
                doc: 'https://ai.baidu.com/ai-doc/EASYDL/tk38n3j9h'
            },
            {
                title: '翻拍识别',
                image: require('assets/subpage/retail-function-display-04.png').default,

                res: [[['图片类型', '翻拍']]],
                doc: 'https://ai.baidu.com/ai-doc/EASYDL/dk3tmho35'
            },
            {
                title: '价签识别',
                image: require('assets/subpage/retail-function-display-05.png').default,

                res: [
                    [
                        ['价签1', '500ml农夫山泉茉莉茶'],
                        ['价格', '4.80']
                    ],
                    [
                        ['价签2', '小茗同学溜溜哒茶480m'],
                        ['价格', '5.00']
                    ]
                ],
                doc: 'https://ai.baidu.com/ai-doc/EASYDL/Okppfchc8'
            }
        ]
    }
};
