import {useState, useMemo, useEffect, useRef} from 'preact/hooks';
import css from './index.less';
import classnames from 'classnames';
import {send} from 'utils';
import baidubce from '@baiducloud/bos-uploader-lite';


export function Uploader(props) {
    const {accept, setValue} = props;
    const inputFile = useRef();
    const uploader = useRef();
    const [bos, setBos] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [isSuccess, setIsSuccess] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        send({method: 'bos/auth'}).then((response) => {
            if (response.success) {
                setBos(response.result);
            }
        }).catch((e) => console.error(e));
    }, []);
    useEffect(() => {
        if (bos) {
            uploader.current = new baidubce.bos.Uploader({
                browse_button: '#imageFile',
                max_file_size: '300M',
                bos_multipart_min_size: '0',
                auto_start: true,
                accept: accept.join(','),
                init: {
                    Key(_, file) {
                        uploader.current.setOptions({
                            bos_ak: bos.tempAK,
                            bos_sk: bos.tempSK,
                            uptoken: bos.sessionToken,
                            bos_endpoint: `https://${bos.endpoint}`,
                            bos_bucket: bos.bucket,
                        });
                        const key = keyName(file);
                        setFileName(key);
                        return key;
                    },
                    FileFiltered(_, file) {
                        const key = keyName(file);
                        const size = file.size;
                        if ((size >= (1024 * 1024 * 300))) {
                            setIsSuccess(false);
                            setError('压缩包不得超过300M，请核对后上传！');
                        }
                        setFileName(key);
                    },
                    FileUploaded: function (_, file, info) {
                        setIsSuccess(true);
                        // 文件上传成功之后，调用这个函数
                        // 处理成功的业务逻辑
                    },
                    Error: function (_, error, file) {
                        setIsSuccess(false);
                        // 如果上传的过程中出错了，调用这个函数
                        if (error.message) {
                            setError(error.message);
                        }
                    },
                    BeforeUpload: async (_, file) => {
                        const re = new RegExp(`.(${accept.join('|')}$)`);
                        if (!re.test(file.name)) {
                            const key = keyName(file);
                            setFileName(key);
                            setIsSuccess(false);
                            setError(`压缩包只支持后缀 ${accept.join(' ')}，请核对后上传！`);
                            return Promise.reject();
                        }
                    }
                }
            });
        }
    }, [bos]);
    useEffect(() => {
        if (isSuccess && fileName) {
            setValue(fileName);
        }
    }, [fileName, isSuccess]);
    const keyName = (file) => {
        return file && file.uuid ? `${file.uuid}-0000-${file.name}` : file.name;
    };
    return (
        <div className={css.uploader}>
            {
                fileName ?
                    <div>
                        <div className={classnames(css.uploader_file, !isSuccess && css.error)}>
                            <span>📎{fileName}</span>
                            <span
                                onClick={() => setFileName(null)}
                            >
                                x
                            </span>
                        </div>
                        {
                            !isSuccess &&
                            <p className={css.uploader_error}>{error}</p>
                        }
                    </div> :
                    <div className={css.uploader_box}>
                        <span
                            onClick={() => {
                                inputFile.current?.click();
                            }}
                        >
                            上传压缩包
                        </span>
                        <p>支持上传不超过300M大小的压缩包示例数据，方便专家更准确地了解您的诉求。</p>
                    </div>
            }
            <input
                name="imageFile"
                ref={inputFile}
                type="file"
                id='imageFile'
            />
        </div>
    );
}