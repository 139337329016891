import {useContext} from 'preact/hooks';
import {TrackType} from '~/components/sections';
import {BmlFooter, EasyDLFooter} from './footer';
import Header from './header';
import Affix from '~/components/affix';
import css from './index.less';
import layoutData from '~/locals/layout';
import ConsoleModal, {useConsoleModal} from '~/components/consoleModal';

export default function Layout(props) {
    const {layout, page} = useContext(TrackType);
    const {header, footer, affix} = layoutData[layout];
    const [consoleModalVisible, consoleModalOnClose] = useConsoleModal();
    return (
        <div>
            <Header {...header} page={page} actionConfig={props.actionConfig} />
            <div class={css.container}>{props.children}</div>
            {(layout === 'bml' || layout === 'wenxin') ? <BmlFooter {...footer} /> : <EasyDLFooter {...footer} />}

            <Affix target={affix} />
            <ConsoleModal visible={consoleModalVisible} onClose={consoleModalOnClose} />
        </div>
    );
}

export function LayoutHeader(props) {
    const {layout, page} = useContext(TrackType);
    const {header, affix} = layoutData[layout];
    const [consoleModalVisible, consoleModalOnClose] = useConsoleModal();
    return (
        <div>
            <Header {...header} page={page} />
            <div className={css.container}>{props.children}</div>

            <Affix target={affix} />
            <ConsoleModal visible={consoleModalVisible} onClose={consoleModalOnClose} />
        </div>
    );
}
