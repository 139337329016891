export default {
    config: {
        trackType: 'EasyDL软硬一体官网',
        layout: 'easydl',
        page: 'solution'
    },
    banner: {
        name: 'fz',
        title: 'EasyDL前端智能计算-软硬一体方案',
        subTitle:
            '高性能硬件与图像分类/物体检测模型深度适配，多种方案可选。可应用于工业分拣、视频<br/>监控等多种设备端离线计算场景，让离线AI落地更轻松',
        bg: require('assets/banner/solution.jpg').default,
        buttonGroup: [
            {
                name: '合作咨询',
                href: process.env.APPLY,
                size: 'small',
                type: 'primary',
                trackTag: '合作咨询_官网上方'
            },
            {
                name: '技术文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Sk38n3nr5#软硬一体方案',
                size: 'small',
                plain: true,
                trackTag: '技术文档_官网上方'
            }
        ]
    },
    step: {
        id: 'step',
        title: '获取流程',
        data: [
            {name: '上传/标注数据', desc: '上传业务场景的图像分类/物体检测数据，并根据业务需求进行数据标注'},
            {name: '训练/校验模型', desc: '训练模型并评估模型效果，可多次迭代，直到模型效果满足业务需求'},
            {name: '获取软硬一体方案', desc: '根据业务场景需求，选择性价比高的方案，在AI市场同时购买软硬件'}
        ]
    },

    compare: {
        id: 'compare',
        title: '方案对比',
        data: {}
    },

    solution: [
        // fz5
        {
            image: require('assets/solution/fz5.png').default,
            icon: 'cube-2',
            title: 'EdgeBoard(FZ5)',
            popover: 'right',
            attrs: [
                ['芯片', {value: 'FZ5'}],
                ['算力', {value: '2.4 TOPS'}],
                ['尺寸', {value: '120mm*80mm'}]
            ],
            feature: [
                '工业品质：工业器件，被动式散热，防尘抗震耐高温，轻松适应严苛的部署环',
                '易开发：内置管理系统，支持SDK与摄像头可视化部署配置，支持二次开发',
                '高性价比：支持4-8路摄像头，Resnet实测可达50FPS，成本仅GPU服务器1/10'
            ],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/b7396167-e6b7-4abb-8dd0-22c514dfeb50'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=edgeBoard`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        params: ['20-34ms', '62-194ms', '低至20ms', '低至200ms']
                    }
                ]
            }
        },
        // edgeBoard-lite
        {
            image: require('assets/solution/fz3.png').default,
            icon: 'book',
            title: 'EdgeBoard(FZ3)',
            popover: 'right',
            attrs: [
                ['芯片', {value: 'FZ3'}],
                ['算力', {value: '1.2 TOPS'}],
                ['尺寸', {value: '80mm*70mm'}]
            ],
            feature: [
                '高性能：高性能SOC芯片，实测可达1.2TOPS算力',
                '低成本：千元级价位，同时获得软硬件方案',
                '开发简单：软硬件深度适配，1分钟轻松集成'
            ],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/fab6dc87-1db6-473f-a536-4dac24d3681d'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=edgeBoardLite`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        // type: 'ZU3',
                        params: ['51-84ms', '98-696ms', '低至50ms', '低至700ms']
                    }
                ]
            }
        },
        // vmx
        {
            image: require('assets/solution/vmx.png').default,
            icon: 'rocket',
            title: 'EdgeBoard(VMX)加速卡',
            popover: 'bottom',
            light: true,
            attrs: [
                ['芯片', {value: 'MyriadX'}],
                ['算力', {value: '4 TOPS'}],
                ['尺寸', {value: '38mm*38mm'}]
            ],
            feature: [
                '高性能：自带2个NCE神经网络加速处理器，功耗低至1.5W',
                '易集成：接口齐全，轻松嵌入各种主控硬件系统',
                '可定制：支持基于加速卡的定制化硬件设计'
            ],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/3e1f397f-77ec-4f7f-846b-25e39d694f4f'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=edgeBoardVmx`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        // type: 'recognition-speed',
                        params: ['65-135ms', '168-1620ms', '低至29ms', '低至169ms']
                    }
                ]
            }
        },
        // xavier
        {
            image: require('assets/solution/xavier.jpg').default,
            icon: 'book',
            title: 'Jetson AGX Xavier套件',
            popover: 'left',
            batch: true,
            attrs: [
                ['芯片', {value: 'NVIDIA Volta™ 架构GPU'}],
                ['算力', {value: '11+8 TOPS'}],
                ['尺寸', {value: '105mm*105mm'}]
            ],
            feature: ['具有六个计算机主板', '具备端到端自主能力', '兼顾计算密度、能效和 AI 推理能力'],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/723d6cf6-698b-4f5d-9e25-c269467e9831'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=xavier`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        // type: 'recognition-speed',
                        params: ['6-10ms', '11-99ms', '低至2ms', '低至3ms']
                    }
                ]
            }
        },
        // tx2
        {
            image: require('assets/solution/tx2.jpg').default,
            icon: 'book',
            title: 'Jetson TX2套件',
            popover: 'left',
            batch: true,
            attrs: [
                ['芯片', {value: 'NVIDIA Pascal™ 架构GPU'}],
                ['算力', {value: '1.33 FLOPS'}],
                ['尺寸', {value: '170mm*170mm'}]
            ],
            feature: ['卓越的速度和能效', '高达 8 GB 内存、59.7 GB/s 内存带宽', '丰富的标准硬件接口'],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/f1adf270-dba2-44a5-afc7-b66aa0a143c4'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=tx2`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        // type: 'recognition-speed',
                        params: ['17-27ms', '35-293ms', '低至5ms', '低至9ms']
                    }
                ]
            }
        },
         //
         {
            image: require('assets/solution/pro.png').default,
            icon: 'book',
            title: '辨影Pro',
            isOriginal: true,
            popover: 'bottom',
            batch: true,
            attrs: [
                ['尺寸', {value: '93mm*60mm*135mm'}],
                ['屏幕', {value: '4.3寸触摸屏'}],
                ['相机', {value: '1200万像素'}],
                ['镜头接口', {value: 'C/CS口'}]
            ],
            feature: [
                '简单：0代码完成部署，无需编程基础',
                '触控屏：普通工人就能操作',
                '多种接口：可对接各种工业自动化设备及二次开发',
                '一体化设计：完整解决方案，便于使用与管理'
            ],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/7bd349f9-652a-4862-8b15-4d77ee1e46d9'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=xavier`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        // type: 'recognition-speed',
                        params: ['6-10ms', '11-99ms', '低至2ms', '低至3ms']
                    }
                ]
            }
        },

        //
        {
            image: require('assets/solution/air.png').default,
            icon: 'book',
            title: '辨影SE/Air',
            isOriginal: true,
            popover: 'bottom',
            batch: true,
            attrs: [
                ['尺寸', {value: '93mm*60mm*135mm'}],
                ['屏幕', {value: '4.3寸触摸屏'}],
                ['相机', {value: '1200万像素'}],
                ['镜头接口', {value: 'C/CS口'}]
            ],
            feature: [
                '简单：0代码完成部署，无需编程基础',
                '触控屏：普通工人就能操作',
                '多种接口：可对接各种工业自动化设备及二次开发',
                '一体化设计：完整解决方案，便于使用与管理'
            ],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/7bd349f9-652a-4862-8b15-4d77ee1e46d9'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=xavier`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        // type: 'recognition-speed',
                        params: ['45-71ms', '92-804ms', '低至12ms', '低至24ms']
                    }
                ]
            }
        },
        // nano
        {
            image: require('assets/solution/nano.jpg').default,
            icon: 'book',
            title: 'Jetson Nano套件',
            popover: 'left',
            batch: true,
            attrs: [
                ['芯片', {value: 'NVIDIA Maxwell™ 架构GPU'}],
                ['算力', {value: '0.5 TOPS'}],
                ['尺寸', {value: '78mm*98mm'}]
            ],
            feature: ['低成本获得超高性能和能效', '可并行多个神经网络', '同时处理多路高清传感器的数据'],
            links: [
                {
                    name: '前往购买',
                    href: 'https://aim.baidu.com/product/c709fb08-a159-4fdc-a6b8-d4467c3ab15c'
                },
                {
                    name: '立即咨询',
                    href: `${process.env.APPLY}?product=nano`
                }
            ],
            paramsTable: {
                label: ['图像分类', '物体检测', '图像分类', '物体检测'],
                data: [
                    {
                        // type: 'recognition-speed',
                        params: ['45-71ms', '92-804ms', '低至12ms', '低至24ms']
                    }
                ]
            }
        }
    ],

    jetson: {
        title: 'EasyDL-EdgeBoard软硬一体方案',
        jetsonDesc:
            // eslint-disable-next-line max-len
            'EasyDL在AI模型端计算领域始终走在行业前列。EdgeBoard是百度面向嵌入式与边缘部署场景打造的AI视觉硬件方案，具有高性能、低成本、使用简单等三大优点。EasyDL和EdgeBoard联手推出软硬一体方案，可广泛适用于智能设备打造、视频监控、工业质检、交通巡检、医疗诊断、智能零售等领域。EasyDL-EdgeBoardFZ5系列新品优惠中，敬请关注。',
        jetsonLink: [
            {text: '解决方案', link: 'https://ai.baidu.com/tech/hardware/deepkit'},
            {text: '产品中心', link: 'https://ai.baidu.com/tech/hardware/edgeboard'},
            {text: '技术文档', link: 'https://ai.baidu.com/ai-doc/HWCE/Yk3b86gvp'}
        ],
        id: 'jetson',
        data: [
            {
                name: 'EasyDL-EdgeBoard（FZ5）软硬一体方案',
                desc:
                    // eslint-disable-next-line max-len
                    'EdgeBoard（FZ5）系列是百度基于FPGA（Zynq ZU5），面向边缘场景打造的微型AI服务器，内置可视化管理系统，极大的方便了模型部署与二次开发，可以快速实现边缘化AI项目部署。',
                param: '芯片：Zynq ZU5   ｜   算力：2.4 TOPS   ｜   尺寸：120mm x 100mm x 50mm',
                image: require('assets/solution/fz5.png').default,
                url: 'https://aim.baidu.com/product/b7396167-e6b7-4abb-8dd0-22c514dfeb50'
            }
        ]
    },

    start: {
        title: '更多软硬一体方案',
        desc: '提交业务场景需要的软硬一体方案需求，获得EasyDL软硬件团队的优先适配支持',
        buttonGroup: [
            {
                text: '立即咨询',
                href: `${process.env.APPLY}`,
                type: 'primary',
                trackTag: '立即咨询_官网下方'
            }
        ]
    },

    navData: [
        {name: '获取流程', hash: 'step'},
        {name: '方案对比', hash: 'compare'},
        {name: '限时活动', hash: 'jetson'}
    ]
};
