import {useState, useMemo, useEffect} from 'preact/hooks';
import css from './column.less';
import classNames from 'classnames';
import {
    scrollToAim,
    getScrollLength,
    getBodyScrollTop,
    throttle,
} from 'utils';

export function Column(props) {
    const {sectionColumn} = props;
    const [curTab, setTab] = useState(sectionColumn[0].id);
    const [fixd, setFixd] = useState(false);
    const handleCheckTab = (id) => {
        setTab(id);
        scrollToAim(`paddle-${id}`, 180);
    };
    useEffect(() => {
        const handleScroll = throttle(() => {
            const columsHeight = sectionColumn.map(({id}) => getScrollLength(`paddle-${id}`));
            const ScrollHeight = getBodyScrollTop();
            const columnOffsetHeight = getScrollLength('paddle-intro') - 180;
            setFixd(ScrollHeight > columnOffsetHeight);
            if (ScrollHeight > columnOffsetHeight) {
                const curIdx = columsHeight.indexOf(columsHeight.find((len) => ScrollHeight < len));
                if (curIdx >= 0 && sectionColumn[curIdx].id !== curTab) {
                    setTab(sectionColumn[curIdx].id);
                }
                return;
            }
            setTab(sectionColumn[0].id);
        }, 100);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div
            className={classNames(css.paddle_column, {
                [css.paddle_fixd]: fixd
            })}
            id="paddle-column"
        >
            <div className={css['column-content']}>
                {sectionColumn.map(({name, id}) => (
                    // eslint-disable-next-line
                    <div className={css['column-item']} key={id} onClick={() => handleCheckTab(id)}>
                        <div className={css['column-item_name']}>
                            <p>{name}</p>
                            {curTab === id && <p className={css['column-item_active']} />}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export function PaddleTimeline(props) {
    const {Steps} = props;
    const [contentWidth, setContentWidth] = useState(1200);
    const [region, setRegion] = useState(Steps.length);
    const [historyEdition, setHistoryEdition] = useState(null);

    const Card = (props) => {
        const {date, edition, desc, historyEdition, setHistoryEdition} = props;
        return (
            <div
                className={
                    classNames(css['timeline-content-card'],
                    historyEdition === edition ? css['timeline-content-activate'] : '')}
                onClick={() => setHistoryEdition(edition)}
            >
                <span
                    className={css['content-card-circle']}
                    onMouseEnter={() => setHistoryEdition(edition)}
                ></span>
                <span>{date}</span>
                <p className={css['content-card-edition']}>{edition}版本</p>
                <p className={css['content-card-desc']}>
                    {
                        typeof desc === 'string' ? desc :
                            desc.map((item) => <span key={item}>{item}</span>)
                    }
                </p>
            </div>
        );
    };
    useEffect(() => {
        if (Steps.length) {
            const width = Steps.length * 270;
            const history = Steps[Steps.length - 1].edition;
            setContentWidth(width + 10);
            setHistoryEdition(history);
        }
    }, [Steps]);
    const transform = useMemo(() => {
        if (region > 4) {
            return (region - 4) * 270;
        }
        return 0;
    }, [region]);
    const prePage = () => {
        let current = region;
        if (--current < 4) {
            return false;
        }
        setRegion(current);
    };
    const nextPage = () => {
        let current = region;
        if (++current > Steps.length) {
            return false;
        }
        setRegion(current);
    };
    return (
        <div className={css.paddle_timeline}>
            <div className={css.paddle_timeline_box}>
                <div className={css.paddle_timeline_box_content}>
                    <div
                        className={css['timeline-content']}
                        style={{width: `${contentWidth}px`, transform: `translateX(-${transform}px)`}}
                    >
                        {
                            Steps.map((item) => (
                                <Card
                                    {...item}
                                    key={item.edition}
                                    historyEdition={historyEdition}
                                    setHistoryEdition={setHistoryEdition}
                                />
                            ))
                        }
                    </div>
                    <div className={css['timeline-console']}>
                        {
                            region <= 4 ? <></> :
                            <span className={css['timeline-console-left']} onClick={() => prePage()}></span>
                        }
                        {
                            region >= Steps.length ? <></> :
                            <span className={css['timeline-console-right']} onClick={() => nextPage()}></span>
                        }
                    </div>
                </div>
            </div>
            {/* 和pm沟通暂时隐藏 */}
            {/* <div className={css.paddle_timeline_tolink}>
                <a href="http://" target="_blank" rel="noopener noreferrer">
                    查看全部更新日志&gt;
                </a>
            </div> */}
        </div>
    );
}

export function CardListBox(props) {
    const {guides} = props;
    return (
        <div className={css.paddle_card_list_box}>
            {
                guides.map(({title, desc, href}) => (
                    <div className={css.paddle_box} key={title}>
                        <p className={css.paddle_box_title}>{title}</p>
                        <p className={css.paddle_box_desc}>{desc}</p>
                        <a href={href} target="_blank" rel="noopener noreferrer">
                            查看文档&nbsp; &gt;
                        </a>
                    </div>
                ))
            }
        </div>
    );

}