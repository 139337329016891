export default {
    config: {
        trackType: 'EasyDL语音官网',
        layout: 'easydl',
        page: 'audio'
    },
    banner: {
        name: 'audio',
        title: 'EasyDL 语音',
        subTitle:
            '零代码自助训练语音识别语言模型，声音分类模型。提升业务领域专有名词识别准确率，区分不同声音类别，广泛适用于行业数据采集录入、语音指令、呼叫中心、声音类型检测等应用场景',
        bg: require('assets/subpage/audio-banner.jpg').default,
        buttonGroup: [
            {
                name: '在线使用',
                className: 'js-consoleModalBtn',
                size: 'small',
                type: 'primary',
                trackTag: '在线使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL在线使用',
                    'data-track-name': 'EasyDL-产品页面-在线使用',
                    'data-track-value': ''
                }
            },
            {
                name: '服务管理',
                href: 'https://console.bce.baidu.com/ai/?fromai=1#/ai/easydlLiteVoice/overview/index~type=1',
                size: 'small',
                plain: true,
                trackTag: '服务管理_官网上方'
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/ekhyics9y',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            }
        ]
    },
    modelTypes: {
        title: '源于实践的模型类型',
        data: [
            {
                title: '语音识别',
                desc: '使用业务场景文本语料，零代码自助训练语音识别模型，提升业务领域识别准确率',
                keywords: ['语音对话', '语音指令', '语音录入', '客服电话'],
                img: require('assets/subpage/audio-sound-indentify.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.SOUNDIDENTIFY
                }
            },
            {
                title: '声音分类',
                desc: '定制识别当前音频是什么类型的声音。应用于生产或泛安防场景中监控异常声音等',
                keywords: ['安防监控', '科学研究'],
                img: require('assets/subpage/audio-sound-cls.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.SOUNDCLS
                }
            }
        ]
    },
    feature: {
        title: '全面领先的特色功能效果',
        data: [
            {
                title: '零门槛操作',
                subtitle: '最快<b>10</b>min训练优化',
                href: '',
                desc: ['一站式自动化训练', '上传文件极简交互']
            },
            {
                title: '高精准评估',
                subtitle: '可视化训练报告',
                href: '',
                desc: ['系统自动评估推荐基础模型', '训练前后均提供评估报告']
            },
            {
                title: '强训练效果',
                subtitle: '<b>5%-25%</b>识别率提升',
                href: '',
                desc: ['预置百度大规模预训练模型', '支持长短文本多种训练方式', '支持多次上传迭代训练']
            },
            {
                title: '超灵活部署',
                subtitle: '多种云端调用方式',
                href: '',
                desc: ['模型上线后专属使用', '支持在线API，SDK多种方式']
            }
        ]
    },
    step: {
        title: '简单四步体验全流程，轻松获得AI模型',
        desc:
            // eslint-disable-next-line
            '一站式模型定制，全流程可视化操作，最快15分钟获得高精度AI模型，',
        data: [
            {name: '创建模型', desc: '根据业务需求，创建并选择想要训练的接口类型'},
            {name: '系统评估', desc: '上传音频测试集，系统自动评估基础模型识别率'},
            {name: '训练模型', desc: '上传业务文本，一键启动模型训练，可迭代训练'},
            {name: '上线模型', desc: '自动上线模型，语音识别接口配置参数即可调用'}
        ]
    },
    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '语音对话',
                desc:
                    'APP语音助手，金融、医疗、航空公司智能机器人对话等短语音交互场景，使用领域中的专业术语进行训练，提高对话精准度',
                banner: require('assets/subpage/audio-dialogue.png').default
            },
            {
                name: '语音指令',
                desc: '智能硬件语音控制、 app内语音搜索关键词、语音红包等场景，训练固定搭配的指令内容，让控制更精确',
                banner: require('assets/subpage/audio-sound-instruction.png').default
            },
            {
                name: '语音录入',
                desc:
                    '农业采集、工业质检、物流快递单录入、餐厅下单、电商货品清点等业务信息语音录入场景，训练业务中的常用词，录入的结果更加有效',
                banner: require('assets/subpage/audio-recording.png').default,
                imgs: [
                    {
                        name: '华智生物',
                        src: require('assets/subpage/audio-huarui.png').default,
                        href: `${process.env.CUSTOMER}/huazhishuidao`
                    }
                ]
            },
            {
                name: '电话客服',
                desc: '运营商、金融、地产销售等电话客服业务场景，使用领域中的专业术语进行训练，提高对话精准度',
                banner: require('assets/subpage/audio-customer-service.png').default
            },
            {
                name: '安防监控',
                desc: '定制识别不同的异常或正常的声音，进而用于突发状况预警',
                banner: require('assets/subpage/audio-monitor.png').default
            },
            {
                name: '科学研究',
                desc: '定制识别同一物种的不同个体的声音、或者不同物种的声音，协助野外作业研究',
                banner: require('assets/subpage/audio-research.png').default
            }
        ]
    },
    recommend: {
        title: '相关推荐',
        data: [
            {
                name: '短语音识别极速版',
                href: 'https://ai.baidu.com/tech/speech/asrpro',
                desc: '将60秒内的语音快速识别为文字，适用于手机语音输入、语音搜索、人机对话等场景 '
            },
            {
                name: '实时语音识别',
                href: 'https://ai.baidu.com/tech/speech/realtime_asr',
                desc: '将音频流实时识别为文字，适用于长句语音输入、音视频字幕、会议等场景'
            },
            {
                name: '呼叫中心语音解决方案',
                href: 'https://ai.baidu.com/solution/bsic',
                desc: '呼叫中心场景专属的8K采样率语音识别、语音合成、助力企业提升客服服务效率'
            }
        ]
    }
};
