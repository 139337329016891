import paddleFrame from './paddle_frame.md';
import paddleClas from './paddleclas.md';
import fastdeploy from './fastdeploy.md';
import paddleDetection from './paddledetection.md';
import paddleHub from './paddlehub.md';
import paddleNLP from './paddleNLP.md';
import paddleOCR from './PaddleOCR.md';
import paddleSeg from './paddleseg.md';
import paddleslim from './paddleslim.md';
import paddleSpeech from './paddlespeech.md';
import paddleScience from './PaddleScience.md';

export default {
    paddleFrame,
    paddleClas,
    fastdeploy,
    paddleDetection,
    paddleHub,
    paddleNLP,
    paddleOCR,
    paddleSeg,
    paddleslim,
    paddleSpeech,
    paddleScience
};