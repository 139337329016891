import {innerHtml} from 'utils';
import {
    flowPathdata,
    lookBack,
    campsite,
    giftBagCon,
    giftBagOne,
    giftBagApply,
    giftBagTwo,
    giftBagThree,
    giftBagFour,
    AwardsTwoData,
    AwardsThreeData,
    AwardsFourData,
    groundPage
} from 'locals/easydl/giftBag';
import classnames from 'classnames';
import css from './index.less';
export default function GiftBag() {
    return (
        <div className={css['gift-bag']}>
            <img src={require('assets/giftBag/banner.jpg').default} alt='banner' />
            <div className={css['gift-bag-content']}>
                <Recommend data={campsite}>
                    <Describe />
                </Recommend>
                <Recommend data={giftBagCon}>
                    <Awards data={giftBagOne}>
                        <AwardsOne />
                    </Awards>
                    <Awards data={giftBagTwo}>
                        <AwardsTwo />
                    </Awards>
                    <Awards data={giftBagThree}>
                        <AwardsThree />
                    </Awards>
                    <Awards data={giftBagFour}>
                        <AwardsFour />
                    </Awards>
                    <QaCode />
                </Recommend>
            </div>
            <p className={css.footer}>本次活动最终解释权归百度所有</p>
            <div className={css['decorate-ball']}></div>
            <div className={css['decorate-giftBag']}></div>
            <div className={css['decorate-ball-b']}></div>
        </div>
    );
}

export const Temporary  = () => {
    return (
        <div className={css.temporary}>
            {
                groundPage.map((items, index) => (
                    <img
                        src={require(`assets/giftBag/ground-page-${index + 1}.jpg`).default}
                        alt={items}
                        key={items}
                    />
                ))
            }
        </div>
    );
};

const Recommend = (props) => {
    const {children, data} = props;
    const {trumpet, bg, titleBg, title, differentiate} = data;
    const itemClass = classnames(css.recommend, differentiate ? css['recommend-diff'] : '');
    return (
        <div className={itemClass} style={{backgroundImage: `url(${bg})`}}>
            <div className={css['recommend-title']} style={{backgroundImage: `url(${titleBg})`}}>
                {innerHtml(<span innerHtml={title} />)}
            </div>
            {children}
            <div className={css['recommend-trumpet']}>
                <img src={trumpet} alt='recommend' />
            </div>
        </div>
    );
};

const Describe = () => {
    return (
        <>
            <p className={css['recommend-desc']}>
                【飞桨AI实战营】是百度飞桨平台面向企业及开发者推出的AI开发全流程实战活动。
                由百度专业的AI算法工程师实操带领，基于真实场景下的应用主题，不管你是入门级选手、专业技术人员，还是有AI开发需求的企业方，都可以体验
                <span>“数据处理-模型训练-模型部署-模型调用”</span>
                的全流程操作，实现AI模型开发即学即用。
            </p>
            <FlowPath />
            <p className={css['recommend-desc']}>
                AI实战营结合真实产业场景，覆盖CV、NLP、推荐、部署等多个技术方向，
                每周一期，提供在线实训场景和免费算力，全程跟踪指导，帮助大家快速体验AI应用、实战落地AI项目。
            </p>
            <LookBack />
        </>
    );
};
const FlowPath = () => {
    const {data} = flowPathdata;
    return (
        <div className={css.flowPath}>
            {data.map(({img, text}) => (
                <div className={css['flowPath-item']} key={text}>
                    <img src={img} alt={text} />
                    <span>{text}</span>
                </div>
            ))}
        </div>
    );
};

const LookBack = () => {
    const {title, data} = lookBack;
    return (
        <div className={css.lookBack}>
            <span>{title}</span>
            <div>
                {data.map(({img, text}) => (
                    <div className={css['lookBack-item']} key={text}>
                        <div className={css['lookBack-item-bg']}>
                            {img ? <img src={img} alt={text} /> : <span>{text}</span>}
                        </div>
                        {text && img ? <span>{text}</span> : ''}
                    </div>
                ))}
            </div>
        </div>
    );
};

const Awards = (props) => {
    const {children, data} = props;
    const {direction, gradientA, gradientB, desc, title, img, text} = data;
    const itemClass = classnames(css.awards, direction ? css['awards-direction'] : '');
    return (
        <div className={itemClass}>
            <p>{text}</p>
            <div className={css['awards-title']}>
                <span style={{...gradientA}}>{desc}</span>
                <p style={{...gradientB}}>{title}</p>
                <img src={img} alt='awards' />
            </div>
            {children}
        </div>
    );
};

const AwardsOne = () => {
    return (
        <div className={css.awardsOne}>
            <span>本期实战营正在报名中，直接扫码报名</span>
            <div className={css['awardsOne-title']}>
                {giftBagApply.map(({img, desc}) => (
                    <div key={desc}>
                        <img src={img} alt={desc} />
                        <p>{desc}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const AwardsTwo = () => {
    return (
        <div className={css.awardsTwo}>
            <AwardsCard data={AwardsTwoData} />
            <p className={css['awards-text']}>{AwardsTwoData.text}</p>
        </div>
    );
};

const AwardsThree = () => {
    return (
        <div className={css.awardsThree}>
            <div>
                <AwardsCard data={AwardsThreeData} />
                <AwardsCardImg data={AwardsThreeData.imgs} />
            </div>
            <p className={css['awards-text']}>{AwardsThreeData.text}</p>
        </div>
    );
};

const AwardsFour = () => {
    return (
        <div className={css.awardsFour}>
            <div>
                <AwardsCard data={AwardsFourData} />
                <AwardsCardImg data={AwardsFourData.imgs} />
            </div>
            <p className={css['awards-text']}>{AwardsFourData.text}</p>
        </div>
    );
};
const AwardsCardImg = ({data}) => {
    return (
        <>
            {data.map(({img, desc}) => (
                <div className={css.awardsCardImg} key={desc}>
                    <img src={img} alt={desc} />
                    <p className={css['awards-desc']}>{desc}</p>
                </div>
            ))}
        </>
    );
};

const AwardsCard = ({data}) => {
    const {price, priceDesc, desc} = data;
    return (
        <div>
            <div className={css['awards-card']}>
                <p>
                    <span>¥</span>
                    {price}
                </p>
                <div>
                    <p>{priceDesc[0]}</p>
                    <p>{priceDesc[1]}</p>
                </div>
            </div>
            <p className={css['awards-desc']}>{desc}</p>
        </div>
    );
};

const QaCode = () => {
    return (
        <div className={css.qaCode}>
            <img src={require('assets/giftBag/qaCode.png').default} alt='qaCode' />
            <div>
                <p>扫码立即报名</p>
                <p className={css['qaCode-desc']}>快速解锁4大新人礼包</p>
            </div>
        </div>
    );
};
