import {useState, useEffect} from 'preact/hooks';
import {scrollToAim, getBodyScrollTop, getScrollLength, throttle} from 'utils';
import css from './index.less';
import classnames from 'classnames';
const TopPageNavItemWidth = 150;

// 距离视口顶部 130px 设置为激活 nav
const NavActivityHeight = 130;

function Tab(props) {
    const {navData} = props;
    const [curTab, setCurTab] = useState(0);
    const [navFixed, setNavFixed] = useState(false);

    const checkedTab = (e, index, hash) => {
        e.preventDefault();
        // 如果当时已经是选中状态，跳出
        if (e.target.classList.contains(css.active)) {
            return;
        }
        // 执行跳转
        scrollToAim(hash);
    };

    useEffect(() => {
        const topArray = navData.map((item) => {
            return document.querySelector(`#${item.hash}`);
        });

        const scrollHandle = throttle(() => {
            // 固定 tab
            const boundary = getScrollLength(navData[0].hash);
            const winScrollTop = getBodyScrollTop();
            setNavFixed(winScrollTop >= boundary);

            for (let i = 0; i < topArray.length; i += 1) {
                const currentEle = topArray[i];
                const nextEle = topArray[i + 1];
                const currentEleTop = getScrollLength(currentEle) - NavActivityHeight;
                const nextEleTop = nextEle ? getScrollLength(nextEle) - NavActivityHeight : winScrollTop;
                if (currentEleTop <= winScrollTop && winScrollTop <= nextEleTop) {
                    setCurTab(i);
                    break;
                }
            }
        }, 100);

        scrollHandle();

        document.addEventListener('scroll', scrollHandle);
        return () => {
            document.removeEventListener('scroll', scrollHandle);
        };
    }, [navData]);

    // render
    const tabItem = navData.map((it, index) => (
        <div
            class={classnames(css['top-page-nav-item'], {[css.active]: curTab === index})}
            onClick={(e) => checkedTab(e, index, it.hash)}
        >
            {it.name}
        </div>
    ));

    return (
        <nav id="nav_tab" class={classnames(css['top-page-nav'], {[css['top-page-nav-fixed']]: navFixed})}>
            <div class={css['top-page-nav-container']}>
                <div
                    class={css['top-page-nav-bar']}
                    style={{transform: `translate(${curTab * TopPageNavItemWidth}px)`}}
                />
                <div class={css['top-page-nav-content']}>{tabItem}</div>
            </div>
        </nav>
    );
}

export default Tab;
