import {useState, useEffect, useRef} from 'preact/hooks';
import AsyncValidator from 'async-validator';
import Layout from 'components/layouts';
import {Field, Input, Submit, Radio, Select, Text} from 'components/base/field';

import {send, scrollToTop, getQueryParam} from 'utils';
// 页面数据
import {errorMsgArr} from 'locals/easydl/cooperation';
import {industry, ExpectedPrice, Delays, Quantitys} from 'locals/easydl/common';
import {TrackType} from 'components/sections';
const rules = () => ({
    boardMode: [{required: true, message: '请选择硬件'}],
    boardDesc: [{required: true, message: '请输入需求描述'}],
    name: [
        {required: true, message: '请输入联系人姓名'},
        {max: 20, message: '联系人姓名不能超过20个字'}
    ],
    phone: [
        {required: true, message: '请输入联系人电话'},
        {pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号'}
    ],
    company: [
        {required: true, message: '请输入公司名称'},
        {max: 20, message: '公司名称不能超过20个字'}
    ],
    industry: [{required: true, message: '请选择您的行业'}],
    scene: [
        {required: true, message: '请输入模型应用场景'},
        {max: 300, message: '网址描述不能超过300个字'}
    ],

    timeLimit: [{required: false, message: '请选择离线计算时延'}],

    deviceCount: [{required: false, message: '请选择所需硬件数量'}],
    priceExpect: [{required: false, message: '请选择价格预期'}],

    otherDemand: [{required: false}]
});

const PRODUCTLIST = (key) => {
    const local = {
        edgeBoard: 'EdgeBoard(FZ)计算盒软硬一体方案',
        edgeBoardLite: 'EdgeBoard(FZ Lite)软硬一体方案',
        edgeBoardVmx: 'EdgeBoard(VMX)加速卡软硬一体方案',
        haisi: '海思Hi3559A开发板',
        xavier: 'Jetson AGX Xavier套件软硬一体方案',
        tx2: 'Jetson TX2套件软硬一体方案',
        nano: 'Jetson Nano套件软硬一体方案'
    };
    return local[key] || null;
};

const radios = [
    {key: '1', title: '是'},
    {key: '2', title: '否'}
];

import 'style/easydl/cooperation.less';

export default function Cooperation() {
    const [formData, setFormData] = useState({
        boardMode: '1',
        boardDesc: null,
        name: null,
        phone: null,
        company: null,
        industry: industry[0].value,
        scene: null,
        timeLimit: Delays[0].value,
        deviceCount: Quantitys[0].value,
        priceExpect: ExpectedPrice[0].value,
        otherDemand: null
    });
    const [formTips, setFormTips] = useState({
        boardMode: null,
        boardDesc: null,
        name: null,
        phone: null,
        company: null,
        industry: null,
        scene: null,
        timeLimit: null,
        deviceCount: null,
        priceExpect: null,
        otherDemand: null
    });
    const modelContentRef = useRef(null);
    const [modelData, SetModelData] = useState({});
    const [submitRes, setSubmitRes] = useState(true);
    const [initSoc, setSoc] = useState(false);

    const handleError = (err) => {
        const errObj = err.reduce(
            (pre, {message, field}) => ({
                ...pre,
                [field]: message
            }),
            {}
        );
        setFormTips({...formTips, ...errObj});
    };
    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value
        });
    };

    const handleBlur = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        const validator = new AsyncValidator({
            [field]: rules()[field]
        });
        setFormTips({
            ...formTips,
            [field]: null
        });
        validator.validate({[field]: value}).catch(({errors}) => {
            errors && handleError(errors);
        });
    };

    const errorCb = () => {
        setSubmitRes(false);
        scrollToTop();
        SetModelData({
            state: 'fail',
            title: '提交失败，请检查网络连接',
            desc: '您录入的信息已自动保存，可返回页面重新提交',
            btn: '返回'
        });
    };

    const successCb = (res) => {
        if (res.success) {
            setSubmitRes(false);
            scrollToTop();
            SetModelData({
                state: 'success',
                title: '提交成功',
                desc: '将会有工作人员和您联系, 沟通进一步事宜',
                btn: '3S 跳转至首页'
            });
            countDown();
            return;
        }

        const errMsg = errorMsgArr.find((it) => it.field === Object.entries(res.message.field)[0]);
        if (errMsg) {
            handleError([errMsg]);
        } else {
            errorCb();
        }
    };

    const formSubmit = () => {
        const validator = new AsyncValidator(rules());
        const baseData = {
            boardMode: formData.boardMode,
            boardDesc: formData.boardDesc,
            name: formData.name,
            phone: formData.phone,
            company: formData.company,
            industry: formData.industry,
            scene: formData.scene,
            otherDemand: formData.otherDemand
        };
        let postData;
        if (initSoc) {
            postData = {
                ...baseData,
                boardMode: '3',
                deviceCount: formData.deviceCount
            };
        } else {
            postData =
                formData.boardMode === '1'
                    ? baseData
                    : {
                          ...baseData,
                          timeLimit: formData.timeLimit,
                          priceExpect: formData.priceExpect,
                          deviceCount: formData.deviceCount
                      };
        }

        validator
            .validate(postData)
            .then(() => {
                send({
                    method: 'record/edgeboard/create',
                    payload: postData,
                    baseURL: process.env.API_URL
                })
                    .then((res) => successCb(res))
                    .catch((err) => errorCb(err));
            })
            .catch(({errors}) => handleError(errors));
    };

    function countDown(time = 3) {
        let timeout = setTimeout(() => {
            time--;
            modelContentRef.current.textContent = `${time > 0 ? time : 1}S 跳转至首页`;
            if (time > 0) {
                countDown(time);
            }
            if (time === 0) {
                window.location.href = '/easydl/solution';
            }
            clearTimeout(timeout);
        }, 1000);
    }

    useEffect(() => {
        const queryParam = getQueryParam();
        if (queryParam.product) {
            setSoc(true);
            setFormData({
                ...formData,
                boardDesc: PRODUCTLIST(queryParam.product)
            });
        } else {
            setFormData({
                ...formData,
                boardMode: '2'
            });
        }
    }, []);

    return (
        <TrackType.Provider value={{trackType: 'EasyDL官网', layout: 'easydl', page: 'cooperation'}}>
            <Layout>
                {submitRes ? (
                    <div className="cooperation-content">
                        <div className="form-body">
                            <div className="form-title">填写信息，咨询与合作</div>
                            <div className="form-content">
                                {initSoc ? (
                                    <Field label="方案名称">
                                        <Text text={formData.boardDesc} />
                                    </Field>
                                ) : (
                                    <>
                                        <Field label="是否已有硬件" tip={formTips.boardMode} radio>
                                            <Radio
                                                name="boardMode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={formData.boardMode}
                                                radios={radios}
                                            />
                                        </Field>
                                        <Field
                                            label={formData.boardMode === '1' ? '已有硬件型号' : '方案需求描述'}
                                            tip={formTips.boardDesc}
                                        >
                                            <Input
                                                name="boardDesc"
                                                placeholder={
                                                    formData.boardMode === '1'
                                                        ? '请描述您的已有硬件型号（限50字）'
                                                        : '可填写意向硬件或描述对硬件的要求，包括芯片、算力、尺寸、价格等（限500字）'
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={formData.boardDesc}
                                                tag={false}
                                            />
                                        </Field>
                                    </>
                                )}
                                <Field label="联系人姓名" tip={formTips.name}>
                                    <Input
                                        name="name"
                                        placeholder="请输入联系人姓名"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.name}
                                    />
                                </Field>
                                <Field label="联系人手机号" tip={formTips.phone}>
                                    <Input
                                        name="phone"
                                        placeholder="请输入联系人姓名"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.phone}
                                    />
                                </Field>
                                <Field label="公司名称" tip={formTips.company}>
                                    <Input
                                        name="company"
                                        placeholder="请输入公司名称"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.company}
                                    />
                                </Field>
                                <Field label="应用行业" tip={formTips.industry}>
                                    <Select
                                        name="industry"
                                        onChange={handleChange}
                                        value={formData.industry}
                                        selectData={industry}
                                    />
                                </Field>
                                <Field label="模型应用场景" tip={formTips.scene}>
                                    <Input
                                        name="scene"
                                        placeholder="请描述模型应用场景"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.scene}
                                        tag={false}
                                    />
                                </Field>
                                {initSoc ? (
                                    <Field label="所需硬件数量">
                                        <Select
                                            name="deviceCount"
                                            onChange={handleChange}
                                            value={formData.deviceCount}
                                            selectData={Quantitys}
                                        />
                                    </Field>
                                ) : (
                                    formData.boardMode === '2' && (
                                        <>
                                            <Field label="离线计算时延">
                                                <Select
                                                    name="timeLimit"
                                                    onChange={handleChange}
                                                    value={formData.timeLimit}
                                                    selectData={Delays}
                                                />
                                            </Field>
                                            <Field label="所需硬件数量">
                                                <Select
                                                    name="deviceCount"
                                                    onChange={handleChange}
                                                    value={formData.deviceCount}
                                                    selectData={Quantitys}
                                                />
                                            </Field>
                                            <Field label="方案价格预期">
                                                <Select
                                                    name="priceExpect"
                                                    onChange={handleChange}
                                                    value={formData.priceExpect}
                                                    selectData={ExpectedPrice}
                                                />
                                            </Field>
                                        </>
                                    )
                                )}
                                <Field label="其他需求" required={false}>
                                    <Input
                                        name="otherDemand"
                                        placeholder="请描述您对AI的具体需求"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.otherDemand}
                                        tag={false}
                                    />
                                </Field>
                                <Field required={false}>
                                    <Submit onClick={formSubmit} />
                                </Field>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="model-content">
                        <div className="model-consult">
                            <div className={`model-icon-${modelData.state}`} />
                            <div className="model-title">{modelData.title}</div>
                            <div className="model-desc">{modelData.desc}</div>
                            {modelData.state === 'success' ? (
                                <div className="model-back-success" ref={modelContentRef}>
                                    {modelData.btn}
                                </div>
                            ) : (
                                <div className="model-back-fail" onClick={() => setSubmitRes(true)}>
                                    {modelData.btn}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Layout>
        </TrackType.Provider>
    );
}
