import css from './index.less';
import classNames from 'classnames';
import {Relyon} from '../relyon';
import {Frame} from '../frame';
import {Exploit} from '../exploit';
import {Practice} from '../practice';
import {useState, useEffect, useCallback} from 'preact/hooks';
import {send} from 'utils';

export function Content(props) {
    const {paddleContent, content, paddleTypeHash} = props;
    const {dataVersions, relyonData} = paddleContent;
    const [isbottom, setIsBottom] = useState(false);
    const sidebarHtml = useCallback(() => {
        if (content) {
            const isbottom = false;
            switch (content.type) {
                case 'practice':
                    setIsBottom(isbottom);
                    return <Practice category={paddleTypeHash || window.location.hash.split('#')[1]} />;
                case 'exploit':
                    const exploit = content.items.filter(({type}) => type === paddleTypeHash)[0];
                    setIsBottom(true);
                    return <Exploit typeHash={paddleTypeHash} exploitData={exploit} label={content.label} />;
                case 'relyon':
                    setIsBottom(isbottom);
                    return <Relyon relyonData={relyonData} />;
                case 'frame':
                    setIsBottom(isbottom);
                    return <Frame dataVersions={dataVersions} />;
                default:
                    setIsBottom(isbottom);
                    return <h3>载入中...</h3>;
            }
        }
    }, [paddleTypeHash, content]);
    return (
        <div className={css.paddle_content}>
            <div className={css.paddle_content_title}>
                <span>
                    {
                        content?.name
                    }
                </span>
            </div>
            <div className={css.paddle_content_box} style={{paddingBottom: `${isbottom ? '80px' : ''}`}}>
                <div className={css.paddle_content_box_content}>
                    {
                        sidebarHtml()
                    }
                </div>
            </div>
        </div>
    );
}