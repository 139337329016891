export default {
    config: {
        trackType: 'EasyDL跨模态官网',
        layout: 'easydl',
        page: 'vqa'
    },
    banner: {
        name: 'vqa',
        title: 'EasyDL 跨模态',
        subTitle: '基于“文心·跨模态大模型”，提供领先的视觉、文本跨模态理解能力，根据业务需求轻松定制图文匹配模型',
        bg: require('assets/subpage/vqa-banner.png').default,
        buttonGroup: [
            {
                name: '立即使用',
                href: process.env.VQA,
                size: 'small',
                type: 'primary',
                trackTag: '立即使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL立即使用',
                    'data-track-name': 'EasyDL-产品页面-立即使用',
                    'data-track-value': ''
                }
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/rl2sxlrq5', //
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            },
            {
                name: '产品定价',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/0l3b0e5sv',
                size: 'small',
                plain: true,
                trackTag: '产品定价_官网上方'
            }
        ]
    },
    modelTypes: {
        title: '源于实践的丰富模型类型',
        data: [
            {
                title: '图文匹配',
                desc: '定制化开发“图文匹配”模型，对文本及图片信息进行深度理解，计算两者的匹配度',
                keywords: ['内容质量评价', '图文素材推荐'],
                img: require('assets/subpage/vqa-matching.jpg').default,
                link: {
                    name: '在线使用',
                    href: 'https://console.bce.baidu.com/easydl/2001/20010/models'
                }
            },
            {
                title: '敬请期待',
                desc: '更多跨模态模型任务，敬请期待',
                keywords: [],
                img: require('assets/subpage/default.jpg').default,
                link: {
                    name: '',
                    href: ''
                }
            }
        ]
    },

    feature: {
        title: '全面领先的特色功能效果',
        data: [
            {
                title: '零门槛操作',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/rl2sxlrq5',
                desc: ['一站式自动化训练', '拖拽式极简操作', '端到端模型训练']
            },
            {
                title: '高精度效果',
                href: 'https://wenxin.baidu.com/wenxin/multi',
                desc: [
                    '使用百度“文心·跨模态大模型”作为训练基座',
                    '少量数据即可获得理想的模型效果',
                    '持续语义学习理解框架助力模型效果'
                ]
            },
            {
                title: '低成本数据',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Il2sxtncf',
                desc: ['便捷的数据标注工具', '一键上传已标注图文数据', '提供可视化的数据管理能力']
            },
            {
                title: '超灵活部署',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/Nl2sxzqdx',
                desc: [
                    '支持一键部署“百度智能云-在线服务”',
                    '完善的鉴权、流控安全机制',
                    '合理的计价方案，低成本部署'
                ]
            }
        ]
    },

    step: {
        title: '简单四步体验全流程，轻松获得AI模型',
        //   eslint-disable-next-line
        desc: '一站式模型定制，全流程可视化操作，最快15分钟获得高精度AI模型，',
        data: [
            {name: '创建模型', desc: '根据业务需求，创建并选择想要训练的接口类型'},
            {name: '准备数据', desc: '上传音频测试集，系统自动评估基础模型识别率'},
            {name: '训练模型', desc: '上传业务文本，一键启动模型训练，可迭代训练'},
            {name: '部署模型', desc: '自动上线模型，语音识别接口配置参数即可调用'}
        ]
    },

    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '内容质量评价',
                desc:
                    '计算互联网内容中图片与文案的匹配程度，进而量化评价内容质量，可应用于电商、广告营销、互联网社区等领域',
                banner: require('assets/subpage/vqa-evaluate.png').default
            },
            {
                name: '图文素材推荐',
                desc:
                    '基于对已有图文素材的匹配度分析，在用户进行内容创作时，推荐相匹配的图片或文案素材',
                banner: require('assets/subpage/vqa-material.png').default
            }
        ]
    },

    recommend: {
        title: '相关推荐',
        data: [
            {
                name: '文心大模型',
                href: 'https://wenxin.baidu.com',
                desc: '产业级知识增强大模型，百行千业AI开发的首选基座大模型'
            },
            {
                name: '情感倾向分析',
                href: 'https://ai.baidu.com/tech/nlp_apply/sentiment_classify',
                desc: '自动对包含主观信息的文本进行情感倾向性判断，为口碑分析、话题监控、舆情分析等应用提供基础技术支持'
            },
            {
                name: '短文本相似度',
                href: 'https://ai.baidu.com/tech/nlp_basic/simnet',
                desc: '提供不同短文本之间语义相似度的计算，帮助快速实现推荐、检索、排序等应用'
            }
        ]
    },
};
