import {useState, useRef, useMemo, useEffect} from 'preact/hooks';
import AsyncValidator from 'async-validator';
import Layout from 'components/layouts';

import {$useRules, send, scrollToTop, redirectURL, jumpToURL} from 'utils';
import {errorMsgArr, applyReason} from 'locals/easydl/cooperation';
import {serviceTypes, customerTypes, industry} from 'locals/easydl/common';

import {TrackType} from 'components/sections';
import {Field, Input, Submit, Radio, Select, CodeImg} from 'components/base/field';
import 'style/easydl/cooperation.less';

// 飞桨 离线SDK额度扩容
const PADDLE_SERVICE_TYPE = '7';

const ENTERPRISE_CUSTOMER_TYPE = '1';

const PADDLE_TRIAL_SERVICE_TYPE = '8';

export default function Custom() {
    const modelContentRef = useRef();
    const [modelData, SetModelData] = useState({});
    const [submitRes, setSubmitRes] = useState(true);

    const [formData, setFormData] = useState({
        serviceType: '1',
        applyReason: void 0,
        serialNumber: void 0,
        description: '',
        customerType: '1',
        industry: void 0,
        company: '',
        customerName: null,
        phone: null,
        email: null,
        verifyCode: null
    });
    const [formTips, setFormTips] = useState({
        serviceType: null,
        serialNumber: null,
        description: null,
        customerType: null,
        company: null,
        industry: null,
        customerName: null,
        phone: null,
        email: null,
        verifyCode: null
    });

    const isShowCompany = useMemo(() => formData.customerType === ENTERPRISE_CUSTOMER_TYPE, [formData.customerType]);
    const isShowSerialNumber = useMemo(() => formData.serviceType === PADDLE_SERVICE_TYPE, [formData.serviceType]);
    const isShowApplyReason = useMemo(() => formData.serviceType === PADDLE_TRIAL_SERVICE_TYPE, [formData.serviceType]);

    const [serialNumberList, setSerialNumberList] = useState([]);
    const serialNumberOptions = useMemo(() => serialNumberList.map((i) => ({value: i, label: i})), [serialNumberList]);

    // 根据表单每个是否显示的子项来决定rule规则
    const isShow = useMemo(
        () => ({
            company: isShowCompany,
            serialNumber: isShowSerialNumber,
            applyReason: isShowApplyReason
        }),
        [isShowCompany, isShowSerialNumber, isShowApplyReason]
    );

    // 返回校验规则
    const rules = useMemo(
        () => $useRules(Object.keys(formData).filter((it) => (Reflect.has(isShow, it) ? isShow[it] : true))),
        [isShow, formData]
    );

    const handleError = (err) => {
        const errObj = err.reduce(
            (pre, {message, field}) => ({
                ...pre,
                [field]: message
            }),
            {}
        );
        setFormTips({...formTips, ...errObj});
    };

    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [field]: value
        });
    };

    const handleBlur = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        const validator = new AsyncValidator({
            [field]: rules[field]
        });
        setFormTips({
            ...formTips,
            [field]: null
        });
        validator.validate({[field]: value}).catch(({errors}) => {
            errors && handleError(errors);
        });
    };

    const errorCb = () => {
        setSubmitRes(false);
        scrollToTop();
        SetModelData({
            state: 'fail',
            title: '提交失败，请检查网络连接',
            desc: '您录入的信息已自动保存，可返回页面重新提交',
            btn: '返回'
        });
    };

    const successCb = (res) => {
        if (res.success) {
            setSubmitRes(false);
            scrollToTop();
            SetModelData({
                state: 'success',
                title: '提交成功',
                desc: '将会有工作人员和您联系, 沟通进一步事宜',
                btn: '3S 跳转至首页'
            });
            countDown();
            return;
        }

        if (res.message.global) {
            setSubmitRes(false);
            scrollToTop();
            SetModelData({
                state: 'success',
                title: '重复提交',
                desc: res.message.global,
                btn: '3S 跳转至首页'
            });
            countDown();
            return;
        }

        const errMsg = errorMsgArr.find((it) => it.field === Object.entries(res.message.field)[0]);
        if (errMsg) {
            handleError([errMsg]);
        } else {
            errorCb();
        }
    };

    const formSubmit = () => {
        const theIndustry = industry.find(({value}) => value === +formData.industry);
        const {serialNumber, serviceType} = formData;
        const data = {
            ...formData,
            industry: theIndustry?.label,
            serialNumber: serviceType === '7' ? serialNumber : void 0
        };
        const validator = new AsyncValidator(rules);
        validator
            .validate(formData)
            .then(() => {
                send({
                    method: 'paddleDesktop/support/consult/save',
                    payload: data
                })
                    .then((res) => successCb(res))
                    .catch((err) => errorCb(err));
            })
            .catch(({errors}) => handleError(errors));
    };

    function countDown(time = 3) {
        let timeout = setTimeout(() => {
            time--;
            modelContentRef.current.textContent = `${time > 0 ? time : 1}S 跳转至首页`;
            if (time > 0) {
                countDown(time);
            }
            if (time === 0) {
                window.location.href = '/easydl';
            }
            clearTimeout(timeout);
        }, 1000);
    }

    const initFormData = ({serialNumberList, ...attrs}) => {
        // 初始化用户信息
        setFormData({
            ...formData,
            ...attrs
        });
    };

    const fetchUserInfo = async () => {
        const {success, result} = await send({method: 'paddleDesktop/support/consult/user'});
        success && initFormData(result);
    };

    // 获取用户登录信息
    useEffect(() => {
        send({method: 'common/userInfo'})
            .then((response) => {
                const login = response.result;
                if (login) {
                    window.isLogin = true;
                    fetchUserInfo();
                    return;
                }
                window.isLogin = false;
                jumpToURL(redirectURL('http://ai.baidu.com/easydl/workorder'));
            })
            .catch((e) => console.error(e));
    }, []);

    useEffect(() => {
        if (isShowSerialNumber) {
            send({method: 'paddleDesktop/serialNumber/list'})
                .then(({result}) => {
                    setSerialNumberList(result || []);
                })
                .catch((e) => console.error(e));
        }
    }, [isShowSerialNumber]);

    return (
        <TrackType.Provider value={{trackType: 'EasyDL官网', layout: 'easydl', page: 'workorder'}}>
            <Layout>
                {submitRes ? (
                    <div className="cooperation-content">
                        <div className="form-body">
                            <div className="form-desc">业务与需求信息</div>
                            <div className="form-content">
                                <Field label="咨询类型" tip={formTips.serviceType}>
                                    <Select
                                        name="serviceType"
                                        // eslint-disable-next-line
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.serviceType}
                                        selectData={serviceTypes}
                                    />
                                </Field>
                                {isShowSerialNumber && (
                                    <Field label="序列号" tip={formTips.serialNumber}>
                                        <Select
                                            name="serialNumber"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={formData.serialNumber}
                                            selectData={serialNumberOptions}
                                            placeholder={
                                                serialNumberOptions.length ? '请选择序列号' : '没有可供扩容的序列号'
                                            }
                                            disabled={!serialNumberOptions.length}
                                        />
                                    </Field>
                                )}
                                {isShowApplyReason && (
                                    <Field label="申请原因" tip={formTips.applyReason}>
                                        <Select
                                            name="applyReason"
                                            placeholder="请选择申请原因"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={formData.applyReason}
                                            selectData={applyReason}
                                        />
                                    </Field>
                                )}

                                <Field label="需求描述" required={false} tip={formTips.description}>
                                    <Input
                                        name="description"
                                        tag={false}
                                        placeholder="请简要描述您的需求，便于我们更快给予您反馈~"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.description}
                                    />
                                </Field>
                            </div>
                            <div className="form-desc">客户基本信息</div>
                            <div className="form-content">
                                <Field label="业务行业" tip={formTips.industry}>
                                    <Select
                                        name="industry"
                                        placeholder="请选择行业"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.industry}
                                        selectData={industry}
                                    />
                                </Field>
                                <Field label="客户类型" tip={formTips.customerType} radio>
                                    <Radio
                                        name="customerType"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.customerType}
                                        radios={customerTypes}
                                    />
                                </Field>
                                {isShowCompany && (
                                    <Field label="公司名称" tip={formTips.company}>
                                        <Input
                                            name="company"
                                            placeholder="请输入公司名称"
                                            // eslint-disable-next-line
                                            onChange={handleChange}
                                            // eslint-disable-next-line
                                            onBlur={handleBlur}
                                            value={formData.company}
                                        />
                                    </Field>
                                )}
                                <Field label="业务联系人" tip={formTips.customerName}>
                                    <Input
                                        name="customerName"
                                        placeholder="请输入联系人姓名"
                                        // eslint-disable-next-line
                                        onChange={handleChange}
                                        // eslint-disable-next-line
                                        onBlur={handleBlur}
                                        value={formData.customerName}
                                    />
                                </Field>
                                <Field label="联系人手机号" tip={formTips.phone}>
                                    <Input
                                        name="phone"
                                        placeholder="请输入手机号"
                                        // eslint-disable-next-line
                                        onChange={handleChange}
                                        // eslint-disable-next-line
                                        onBlur={handleBlur}
                                        value={formData.phone}
                                    />
                                </Field>
                                <Field label="联系人邮箱" required={false} tip={formTips.email}>
                                    <Input
                                        name="email"
                                        placeholder="选填，请输入您的邮箱"
                                        // eslint-disable-next-line
                                        onChange={handleChange}
                                        // eslint-disable-next-line
                                        onBlur={handleBlur}
                                        value={formData.email}
                                    />
                                </Field>
                                <Field label="验证码" tip={formTips.verifyCode}>
                                    <Input
                                        name="verifyCode"
                                        placeholder="请输入右侧验证码"
                                        // eslint-disable-next-line
                                        onChange={handleChange}
                                        // eslint-disable-next-line
                                        onBlur={handleBlur}
                                        value={formData.verifyCode}
                                    />
                                    <CodeImg />
                                </Field>
                                <Field required={false}>
                                    {/* eslint-disable-next-line */}
                                    <Submit onClick={formSubmit} />
                                </Field>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="model-content">
                        <div className="model-consult">
                            <div className={`model-icon-${modelData.state}`} />
                            <div className="model-title">{modelData.title}</div>
                            <div className="model-desc">{modelData.desc}</div>
                            {modelData.state === 'success' ? (
                                <div className="model-back-success" ref={modelContentRef}>
                                    {modelData.btn}
                                </div>
                            ) : (
                                // eslint-disable-next-line
                                <div className="model-back-fail" onClick={() => setSubmitRes(true)}>
                                    {modelData.btn}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Layout>
        </TrackType.Provider>
    );
}
