import { useState, useMemo, useEffect, useRef } from 'preact/hooks';
import css from './index.less';
import classnames from 'classnames';
import {jumpToURL, $useTrack, debounce, send} from 'utils';


const {track, ACTIONS} = $useTrack('BML飞桨专区范例');
const Pagination = (prop) => {
    const {
        total = 5,
        initialPage = 0,
        currentPage = 1,
        groupCount = 3,
        pagerSize = 20,
        setInitialPage,
        setCurrentPage,
        setPagerSize
    } = prop;
    const pagerSizes = [20, 30, 40];
    const inputEl = useRef(null);
    const pageClick = (value) => {
        if (value >= groupCount) {
            setInitialPage(+value - 2);
        } else {
            setInitialPage(1);
        }
        setCurrentPage(value);
    };
    const prePage = () => {
        let current = currentPage;
        if (--current === 0) {
            return false;
        }
        pageClick(current);
    };
    const nextPage = () => {
        let current = currentPage;
        if (++current >= total) {
            return false;
        }
        pageClick(current);
    };
    const goPage = () => {
        const value = +inputEl.current.value;
        if (!value) {
            inputEl.current.value = currentPage;
        } else {
            const page = value > total ? total : value;
            pageClick(page);
        }
    };

    let pages = [];
    pages.push(
        <li
            className={currentPage === 1 ? css.disabledPage : null}
            onClick={prePage.bind(this)}
            key={0}
        >
            <svg viewBox="0 0 1024 1024" className={css['s-icon']}>
                <path
                    d={
                        'M273.490683 502.459627l318.012423 311.652174 44.521739-44.521739-267' +
                        '.130435-267.130435L636.024845 228.968944l-44.521739-44.521739z'
                    }
                    fill=""
                    id="0"
                ></path>
            </svg>
        </li>);
    if (total <= 6) {
        for (let i = 1; i <= total; i++) {
            pages.push(
                <li
                    key={i}
                    onClick={pageClick.bind(this, i)}
                    className={currentPage === i ? css.activePage : null}
                >{i}</li>
            );
        }
    } else {
        pages.push(
            <li
                className={currentPage === 1 ? css.activePage : null}
                key={1}
                onClick={pageClick.bind(this, 1)}
            >1</li>
        );
        if (currentPage >= groupCount) {
            pages.push(<li className="" key={-1}>···</li>);
        }
        let pageLength = 0;
        if (groupCount + initialPage > total) {
            pageLength = total;
        } else {
            pageLength = groupCount + initialPage;
        }
        for (let i = initialPage; i < pageLength; i++) {
            if (i <= total - 1 && i > 1) {
                pages.push(
                    <li
                        className={currentPage === i ? css.activePage : null}
                        key={i}
                        onClick={pageClick.bind(this, i)}
                    >{i}</li>
                );
            }
        }
        if (total - initialPage >= groupCount + 1) {
            pages.push(<li className="" key={-2}>···</li>);
        }
        pages.push(
            <li
                className={currentPage === total ? css.activePage : null}
                key={total}
                onClick={pageClick.bind(this, total)}
            >{total}</li>);
    }
    pages.push(
        <li
            className={currentPage === total ? css.disabledPage : null}
            onClick={nextPage.bind(this)}
            key={total + 1}
        >
            <svg viewBox="0 0 1024 1024" className={css['s-icon']}>
                <path
                    d={
                        'M702.207841 512.318606L386.787803 196.898569l-45.242066 45.242066L6' +
                        '11.723709 512.318606l-270.177972 270.177971 45.242066 45.242066 315.420038-315.420037z'
                    }
                    fill=""
                    id="0"
                ></path>
            </svg>
        </li>
    );


    return (
        <div className={css.practice_pagination}>
            <div className={css.practice_pagination_pagersize}>
                <div className={css.practice_pagination_pagersize_box}>{`${pagerSize || 20}条/页`}</div>
                <span>
                    {
                        <svg viewBox="0 0 1024 1024" className={css['s-icon']} style={{display: 'inline-block'}}>
                            <path
                                d={
                                    'M511.681394 702.207841h0.637212l315.420037-315.420038-45.879278-45.24' +
                                    '2066L511.681394 611.723709 241.503423 341.545737l-45.242066 ' +
                                    '45.242066 315.420037 315.420038z'
                                }
                                fill=""
                                id="0"
                            ></path>
                        </svg>
                    }
                </span>
                <div className={css.pagersize_box_select_option}>
                    {
                        pagerSizes.map((item) => (
                            <div
                                className={item === pagerSize && css.option_action}
                                key={item}
                                onClick={() => setPagerSize(item)}
                            >
                                {`${item}条/页`}
                            </div>
                        ))
                    }
                </div>
            </div>
            <ul className={css.practice_pagination_ul}>
                {pages}
            </ul>
            <div className={css.practice_pagination_go}>
                <span className={css.practice_pagination_go_text}>跳转至</span>
                <div className={css.practice_pagination_go_input}>
                    <div
                        onBlur={() => {
                            inputEl.current.value = null;
                        }}
                    >
                        <input
                            type="text"
                            style={{width: '40px'}}
                            ref={inputEl}
                        />
                    </div>
                    <span onClick={() => goPage()}>GO</span>
                </div>

            </div>
        </div>
    );
};
const CardBox = ({cardBox}) => {
    const [marginRight, setMarginRight] = useState(null);
    const elWidth = useRef();
    useEffect(() => {
        if (cardBox && cardBox.length) {
            const height = elWidth.current.clientWidth;
            const number = cardBox.length > 1 && cardBox[0].length;
            if (number) {
                const margin = (height - (number * 243)) / (number - 1) - 1;
                setMarginRight(+margin);
            } else {
                setMarginRight(null);
            };
        }
    }, [cardBox]);
    return (
        <div className={css.practice_card} ref={elWidth}>
            {
                cardBox.map((item) => (
                    <div
                        className={css.practice_card_item}
                        key={item.id}
                    >
                        {
                            item.map(({sourceType, name, imageUrl, id, modelAlgorithm, component}) => (
                                <div
                                    className={css.practice_card_cardbox}
                                    key={name}
                                    style={{marginRight: `${marginRight ? marginRight : '18'}px`}}
                                    onClick={() => {
                                        const link = `/bml/app/project/paddle/${id}?from=bml_paddle`;
                                        track(ACTIONS.CLICK, '跳转至范例跳转页');
                                        jumpToURL(link);
                                    }}
                                >
                                    <img src={imageUrl} alt={name} />
                                    <div className={css.practice_card_cardbox_content}>
                                        <div className={css.practice_card_cardbox_content_text}>
                                            <span>{name}</span>
                                            {name.length > 15 && <span className={css.cardbox_text}>{name}</span>}
                                            <p className={css.practice_cardbox_p}>
                                                <span>套件: {component}</span>
                                                <span>模型: {modelAlgorithm}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    );
};
const Loading = () => {
    return (
        <div className={css.practice_loading}>
            <img src={require('assets/scene/error.png').default} />
            <div>没有相关案例</div>
        </div>
    );
};

export function Practice({category}) {
    const [practiceData, setPracticeData] = useState([]);
    const [cardBox, setCardBox] = useState([]);
    const [initialPage, setInitialPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [totalIndex, setTotalIndex] = useState(0);
    const [pagerSize, setPagerSize] = useState(20);
    const [rowIndex, setRowIndex] = useState(4);
    const boxhtml = useRef(null);
    const getData = async (category, pagerSize, currentPage) => {
        try {
            const {result} = await send({
                baseURL: '/bml/api',
                method: 'project/paddle/simpleList',
                payload: {
                    sourceType: 1,
                    pageSize: pagerSize,
                    category: +category,
                    offset: (currentPage - 1) * pagerSize
                }
            });
            const {total, items} = result;
            const totalValue = total || 1;
            setTotalIndex(total);
            setTotal(Math.ceil(totalValue / pagerSize));
            setPracticeData(items);
        } catch (error) {
            console.error(error);
        };
    };
    const {current} = useRef(debounce(getData, 300));
    const sliceArr = (data, n) => {
        return data.reduce((arr, cur, index) => {
            const num = index % n;
            const currentArr = arr[arr.length - 1];
            if (num === 0) {
            arr.push([cur]);
            } else {
            currentArr.push(cur);
            arr[arr.length - 1] = currentArr;
            }
            return arr;
        }, []);
    };
    useEffect(() => {
        current(category, pagerSize, currentPage);
    }, [category, pagerSize, currentPage]);
    useEffect(() => {
        setInitialPage(0);
        setCurrentPage(1);
        setTotal(1);
        setPagerSize(20);
    }, [category]);
    useEffect(() => {
        if (rowIndex && practiceData.length) {
            const data = sliceArr(practiceData, rowIndex);
            setCardBox(data);
        }
    }, [rowIndex, practiceData]);
    useEffect(() => {
        const callback = () => {
            const boxWidth = boxhtml.current.offsetWidth;
            const rowIndex = Math.floor(boxWidth / 260);
            setRowIndex(rowIndex);
        };
        callback();
        window.addEventListener('resize', callback, false);
        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);

    return (
        <div className={css.practice} ref={boxhtml}>
            {
                practiceData.length ?
                    <CardBox cardBox={cardBox} rowIndex={rowIndex} /> :
                    <Loading />
            }
            <div>
                {
                    +totalIndex > 20 && (
                        <Pagination
                            initialPage={initialPage}
                            currentPage={currentPage}
                            pagerSize={pagerSize}
                            setCurrentPage={setCurrentPage}
                            setInitialPage={setInitialPage}
                            setPagerSize={setPagerSize}
                            total={total}
                        />
                     )
                }
            </div>
        </div>
    );
}