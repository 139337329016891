import Layout from 'components/layouts';
import Banner from 'components/banner';
import ALink from 'components/base/aLink';

import Section, {
    TrackType,
    WithComponent,
    CardList,
    UseStep,
    TemplateCards,
    TableCase,
    TableCaseNew,
    Feature,
    SubStartUse,
    ProductForm
} from 'components/sections';
const SubSection = WithComponent(Section)({
    subSection: true
});

// 数据
import localData from 'locals/easydl/vision';

export default function Vis() {
    const {config, banner, modelTypes, step, scene, recommend, start, feature} = localData;
    const tip = (
        <div className="vis_banner_tip">
            <span className="vis_banner_tip-tag">特惠</span>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://cloud.baidu.com/campaign/2021autdiscount/index.html?track=cp:aionline-media|pf:pc|pp:huodong|pu:21puhuishangyun|ci:|kw:10304334#nodes"
            >
                设备端SDK限时特卖，永久授权价格低至
                <span className="vis_banner_tip-num">225元</span>&gt;
            </a>
        </div>
    );
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} tip={tip} />

                <SubSection title={modelTypes.title}>
                    <TemplateCards {...modelTypes} />
                </SubSection>
                <Section title={localData.productForm.title}>
                    <ProductForm data={localData.productForm.data} />
                </Section>
                <SubSection title={feature.title} desc={feature.desc}>
                    <Feature data={feature.data} />
                </SubSection>
                <SubSection
                    title={step.title}
                    desc={step.desc}
                    descChildren={
                        <ALink className="js-consoleModalBtn" trackTag="在线使用_AI开发流程图上方">
                            在线使用
                        </ALink>
                    }
                >
                    <UseStep data={step.data} />
                </SubSection>
                <SubSection title={scene.title}>
                    <TableCaseNew data={scene.data} />
                </SubSection>
                <SubSection title={start.title}>
                    <SubStartUse buttonGroup={start.buttonGroup} />
                </SubSection>
                <SubSection title={recommend.title}>
                    <CardList {...recommend} />
                </SubSection>
            </Layout>
        </TrackType.Provider>
    );
}
