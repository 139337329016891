import css from './index.less';
import classNames from 'classnames';
import {jumpToURL, $useTrack, debounce, send} from 'utils';

const {track, ACTIONS} = $useTrack('BML飞桨专区版本依赖');
export function Relyon(props) {
    const {relyonData} = props;
    const {title, desc, relyon} = relyonData;
    const tdContent = (identity, item) => {
        switch (identity) {
            case 'paddle':
                return (<span>{item[identity]}</span>);
            case 'suiteEdition':
                return (
                    <p className={css.suite_edition}>
                        {
                            Array.isArray(item[identity]) ?
                                item[identity].map((ite) => <p key={ite}>{ite}</p>) :
                                (<span>{item[identity]}</span>)
                        }
                    </p>
                );
            case 'python':
                return (
                    <div>
                        {
                            item[identity].map((ite) => (
                                <span key={ite}>
                                    {ite}
                                </span>
                            ))
                        }
                    </div>
                );
            case 'environment':
                return (
                    <a
                        href='javascript:void(0)'
                        onClick={() => {
                            track(ACTIONS.CLICK, '启动任务');
                            const herf = `/bml/app/project/notebook/list/mine?preFramework=${item.paddle}`;
                            jumpToURL(herf);
                        }}
                    >
                        创建NoteBook任务
                    </a>
                );
            default:
                return (<span>-</span>);
        }
    };

    return (
        <div className={css.relyon}>
            <div className={css.relyon_title}>
                <span>{title}</span>
                <p>{desc}</p>
            </div>
            <div className={css.relyon_table}>
                <table cellPadding="0" cellSpacing="0" width="100%">
                    <thead className={css.relyon_table_th}>
                        <tr>
                            {
                                relyon.columns.map(({name}) => (
                                    <th key={name}>
                                        <span>
                                            {name}
                                        </span>
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className={css.relyon_table_td}>
                        {
                            relyon.datasource.map((item) => (
                                <tr key={item}>
                                    {
                                        relyon.columns.map(({identity}) => (
                                            <td
                                                className={css[`relyon_table_td_${identity}`]}
                                                style={{width: `${identity === 'suiteEdition' ? '420px' : '200px'}`}}
                                                key={identity}
                                            >
                                                {tdContent(identity, item)}
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}