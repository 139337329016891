/*
 * @Author: v_houlin02@baidu.com
 * @Date: 2020-11-25 11:28:34
 * @LastEditTime: 2021-06-08 15:44:27
 * @file: video.js
 */

export default {
    config: {
        trackType: 'EasyDL视频官网',
        layout: 'easydl',
        page: 'video'
    },
    banner: {
        name: 'video',
        title: 'EasyDL视频',
        subTitle: '零门槛基础定制化训练视频流数据，快速获得高精度视频理解/检测的AI能力模型',
        bg: require('assets/banner/lite-video.jpg').default,
        buttonGroup: [
            {
                name: '在线使用',
                className: 'js-consoleModalBtn',
                size: 'small',
                type: 'primary',
                trackTag: '在线使用_官网上方',
                attrs: {
                    'data-track-action': 'click',
                    'data-track-category': 'EasyDL在线使用',
                    'data-track-name': 'EasyDL-产品页面-在线使用',
                    'data-track-value': ''
                }
            },
            {
                name: '使用文档',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/4khyi07ld',
                size: 'small',
                plain: true,
                trackTag: '使用文档_官网上方'
            },
            {
                name: '产品定价',
                href: 'https://ai.baidu.com/ai-doc/EASYDL/gl2tvkl95',
                size: 'small',
                plain: true,
                trackTag: '产品定价_官网上方'
            }
        ]
    },
    modelTypes: {
        title: '源于实践的模型类型',
        data: [
            {
                title: '视频分类',
                desc:
                    '定制视频内容分类，可以用于分析短视频的内容，识别出视频内人体做的是什么动作，物体/环境发生了什么变化',
                keywords: ['人体动作监控', '视频内容分析'],
                img: require('assets/subpage/video-classification.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.VIDEOCLS
                }
            },
            {
                title: '目标跟踪',
                desc:
                    '定制检测识别视频流中的特定运动对象，获取目标的运动参数，从而实现对后续视频帧该对象的运动预测（轨迹、速度等）',
                keywords: ['目标计数', '人/动物轨迹分析', '智能交通'],
                img: require('assets/subpage/video-track.png').default,
                link: {
                    name: '在线使用',
                    href: process.env.TRACK
                }
            }
        ]
    },

    feature: {
        title: '全面领先的功能效果',
        data: [
            {
                title: '零门槛操作',
                subtitle: '',
                href: '',
                desc: ['一站式自动化训练', '托拉拽式极简交互', '无需关注算法细节']
            },
            {
                title: '高精度效果',
                subtitle: '',
                href: '',
                desc: ['仅需50个短视频文件即可开始训练', '超过三分之二的模型准确率大于90%', '专业完善的模型评估优化']
            },
            {
                title: '低成本数据',
                subtitle: '',
                href: '',
                desc: ['已标注视频数据一键上传', 'API便捷上传和管理数据', '企业级数据加密安全保障']
            },
            {
                title: '高并发调用',
                subtitle: '',
                href: '',
                desc: ['海量GPU集群稳定承载高并发请求', '完善的鉴权、流控安全机制', '简单易用的调用示例']
            }
        ]
    },

    step: {
        title: '简单四步体验全流程，轻松获得AI模型',
        desc:
            // eslint-disable-next-line
            '一站式模型定制，全流程可视化操作，最快15分钟获得高精度AI模型，',
        data: [
            {name: '创建模型', desc: '了解不同类型模型的功能，创建想要训练的模型'},
            {name: '准备数据', desc: '上传并标注需要模型识别的示例数据'},
            {name: '训练模型', desc: '了解不同算法的优势，一键启动模型训练'},
            {name: '上线模型', desc: '快速部署上线，即刻生成公有云 API 以供调用'}
        ]
    },
    scene: {
        title: '广泛成熟的实践应用，助力产业AI升级',
        data: [
            {
                name: '内容审核',
                desc:
                    '将海量短视频标签分类，制定审核规则，过滤不良视频信息，实现短视频的内容审核功能。常应用于短视频app、直播平台等',
                banner: require('assets/subpage/video-examine.png').default
            },
            {
                name: '轨迹分析',
                desc: '监控摄像下行人/动物的移动轨迹记录分析，常用于小区监控、动物养殖场监控等监测场景中',
                banner: require('assets/subpage/video-trajectory.png').default
            },
            {
                name: '目标计数',
                desc: '流水线上特定产品的数量统计；商场、旅游景点的人流统计等',
                banner: require('assets/subpage/video-count.png').default
            },
            {
                name: '智能交通',
                desc: '行人运动轨迹预测、交通违规抓拍、肇事逃逸追踪等',
                banner: require('assets/subpage/video-traffic.png').default
            }
        ]
    },

    recommend: {
        title: '相关推荐',
        data: [
            {
                name: 'EasyDL图像',
                href: 'https://ai.baidu.com/easydl/vision',
                desc: '零门槛定制图像分类、物体检测、图像分割AI模型，丰富的灵活部署方案，适用于各种行业场景'
            },
            {
                name: 'BML 全功能AI开发平台',
                href: 'https://cloud.baidu.com/product/bml.html',
                desc: '企业级AI开发平台，一站式完成模型构建、训练、部署的全部工作'
            },
            {
                name: 'EasyDL零售行业版',
                href: 'https://ai.baidu.com/easydl/retail',
                desc:
                    '专门用于定制货架合规性检查、自助结算、无人货柜等零售场景下识别商品的高精度AI模型，可以识别商品名称和在图中的位置'
            }
        ]
    }
};
