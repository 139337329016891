import css from './index.less';
import classNames from 'classnames';
import {useCallback, useEffect, useMemo, useState} from 'preact/hooks';

const packOn = [
    require('assets/bml/paddle/pack_up.svg').default,
    require('assets/bml/paddle/pack_up_action.svg').default,
];
const unfold = [
    require('assets/bml/paddle/unfold.svg').default,
    require('assets/bml/paddle/unfold_action.svg').default,
];
const paddlepaddle = require('assets/bml/paddle/paddlepaddle.svg').default;
const sidebarOn = [
    require('assets/bml/paddle/sidebar_put.svg').default,
    require('assets/bml/paddle/sidebar_open.svg').default,
];

export function Sidebar(props) {
    const {sidebar, setContent, setPaddleTypeHash} = props;
    const [isShow, setIsShow] = useState(true);

    const [liAction, setLiAction] = useState(sidebar.map(() => false));
    const liHeight = useMemo(() => {
        return sidebar.map(({ items }) => {
            if (items.length === 1 && ['relyon', 'frame'].includes(items[0].type)) {
                return '0px';
            };
            return `${items.length * 40}px`;
        });
    }, [sidebar]);
    const [typeHash, setTypeHash] = useState(null);
    const [pitchOn, setPitchOn] = useState(0);
    const isTypeHash = useCallback((arr) => arr.some(({ type }) => type === typeHash), [typeHash]);
    useEffect(() => {
        if (typeHash) {
            const hashArr = sidebar.filter(({ items }) => isTypeHash(items));
            if (hashArr.length) {
                const indexAction = sidebar.findIndex(({type}) => type === hashArr[0].type);
                liAction[indexAction] = true;
                setContent(hashArr[0]);
                setPaddleTypeHash(typeHash);
                setLiAction([...liAction]);
                hashArr[0].items.forEach(({ type }, index) => {
                    if (type === typeHash) {
                        setPitchOn(index);
                        return;
                    }
                });
                window.location.hash = typeHash;
            } else {
                setPitchOn(0);
                window.location.hash = sidebar[0].items[0].type;
            };
        } ;
    }, [typeHash]);
    useEffect(() => {
        const callback = (e) => {
            const path = e.newURL.split('#')[1];
            if (path) {
                setTypeHash(path);
            }
        };
        window.addEventListener('hashchange', callback, false);
        return () => {
            document.removeEventListener('hashchange', callback);
        };
    }, []);
    useEffect(() => {
        const hash = window.location.hash.split('#')[1];

        window.location.hash = hash || sidebar[0].items[0].type;
        if (hash) {
            setTypeHash(hash);
        }
    }, []);

    return (
        <div className={classNames(css.paddle_sidebar, !isShow && css.collapsed)}>
            <div className={css.paddle_sidebar__header}>
                <div>
                    {!isShow && (
                        <span className={css.paddle_sidebar__icon}>
                            <img src={paddlepaddle} alt="" />
                        </span>
                    )}
                    <span>飞桨专区</span>
                </div>
            </div>
            <div className={css.paddle_sidebar__content}>
                <div className={css.sidebar__content}>
                    <nav>
                        <ul>
                            {
                                sidebar.map((item, index) => (
                                    <li
                                        className={
                                            classNames(
                                                css.sidebar__content_item,
                                                !isShow && css.sidebar__content_item_hover,
                                                isTypeHash(item.items) && css.sidebar__content_item_action
                                            )
                                        }
                                        key={item.items}
                                    >
                                        <div
                                            className={
                                                classNames(
                                                    css.sidebar__inner_item,
                                                    !isShow && css.sidebar__item_action,
                                                    (!isShow && isTypeHash(item.items)) && css.item_action
                                                )
                                            }
                                            onClick={() => {
                                                if (!isShow) {
                                                    return;
                                                };
                                                if (['relyon', 'frame'].includes(item.type)) {
                                                    setPitchOn(index);
                                                    setTypeHash(item.type);
                                                }
                                                liAction[index] = !liAction[index];
                                                setLiAction([...liAction]);
                                            }}
                                        >
                                            <span className={css.sidebar__icon_wrapper}>
                                                {
                                                    <img
                                                        src={isTypeHash(item.items) ? item.icon[1] : item.icon[0]}
                                                        alt=""
                                                    />
                                                }
                                            </span>
                                            <span className={css.sidebar__item_content}>{item.name}</span>
                                            {
                                                (isShow && !['relyon', 'frame'].includes(item.type)) && (
                                                    <span className={css.sidebar__arrow_wrapper}>
                                                        <span className={css.sidebar__arrow}>
                                                            {
                                                                isTypeHash(item.items) ?
                                                                    <img
                                                                        src={!liAction[index] ?
                                                                            packOn[1] : unfold[1]
                                                                        }
                                                                        alt=""
                                                                    /> :
                                                                    <img
                                                                        src={!liAction[index] ?
                                                                            packOn[0] : unfold[0]
                                                                        }
                                                                        alt=""
                                                                    />
                                                            }
                                                        </span>
                                                    </span>
                                                )
                                            }
                                        </div>
                                        <div
                                            className={
                                                classNames(
                                                    css.sidebar__inner_list_item,
                                                    !isShow && css.sidebar__list_item_action
                                                )
                                            }
                                            style={{ height: `${liAction[index] ? liHeight[index] : '0px'}` }}
                                        >
                                            {
                                                (!isShow && !['relyon', 'frame'].includes(item.type)) &&
                                                    (
                                                        <span className={css.sidebar__inner_list_item_title}>
                                                            {item.name}
                                                        </span>
                                                    )
                                            }
                                            {
                                                item.items.map(({ name, type }, index) => (
                                                    <span
                                                        className={
                                                            classNames(
                                                                css.sidebar__inner_list_item_text,
                                                                pitchOn === index && css.item_text_action
                                                            )
                                                        }
                                                        onClick={() => {
                                                            setPitchOn(index);
                                                            setTypeHash(type);
                                                        }}
                                                        key={type}
                                                    >
                                                        {name}
                                                    </span>
                                                ))
                                            }
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </nav>
                </div>
            </div>
            <div className={css.paddle_sidebar__footer}>
                <div
                    onClick={() => {
                        setIsShow(!isShow);
                    }}
                >
                    <span className={css.paddle_sidebar__icon}>
                        <img src={isShow ? sidebarOn[0] : sidebarOn[1]} alt="" />
                    </span>
                    <span>展开/收起</span>
                </div>
            </div>
        </div>
    );
}
