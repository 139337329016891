import {LayoutHeader} from 'components/layouts';
import Banner from 'components/banner';
import 'style/bml.less';

import localData from 'locals/bml/paddlepaddle';
import Section, {TrackType} from 'components/sections';
import {Sidebar, Content} from 'components/paddlepaddle';
import {useEffect, useState} from 'preact/hooks';
import {send, redirectURL, jumpToURL} from 'utils';

export default function PaddlePaddle() {
    const {config, sidebar, paddleContent} = localData;
    const [content, setContent] = useState();
    const [paddleTypeHash, setPaddleTypeHash] = useState('');

    // 获取用户登录信息
    useEffect(() => {
        send({method: 'common/userInfo'})
            .then((response) => {
                const login = response.result;
                if (login) {
                    window.isLogin = true;
                    return;
                }
                window.isLogin = false;
                jumpToURL(redirectURL('http://ai.baidu.com/bml/paddlepaddle'));
            })
            .catch((e) => console.error(e));
    }, []);

    return (
        <TrackType.Provider value={config}>
            <LayoutHeader>
                <div className={'paddle_paddle'}>
                    <Sidebar sidebar={sidebar} setContent={setContent} setPaddleTypeHash={setPaddleTypeHash} />
                    <Content paddleContent={paddleContent} content={content} paddleTypeHash={paddleTypeHash} />
                </div>
            </LayoutHeader>
        </TrackType.Provider>
    );
}
