import * as header from './header';
import footer from './footer';

export default {
    easydl: {
        header: header.easydl,
        footer: footer.easydl,
        affix: 'easydl'
    },
    wenxin: {
        header: header.wenxin,
        footer: footer.wenxin,
        affix: 'wenxin'
    },
    bml: {header: header.bml, footer: footer.bml, affix: 'bml'},
    edge: {header: header.edge, footer: footer.edge, affix: 'easyedge/home'},
    data: {header: header.data, footer: footer.data, affix: 'easydata'},
    enterprise: {
        header: header.enterprise,
        footer: footer.enterprise,
        affix: ''
    },
    paddle: {header: header.easydl, footer: footer.paddle, affix: 'paddle'},
};
