export default {
    config: {
        trackType: 'CodeLab官网',
        layout: 'bml',
        page: 'paddlepaddle'
    },
    sidebar: [
        {
            name: '产业实践范例库',
            type: 'practice',
            icon: [
                require('assets/bml/paddle/practice.svg').default,
                require('assets/bml/paddle/practice_action.svg').default,
            ],
            items: [
                {
                    name: '智慧安防',
                    type: '3'
                },
                {
                    name: '智慧交通',
                    type: '7'
                },
                {
                    name: '工业/能源',
                    type: '2'
                },
                {
                    name: '智慧城市',
                    type: '5'
                },
                {
                    name: '智慧零售',
                    type: '1'
                },
                {
                    name: '智慧农业',
                    type: '4'
                },
                {
                    name: '智慧办公',
                    type: '6'
                }
            ]
        },
        {
            name: '开发套件',
            type: 'exploit',
            icon: [
                require('assets/bml/paddle/exploit.svg').default,
                require('assets/bml/paddle/exploit_action.svg').default,
            ],
            label: ['PP2.3-GPU-cuda11.2-py37-单套件', 'PP2.3-GPU-cuda11.2-py37-多套件'],
            items: [
                {
                    name: 'PaddleClas',
                    type: 'paddleClas',
                    component: 'PaddleClas 2.4',
                    tolink: 'https://github.com/PaddlePaddle/PaddleClas',
                },
                {
                    name: 'PaddleDetection',
                    type: 'paddleDetection',
                    component: 'PaddleDetection 2.4',
                    tolink: 'https://github.com/PaddlePaddle/PaddleDetection',
                },
                {
                    name: 'PaddleHub',
                    type: 'paddleHub',
                    component: 'PaddleHub 2.3',
                    tolink: 'https://github.com/PaddlePaddle/PaddleHub',
                },
                {
                    name: 'PaddleNLP',
                    type: 'paddleNLP',
                    component: 'PaddleNLP develop',
                    tolink: 'https://github.com/PaddlePaddle/PaddleNLP',
                    label: 'PP2.3-GPU-cuda11.2-py37-单套件'
                },
                {
                    name: 'PaddleOCR',
                    type: 'paddleOCR',
                    component: 'PaddleOCR 2.6',
                    tolink: 'https://github.com/PaddlePaddle/PaddleOCR',
                },
                {
                    name: 'PaddleSeg',
                    type: 'paddleSeg',
                    component: 'PaddleSeg 2.6',
                    tolink: 'https://github.com/PaddlePaddle/PaddleSeg',
                },
                {
                    name: 'PaddleSpeech',
                    type: 'paddleSpeech',
                    tolink: 'https://github.com/PaddlePaddle/PaddleSpeech',
                },
                {
                    name: 'PaddleScience',
                    type: 'paddleScience',
                    tolink: 'https://github.com/PaddlePaddle/PaddleScience',
                }
            ]
        },
        {
            name: '版本依赖',
            type: 'relyon',
            icon: [
                require('assets/bml/paddle/relyon.svg').default,
                require('assets/bml/paddle/relyon_action.svg').default,
            ],
            items: [
                {
                    name: '版本依赖',
                    type: 'relyon'
                }
            ]
        },
        {
            name: '基础框架',
            type: 'frame',
            icon: [
                require('assets/bml/paddle/frame.svg').default,
                require('assets/bml/paddle/frame_action.svg').default,
            ],
            items: [
                {
                    name: '基础框架',
                    type: 'frame'
                },
            ]
        }
    ],
    paddleContent: {
        relyonData: {
            title: '版本依赖介绍',
            desc: '飞桨框架与Python版本、各套件版本关系对应表，按照飞桨框架大版本迭代更新（约半年一次）。',
            relyon: {
                columns: [
                    {
                        name: '飞桨框架版本',
                        identity: 'paddle'
                    },
                    {
                        name: 'Python版本',
                        identity: 'python'
                    },
                    {
                        name: '套件版本',
                        identity: 'suiteEdition'
                    },
                    {
                        name: '构建环境',
                        identity: 'environment'
                    }
                ],
                datasource: [
                    {
                        paddle: 'PaddlePaddle 2.3.1',
                        python: ['3.6', '3.7', '3.8', '3.9', '3.10'],
                        suiteEdition: [
                            'PaddleDetection 2.4',
                            'PaddleOCR 2.5',
                            'PaddleTS 0.1.1（不支持py36）',
                            'PaddleNLP 2.3.x',
                            'PaddleSpeech',
                            'r1.1',
                            'PGL2.2.4',
                            'PaddleClas v2.4.0',
                            'PaddleSeg v2.6.0'
                        ],
                        environment: {
                            href: ''
                        }
                    },
                    {
                        paddle: 'PaddlePaddle 2.2.2',
                        python: ['3.6', '3.7', '3.8', '3.9'],
                        suiteEdition: ['PaddleDetection 2.3-2.4', 'PaddleOCR 2.3-2.4', 'PaddleNLP 2.1.0 - 2.2.6 ',
                            'PaddleSpeech', 'r1.0', 'PGL2.2.2', 'PaddleClas v2.3.1', 'PaddleSeg v2.5.0'],
                        environment: {
                            href: ''
                        }
                    },
                    {
                        paddle: 'PaddlePaddle 2.1.2',
                        python: ['3.6', '3.7', '3.8', '3.9'],
                        suiteEdition: ['PaddleDetection 2.1-2.2', 'PaddleOCR 2.0-2.2', 'PaddleNLP 2.0.0 - 2.0.8',
                            ' PGL2.1.5', 'PaddleClas v2.2.1', 'PaddleSeg v2.3.0-v2.4.0'],
                        environment: {
                            href: ''
                        }
                    },
                    {
                        paddle: 'PaddlePaddle 2.0.0',
                        python: ['2.7.15+', '3.5.1+', '3.6', '3.7', '3.8'],
                        suiteEdition: ['PaddleDetection 2.0', 'PaddleOCR 2.0', 'PaddleNLP 2.0rc ',
                            'PGL2.1.5', 'PaddleClas v2.0.0(不推荐使用)', 'PaddleSeg v2.0.0-v2.2.0'],
                        environment: {
                            href: ''
                        }
                    },
                    {
                        paddle: 'PaddlePaddle 1.8.4',
                        python: ['2.7.15+', '3.5.1+', '3.6', '3.7', '3.8'],
                        suiteEdition: [
                            'PaddleDetection 0.4-0.5',
                            'PaddleOCR 1.1',
                            'PGL1.2.1',
                            'PaddleSeg v0.5.0-v0.8.0'
                        ],
                        environment: {
                            href: ''
                        }
                    }
                ]
            }
        },
        dataVersions: [{
            date: ' 敬请期待',
            versions: '核心框架V2.4'
        },
        {
            date: '2022年5月',
            versions: '核心框架V2.3',
            dase: '高复用性算子库PHI、异构多云分布式训练等多项新特性升级'
        }, {
            date: '2021年12月',
            versions: '核心框架V2.2',
            dase: '硬件适配统一方案等多项新特性'
        }, {
            date: '2021年5月',
            versions: '核心框架V2.1',
            dase: '自动混合精度、动态图、高层API等进行了优化和增强。自定义算子功能全面升级'
        }, {
            date: '2020年',
            versions: '核心框架V2.0',
            dase: '默认开启动态图模式进行模型开发和训练，通过动转静的方式进行模型部署和训练加速。'
        }, {
            date: '2019年',
            versions: '核心框架V1.8',
            dase: '新增CV、NLP和推荐等领域的大量经典模型，分布式训练能力显著提升'
        }, {
            date: '2018年',
            versions: '核心框架V1.0',
            dase: '深度优化动态图功能，升级为飞桨产业级开源开放平台'
        }]
    }
};