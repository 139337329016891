import 'style/common.less';
import 'style/easydl/subPage.less';

import {useEffect, useState} from 'preact/hooks';
import {Router} from 'preact-router';
import SwiperCore, {Autoplay, EffectFade, Pagination, Navigation} from 'swiper/core';

// import Activity from 'routes/easydl/gravity';
import ActivityGift from 'routes/easydl/giftBag';
import Audio from 'routes/easydl/audio';
import Case from 'routes/easydl/case';
import {fetchBceAuthInfo} from 'utils';
// import Scene from 'routes/easydl/scene';
// import SceneDetails from 'routes/easydl/scene/details';
import Cooperation from 'routes/easydl/cooperation';
import WxCooperation from 'routes/wenxin/cooperation';
import Custom from 'routes/easydl/custom';
import Easydl from 'routes/easydl/index';
import Nlp from 'routes/easydl/nlp';
import Vqa from 'routes/easydl/vqa';
import Ocr from 'routes/easydl/ocr';
import Paddle from 'routes/easydl/paddle';
import PaddleDuty from 'routes/easydl/paddle/duty';
import Retail from 'routes/easydl/retail';
import Solution from 'routes/easydl/solution';
import SolutionCooperation from 'routes/easydl/solution/apply';
import StructData from 'routes/easydl/structdata';
import Video from 'routes/easydl/video';
import Vision from 'routes/easydl/vision';

import Bml from 'routes/bml';
import CodeLab from 'routes/bml/codelab';
import PaddlePaddle from 'routes/bml/paddlepaddle';

import EasyEdge from 'routes/easyedge';
import Easydata from 'routes/easydata';
import Enterprise from 'routes/enterprise';
import Error from 'routes/error';
// import Navbar from 'routes/navbar';
import WorkOrder from 'routes/easydl/workorder';

SwiperCore.use([Autoplay, Pagination, EffectFade, Navigation]);

export default function App() {
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        await fetchBceAuthInfo();
        setLoading(false);
    }, []);

    return (
        !loading && (
            <div id="preact_root">
                <Router>
                    <Easydl path="/easydl" />
                    <Enterprise path="/easydl/paddleenterprise" />
                    {/* <Activity path="/easydl/universal-gravitation" /> */}
                    <ActivityGift path="/easydl/activity-gift" />
                    <Audio path="/easydl/audio" />
                    <Nlp path="/easydl/nlp" />
                    <Vqa path="/easydl/multi" />
                    <Ocr path="/easydl/ocr" />
                    <Retail path="/easydl/retail" />
                    <StructData path="/easydl/structured_data" />
                    <Vision path="/easydl/vision" />
                    <Video path="/easydl/video" />
                    <Solution path="/easydl/solution" />
                    <SolutionCooperation path="/easydl/solution/apply" />
                    <Cooperation path="/easydl/cooperation" />
                    <WxCooperation path="/wenxinworkshop/cooperation" />
                    <Custom path="/easydl/custom" />
                    <WorkOrder path="/easydl/workorder" />
                    <Paddle path="/easydl/paddle" />
                    <PaddleDuty path="/easydl/paddle/duty" />
                    <Case path="/easydl/case" />
                    {/* <Scene path="/easydl/scene" />
                <SceneDetails path="/easydl/scene/details" /> */}
                    <Bml path="/bml" />
                    <CodeLab path="/bml/codelab" />
                    <PaddlePaddle path="/bml/paddlepaddle" />
                    <EasyEdge path="/easyedge/:home?" />
                    <Easydata path="/easydata" />
                    {/* <Navbar path="/navbar" /> */}
                    <Error type="404" path="/easydl/error" default />
                </Router>
            </div>
        )
    );
}
