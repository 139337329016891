// *KEY和easydl内用的是一致的
const KEY = 'EASYDL_COOP_REQ_FLAG';
const getFlags = () => {
    try {
        return JSON.parse(localStorage.getItem(KEY) || '{}');
    } catch (error) {
        return {};
    }
};
const setFlags = (key) => {
    const currentFlag = getFlags();
    try {
        currentFlag[key] = true;
        localStorage.setItem(KEY, JSON.stringify(currentFlag));
    } catch (error) {
        return;
    }
};
/**
 * 标记模型表单是否提交
 *
 * 目前只有ernie用到
 */
export const flagCooperationRequest = (formData) => {
    const techType = formData?.techType;
    if (!techType) {
        return;
    }
    setFlags(techType);
};
