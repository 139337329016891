import Layout from 'components/layouts';
import Banner from 'components/banner';
import ALink from 'components/base/aLink';

import Section, {
    TrackType,
    WithComponent,
    CardList,
    UseStep,
    HighLightNumber,
    TemplateCards,
    TableCase
} from 'components/sections';
const SubSection = WithComponent(Section)({
    subSection: true
});

import localData from 'locals/easydl/vqa';

export default function Vqa() {
    const {config, banner, modelTypes, feature, step, scene, recommend} = localData;
    return (
        <TrackType.Provider value={config}>
            <Layout>
                <Banner {...banner} />

                <SubSection title={modelTypes.title}>
                    <TemplateCards {...modelTypes} />
                </SubSection>

                <SubSection title={feature.title}>
                    <HighLightNumber data={feature.data} />
                </SubSection>

                <SubSection
                    title={step.title}
                    desc={step.desc}
                    descChildren={
                        <ALink href='https://ai.baidu.com/easydl/app/2001/20010/models' trackTag="在线使用_AI开发流程图上方">
                            在线使用
                        </ALink>
                    }
                >
                    <UseStep data={step.data} />
                </SubSection>
                <SubSection title={scene.title}>
                    <TableCase data={scene.data} />
                </SubSection>
                <SubSection title={recommend.title}>
                    <CardList {...recommend} />
                </SubSection>
            </Layout>
        </TrackType.Provider>
    );
}
