import css from './index.less';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import {marked} from 'marked';
import mds from 'locals/markdowns/bml/index';
import {useEffect, useState} from 'preact/hooks';
import {jumpToURL, $useTrack, send} from 'utils';

const {track, ACTIONS} = $useTrack('BML飞桨专区套件');
export function Exploit({typeHash, exploitData, label}) {
    // const {paddleclas} = mds;
    const [resultData, setResultData] = useState();
    const [html, setHtml] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [isMultiple, setIsMultiple] = useState(false);
    useEffect(() => {
        if (exploitData) {
            send({
                baseURL: '/bml/api',
                method: 'project/paddle/componentInfo',
                payload: {
                    component: exploitData.component || ''
                }
            }).then(({result}) => {
                const isOn = !+result.projectId;
                const components = result.component.split(',');
                setIsDisabled(isOn);
                setResultData(result);
                setIsMultiple(components.length > 1);
            }).catch((err) => {
                setResultData({
                    component: '',
                    imageId: '',
                    projectId: ''
                });
                setIsDisabled(true);
            });
            setHtml(DOMPurify.sanitize(marked(mds[typeHash])));
        }
    }, [typeHash]);
    return (
        <div className={css.exploit}>
            {
                // bca-disable-line
                <div className={css.exploit_md} dangerouslySetInnerHTML={{ __html: html }}>
                </div>
            }
            {
                resultData && (
                    <div className={css.exploit_bottom}>
                        <span
                            onClick={() => {
                                track(ACTIONS.CLICK, '跳转至套件经典案例');
                                if (+resultData.projectId) {
                                    jumpToURL(`/bml/app/project/paddle/${resultData.projectId}`);
                                }
                            }}
                            className={classNames(isDisabled ? css.exploit_bottom_disabled : '')}
                        >
                            运行经典案例
                        </span>
                        <span
                            onClick={() => {
                                track(ACTIONS.CLICK, '启动任务');
                                if (resultData.imageId) {
                                    jumpToURL(
                                        '/bml/app/project/notebook/list/mine?component' +
                                        `=${resultData.component}&imageId=${resultData.imageId}&label=${
                                            isMultiple ? label[1] : label[0]
                                        }`,
                                        true
                                    );
                                }
                            }}
                            className={classNames(!resultData.imageId ? css.exploit_bottom_disabled : '')}
                        >
                            启动套件任务
                        </span>
                        <span onClick={() => {
                            track(ACTIONS.CLICK, '跳转至github官网');
                            jumpToURL(exploitData.tolink, true);}}
                        >
                            了解更多
                        </span>
                    </div>
                )
            }
        </div>
    );
}
