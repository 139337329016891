import {useState, useRef, useMemo} from 'preact/hooks';
import AsyncValidator from 'async-validator';
import Layout from 'components/layouts';

import {$useRules, send, scrollToTop} from 'utils';
// 页面数据
import {companyScale, errorMsgArr} from 'locals/easydl/cooperation';
import {industryCustom} from 'locals/easydl/common';

import {TrackType, Uploader} from 'components/sections';
import {Field, Input, Submit, Radio, Select, CodeImg} from 'components/base/field';
import 'style/easydl/cooperation.less';

const customerTypes = [
    {key: '1', title: '企业'},
    {key: '2', title: '个人'}
];

export default function Custom() {
    const [formData, setFormData] = useState({
        scenes: null,
        demand: null,
        industry: 1,
        customerType: '1',
        company: null,
        companyScale: 1,
        customerName: null,
        phone: null,
        email: null,
        verifyCode: null,
        imageFile: null
    });
    const [formTips, setFormTips] = useState({
        scenes: null,
        demand: null,
        industry: null,
        customerType: null,
        company: null,
        companyScale: null,
        customerName: null,
        phone: null,
        email: null,
        verifyCode: null,
        imageFile: null
    });
    const modelContentRef = useRef(null);
    const [modelData, SetModelData] = useState({});
    const [submitRes, setSubmitRes] = useState(true);
    const [showCompany, setShowCompany] = useState(true);
    const codeImg = useRef(null);
    const rules = useMemo(() => {
        const ruleArr = Object.keys(formData);
        const other = ['company', 'companyScale'];
        const targetRules = showCompany ? ruleArr : ruleArr.filter((it) => !other.includes(it));
        return $useRules(targetRules);
    }, [showCompany]);

    const handleError = (err) => {
        const errObj = err.reduce(
            (pre, {message, field}) => ({
                ...pre,
                [field]: message
            }),
            {}
        );
        setFormTips({...formTips, ...errObj});
    };
    const setValue = (value) => {
        value &&
            setFormData({
                ...formData,
                imageFile: value
            });
    };

    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        // 处理客户类型
        if (field === 'customerType') {
            setShowCompany(value === '1');
        }
        setFormData({
            ...formData,
            [field]: value
        });
    };

    const handleBlur = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        const validator = new AsyncValidator({
            [field]: rules[field]
        });
        setFormTips({
            ...formTips,
            [field]: null
        });
        validator.validate({[field]: value}).catch(({errors}) => {
            errors && handleError(errors);
        });
    };

    const errorCb = (err) => {
        const {message} = err;
        const title =
            message.field && message.field.verifyCode
                ? `提交失败，${message.field.verifyCode}`
                : '提交失败，请检查网络连接';
        setSubmitRes(false);
        scrollToTop();
        SetModelData({
            state: 'fail',
            title: title,
            desc: '您录入的信息已自动保存，可返回页面重新提交',
            btn: '返回'
        });
    };

    const successCb = (res) => {
        if (res.success) {
            setSubmitRes(false);
            scrollToTop();
            SetModelData({
                state: 'success',
                title: '提交成功',
                desc: '将会有工作人员和您联系, 沟通进一步事宜',
                btn: '3S 跳转至首页'
            });
            countDown();
            return;
        }

        const errMsg = errorMsgArr.find((it) => it.field === Object.keys(res.message.field)[0]);
        if (errMsg) {
            handleError([errMsg]);
            codeImg.current.base.click();
        } else {
            errorCb();
        }
    };

    const formSubmit = () => {
        const validator = new AsyncValidator(rules);
        validator
            .validate(formData)
            .then(() => {
                send({
                    method: 'record/custom/create',
                    payload: formData
                })
                    .then((res) => successCb(res))
                    .catch((err) => errorCb(err));
            })
            .catch(({errors}) => handleError(errors));
    };

    function countDown(time = 3) {
        let timeout = setTimeout(() => {
            time--;
            modelContentRef.current.textContent = `${time > 0 ? time : 1}S 跳转至首页`;
            if (time > 0) {
                countDown(time);
            }
            if (time === 0) {
                window.location.href = '/easydl';
            }
            clearTimeout(timeout);
        }, 1000);
    }

    return (
        <TrackType.Provider value={{trackType: 'EasyDL官网', layout: 'easydl', page: 'custom'}}>
            <Layout>
                {submitRes ? (
                    <div className="cooperation-content">
                        <div className="form-body">
                            <div className="form-title">专家团队定制服务-合作咨询</div>
                            <span>
                                EasyDL&amp;BML不仅提供各个技术方向模型在线定制化训练的功能，还提供专家技术团队点对点对接，为您定制AI能力的服务模式。
                                <br />
                                将您的合作需求填入以下表单，我们的工作人员会尽快联系您。
                            </span>
                            <div className="form-desc">业务与需求信息</div>
                            <div className="form-content">
                                <Field label="业务的应用场景" tip={formTips.scenes}>
                                    <Input
                                        name="scenes"
                                        placeholder="请简要描写您定制AI模型的应用场景"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.scenes}
                                        tag={false}
                                    />
                                </Field>
                                <Field label="所需专家服务简述" tip={formTips.demand}>
                                    <Input
                                        name="demand"
                                        placeholder="请简要描写您需要专家团队为您定制解决什么问题"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.demand}
                                        tag={false}
                                    />
                                </Field>
                                <Field label="图片数据示例" required={false} tip={formTips.imageFile}>
                                    <Uploader accept={['zip', 'rar']} setValue={setValue} />
                                </Field>
                            </div>
                            <div className="form-desc">客户基本信息</div>
                            <div className="form-content">
                                <Field label="所在行业" tip={formTips.industry}>
                                    <Select
                                        name="industry"
                                        onChange={handleChange}
                                        value={formData.industry}
                                        selectData={industryCustom}
                                    />
                                </Field>
                                <Field label="客户类型" tip={formTips.customerType} radio>
                                    <Radio
                                        name="customerType"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.customerType}
                                        radios={customerTypes}
                                    />
                                </Field>
                                {showCompany && (
                                    <>
                                        <Field label="公司名称" tip={formTips.company}>
                                            <Input
                                                name="company"
                                                placeholder="请输入公司名称"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={formData.company}
                                            />
                                        </Field>
                                        <Field label="公司规模" tip={formTips.companyScale}>
                                            <Select
                                                name="companyScale"
                                                onChange={handleChange}
                                                value={formData.companyScale}
                                                selectData={companyScale}
                                            />
                                        </Field>
                                    </>
                                )}
                                <Field label="业务联系人" tip={formTips.customerName}>
                                    <Input
                                        name="customerName"
                                        placeholder="请输入联系人姓名"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.customerName}
                                    />
                                </Field>
                                <Field label="联系人手机号" tip={formTips.phone}>
                                    <Input
                                        name="phone"
                                        placeholder="请输入手机号"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.phone}
                                    />
                                </Field>
                                <Field label="联系人邮箱" required={false} tip={formTips.email}>
                                    <Input
                                        name="email"
                                        placeholder="选填，请输入您的邮箱"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.email}
                                    />
                                </Field>
                                <Field label="验证码" tip={formTips.verifyCode}>
                                    <Input
                                        name="verifyCode"
                                        placeholder="请输入右侧验证码"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={formData.verifyCode}
                                    />
                                    <CodeImg ref={codeImg} />
                                </Field>
                                <Field required={false}>
                                    <Submit onClick={formSubmit} />
                                </Field>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="model-content">
                        <div className="model-consult">
                            <div className={`model-icon-${modelData.state}`} />
                            <div className="model-title">{modelData.title}</div>
                            <div className="model-desc">{modelData.desc}</div>
                            {modelData.state === 'success' ? (
                                <div className="model-back-success" ref={modelContentRef}>
                                    {modelData.btn}
                                </div>
                            ) : (
                                <div className="model-back-fail" onClick={() => setSubmitRes(true)}>
                                    {modelData.btn}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Layout>
        </TrackType.Provider>
    );
}
